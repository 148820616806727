/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";
import CommentsForm from "../Form/index";

const CommentsShow = (props) => {
  const { commentProps, csrf } = props;
  const [comment, setComment] = useState(commentProps);
  const [edit, setEdit] = useState(false);

  const handleOnClickEdit = useCallback(() => {
    setEdit(true);
  });

  const handleEditComment = useCallback((response) => {
    setComment(response);
    setEdit(false);
  });

  const {
    created_at,
    content,
    user: { first_name, last_name },
    owner_id,
    owner_type,
  } = comment;
  return (
    <div>
      {!edit && (
        <div className="card-footer p-3">
          <div className="row">
            <div className="col-1 align-self-start">
              <i className="mdi mdi-account-circle md-36 text-secondary"></i>
            </div>
            <div className="col-9 pl-5">
              <div className="row">
                <div className="col-12">
                  <span className="font-italic">
                    {moment(created_at).format("DD/MM/YYYY")} -{" "}
                  </span>
                  <b>
                    {first_name} {last_name}
                  </b>
                </div>
                <div className="col-12">{content}</div>
              </div>
            </div>
            <div className="col-2">
              <div
                className="btn btn-sm btn-primary"
                onClick={handleOnClickEdit}
              >
                Editar
              </div>
            </div>
          </div>
        </div>
      )}
      {edit && (
        <CommentsForm
          ownerId={owner_id}
          ownerType={owner_type}
          csrf={csrf}
          commentProps={comment}
          handleSaveComment={handleEditComment}
        />
      )}
    </div>
  );
};

export default CommentsShow;
