/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { Fragment, useCallback, useRef } from "react";
import domtoimage from "dom-to-image";
import fileDownload from "js-file-download";
import { jsPDF } from "jspdf";
import { BAR_TYPE, SUNBURST_TYPE, PIE_TYPE } from "./constants";
import BarGraph from "./graphs/BarGraph";
import PieGraph from "./graphs/PieGraph";
import SunburstGraph from "./graphs/SunburstGraph";

const GrahType = ({
  modelTitle,
  graphTitle,
  loadingCases,
  data,
  dateType,
  datesTitle,
  graphType,
  modelName,
  compare,
  labelHeight,
  height,
}) => {
  const graphRef = useRef();

  const handleDownloadPng = useCallback(() => {
    domtoimage.toBlob(graphRef.current).then((blob) => {
      fileDownload(blob, `${graphTitle}.png`);
    });
  });

  const handleDownloadPdf = useCallback(() => {
    domtoimage.toPng(graphRef.current).then((image) => {
      const pdf = new jsPDF("l", "px", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const widthRatio = pageWidth / 12;
      const heightRatio = pageHeight / 5;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = 12 * ratio;
      const canvasHeight = 5 * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;
      pdf.addImage(image, "png", marginX, marginY, canvasWidth, canvasHeight);
      pdf.save(`${graphTitle}.pdf`);
    });
  });

  return (
    <Fragment>
      <div className="card-header">
        <div className="text-center">{graphTitle}</div>
      </div>
      <div
        className="row p-2"
        style={{ ...(loadingCases ? { opacity: 0.5 } : {}) }}
        ref={graphRef}
      >
        {graphType === BAR_TYPE && (
          <BarGraph
            data={data}
            titles={datesTitle}
            loading={loadingCases}
            compare={compare}
            labelHeight={labelHeight}
            height={height}
          />
        )}
        {graphType === PIE_TYPE && (
          <PieGraph
            data={data}
            titles={datesTitle}
            loading={loadingCases}
            compare={compare}
          />
        )}
        {graphType === SUNBURST_TYPE && (
          <SunburstGraph
            data={data}
            title={modelTitle}
            dateType={dateType}
            titles={datesTitle}
            type={modelName}
            loading={loadingCases}
            compare={compare}
          />
        )}
      </div>
      {!loadingCases && (
        <div className="d-inline text-center">
          <a onClick={handleDownloadPng} className="btn" title="Exportar a PNG">
            <i
              className={`mdi mdi-file-image text-primary`}
              style={{ fontSize: "35px" }}
            />
          </a>
          <a onClick={handleDownloadPdf} className="btn" title="Exportar a PDF">
            <i
              className={`mdi mdi-file-pdf text-primary`}
              style={{ fontSize: "35px" }}
            />
          </a>
        </div>
      )}
    </Fragment>
  );
};

export default GrahType;
