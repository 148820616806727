/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import PropTypes from "prop-types";

const Checkbox = ({
  selected,
  onChange,
  disabled,
  label,
  name,
  id,
  ...rest
}) => (
  <Fragment>
    <input
      id={id}
      type="checkbox"
      selected={selected}
      onChange={onChange}
      disabled={disabled}
      name={name}
      {...rest}
      className="chk-col-indigo"
    />
    <label className="form-label" htmlFor={id}>
      {label}
    </label>
  </Fragment>
);

Checkbox.propTypes = {
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
};

Checkbox.defaultProps = {
  selected: false,
  onChange: () => {},
  disabled: false,
  label: "",
  name: "",
};

export default Checkbox;
