/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-25)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const BarGraph = ({ data, titles, compare, labelHeight, height }) => {
  const [actual, previous] = titles;

  const labelFormat = useCallback((number) => {
    return new Intl.NumberFormat("es", { maximumFractionDigits: 1 }).format(
      number
    );
  });

  const isWideEnough = window.innerWidth > 550;

  return (
    <ResponsiveContainer width={"99%"} height={height}>
      <BarChart data={data} title="caca">
        <XAxis
          dataKey="name"
          tick={<CustomizedAxisTick />}
          height={labelHeight}
          {...(isWideEnough ? { interval: 0 } : {})}
        />
        <YAxis padding={{ top: 15 }} />
        <Tooltip
          formatter={(value) => new Intl.NumberFormat("es").format(value)}
        />
        <Legend />
        <Bar dataKey={actual} fill="#8884d8">
          <LabelList position="top" dataKey={actual} formatter={labelFormat} />
        </Bar>
        {compare && previous && (
          <Bar dataKey={previous} fill="#82ca9d">
            <LabelList
              position="top"
              dataKey={previous}
              formatter={labelFormat}
            />
          </Bar>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

BarGraph.propTypes = {
  data: PropTypes.array.isRequired,
  compare: PropTypes.bool,
  labelHeight: PropTypes.number,
  height: PropTypes.number,
};

BarGraph.defaultProps = {
  compare: true,
  labelHeight: 90,
  height: 300,
};

export default BarGraph;
