import axios from "axios";

export const getEntities = (csrfToken, entityClass) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  return axios.get(`/maintainers.json?entity_class=${entityClass}`, {
    withCredentials: true,
  });
};

export const getEntity = (csrfToken, entityClass, entityId) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  return axios.get(
    `/maintainers/${entityId}.json?entity_class=${entityClass}`,
    {
      withCredentials: true,
    }
  );
};

export const createEntity = (csrfToken, data, entityClass) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  return axios.post(`/maintainers.json?entity_class=${entityClass}`, {
    ...data,
    withCredentials: true,
  });
};

export const updateEntiy = (csrfToken, data, entityClass, entityId) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  return axios.put(
    `/maintainers/${entityId}.json?entity_class=${entityClass}`,
    {
      ...data,
      withCredentials: true,
    }
  );
};

export const destroyEntity = (csrfToken, entityClass, entityId) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  return axios.delete(
    `/maintainers/${entityId}.json?entity_class=${entityClass}`,
    {
      withCredentials: true,
    }
  );
};
