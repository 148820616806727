/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";
import { createComment, updateComment } from "../../../../services/comments";

const CommentsForm = (props) => {
  const { ownerId, ownerType, commentProps, handleSaveComment, csrf } = props;

  const [form, setForm] = useState({
    owner_id: ownerId,
    owner_type: ownerType,
    content: (commentProps && commentProps.content) || "",
  });

  const handleInputChange = useCallback((event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value });
  });

  const createOrUpdate = useCallback(() => {
    return commentProps
      ? updateComment(commentProps.id, csrf, form)
      : createComment(csrf, form);
  });

  const submitSuccess = useCallback(
    (response) => {
      const message = `Comentario ${
        commentProps ? "actualizado" : "creado"
      } con éxito`;
      // eslint-disable-next-line no-alert
      alert(message);
      setForm({ ...form, content: "" });
      handleSaveComment(response.data);
    },
    [form]
  );

  const handleSubmit = useCallback(() => {
    createOrUpdate().then(
      (response) => submitSuccess(response),
      // eslint-disable-next-line no-console
      (err) => alert("No estás autorizado para realizar un comentario")
    );
  });

  const { content } = form;
  return (
    <div className="card-footer pb-5">
      <div className="d-flex">
        <i className="mdi mdi-account-circle md-48 mr-4 text-secondary"></i>
        <textarea
          className="form-control"
          type="text"
          rows="2"
          name="content"
          value={content}
          id="detail"
          placeholder="Comentario"
          onChange={handleInputChange}
        ></textarea>
      </div>
      <div className="float-right mt-2">
        <div className="btn btn-sm btn-success" onClick={handleSubmit}>
          {!commentProps && "Comentar"}
          {commentProps && "Guardar"}
        </div>
      </div>
    </div>
  );
};

export default CommentsForm;
