/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import CommentsIndex from "../../../../containers/maintainers/comments/Index";
import MovementsForm from "../Form";
import { deleteMovement } from "../../../../services/movements";

const MovementsShow = (props) => {
  const sortedMovements = (m) => m.sort((a, b) => (a.date > b.date ? 1 : -1));

  const { caseProps, movementsProps, csrf } = props;
  const [movements, setMovements] = useState(sortedMovements(movementsProps));
  const newMovement = useRef(null);

  useEffect(() => {
    if (newMovement && newMovement.current) {
      window.scrollTo({
        bottom: 0,
        top: newMovement.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [movements]);

  const deleteMovementSuccess = useCallback((index) => {
    movements.splice(index, 1);
    setMovements([...sortedMovements(movements)]);
    alert("Movimiento eliminado con éxito");
  });

  const deleteMovementError = useCallback((error) => {
    /* eslint-disable no-console */
    console.log(error);
    alert("Hubo un error al intentar eliminar el movimiento");
  });

  const handleAddMovement = useCallback(() => {
    movements.push({ enabled: true });
    setMovements([...movements]);
  });

  const handleSaveMovement = useCallback((movement, index) => {
    movements.splice(index, 1, movement);
    setMovements([...sortedMovements(movements)]);
  });

  const handleEditMovement = useCallback((movement) => {
    const movementIndex = movements.findIndex((m) => m.id === movement.id);
    movements[movementIndex].editing = true;
    setMovements([...sortedMovements(movements)]);
  });

  const handleDeleteMovement = useCallback((movement) => {
    const { id, case_id } = movement;
    if (confirm("¿Estás seguro que deseas eliminar el movimiento?")) {
      const movementIndex = movements.findIndex((m) => m.id === movement.id);
      deleteMovement(case_id, id, csrf).then(
        () => deleteMovementSuccess(movementIndex),
        (error) => deleteMovementError(error.response)
      );
    }
  });

  const safeUrl = useCallback((url) => {
    return url.replace("http", "https");
  }, []);

  return (
    <div>
      <div className="row my-2">
        <div className="col-12">
          <div className="float-right">
            <div className="btn btn-success" onClick={handleAddMovement}>
              Nuevo movimiento
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {movements
            .filter((d) => d.enabled)
            .map((movement, index) => (
              <div
                ref={index == movements.length - 1 ? newMovement : null}
                key={index}
              >
                {(!movement.id || movement.editing) && (
                  <MovementsForm
                    index={index}
                    caseProps={caseProps}
                    movementProps={movement}
                    handleSaveMovement={handleSaveMovement}
                    csrf={csrf}
                  />
                )}
                {movement.id && !movement.editing && (
                  <div className="card" key={index}>
                    <div className="card-header">
                      <div>
                        <div className="d-flex flex-row justify-content-between">
                          {movement.title}
                          <div className="dropdown">
                            <i
                              data-toggle="dropdown"
                              className="mdi mdi-dots-vertical float-right"
                              style={{ fontSize: "20px" }}
                            />
                            <div className="dropdown-menu dropdown-menu-right">
                              <button
                                className="dropdown-item"
                                onClick={() => handleEditMovement(movement)}
                              >
                                Editar
                              </button>
                              <button
                                className="dropdown-item"
                                onClick={() => handleDeleteMovement(movement)}
                              >
                                Eliminar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="d-flex">
                        <i className="mdi mdi-account-circle md-48 mr-3 text-secondary"></i>
                        <div className="d-flex flex-column justify-content-center">
                          <br />
                          Documentos:
                          {movement.documents_with_url.map((document) => (
                            <a key={document.id} href={safeUrl(document.url)} download>
                              {document.name}
                            </a>
                          ))}
                          <div>
                            <i className="mdi mdi-calendar md-18 mr-2 text-success"></i>
                            <b className="mr-2">
                              Fecha del movimiento *
                              {moment(movement.date).format("DD/MM/YYYY")}*
                            </b>
                            <b className="mr-2">
                              (Creado el{" "}
                              {moment(movement.created_at).format("DD/MM/YYYY")}
                              )
                            </b>
                          </div>
                        </div>
                      </div>
                      <CommentsIndex
                        owner={movement}
                        ownerType={"Movement"}
                        csrf={csrf}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MovementsShow;
