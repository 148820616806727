/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useCallback, useState } from "react";
import Errors from "../../../../components/Layouts/Errors";
import LawyerForm from "../../lawyers/Form/LawyerForm";
import Select from "../../../../components/Forms/Select";
import RequiredLabel from "../../../../components/Forms/RequiredLabel";
import { createUser, updateUser } from "../../../../services/users";

const UsersForm = (props) => {
  const { user, roles, procedures, csrf } = props;
  const [form, setForm] = useState({
    id: user.id,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    role_id: user.role_id,
    lawyer_attributes: user.lawyer,
  });
  const [errors, setErrors] = useState(user.errors || []);

  const handleInputChange = useCallback((event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value });
  });

  const handleSelectOnChange = useCallback((e, t) => {
    const { value } = e;
    const { name } = t;
    if (value == 4 && !form.lawyer_attributes) {
      form.lawyer_attributes = {
        lawyer_type: null,
        lawyer_procedures_attributes: [],
      };
    } else if (value != 4 && form.lawyer_attributes) {
      form.lawyer_attributes.enable = false;
    }
    setForm({ ...form, [name]: value });
  });

  const createOrUpdate = useCallback(() => {
    const { id } = user;
    return id ? updateUser(id, csrf, form) : createUser(csrf, form);
  });

  const submitSuccess = useCallback(() => {
    const { id } = user;
    const message = `Usuario ${id ? "actualizado" : "creado"} con éxito`;
    // eslint-disable-next-line no-alert
    alert(message);
    window.location.href = "/users";
  });

  const handleSubmit = useCallback(() => {
    createOrUpdate()
      .then(() => submitSuccess())
      .catch((error) => {
        setErrors(error.response.data);
      });
  });

  const { email, first_name, last_name, phone, role_id } = form;
  return (
    <div className="row">
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <div className="form-group col-md-6">
        <RequiredLabel label={"Email"} htmlFor="user_email" />
        <input
          className="form-control"
          type="email"
          name="email"
          id="user_email"
          defaultValue={email}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group col-md-6">
        <RequiredLabel label={"Nombre"} htmlFor="user_first_name" />
        <input
          className="form-control"
          type="text"
          name="first_name"
          id="user_first_name"
          defaultValue={first_name}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group col-md-6">
        <RequiredLabel label={"Apellido"} htmlFor="user_last_name" />
        <input
          className="form-control"
          type="text"
          name="last_name"
          id="user_last_name"
          defaultValue={last_name}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group col-md-6">
        <label className="form-label" htmlFor="user_Teléfono">
          Teléfono
        </label>
        <input
          type="number"
          className="form-control"
          name="phone"
          id="user_phone"
          defaultValue={phone}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group col-md-6">
        <label className="form-label" htmlFor=""></label>
        <RequiredLabel label={"Perfil"} htmlFor="user_role" />
        <Select
          id="user_role"
          options={roles}
          value={roles.find((d) => d.value == role_id)}
          onChange={handleSelectOnChange}
          name="role_id"
          placeholder="Seleccione un perfil"
        />
      </div>

      {role_id == 4 && (
        <div className="col-12">
          <LawyerForm procedures={procedures} form={form} setForm={setForm} />
        </div>
      )}

      <div className="col-12 actions">
        <button
          type="submit"
          name="commit"
          value="Guardar"
          className="btn btn-success float-right"
          onClick={handleSubmit}
        >
          Guardar
        </button>
      </div>
    </div>
  );
};

export default UsersForm;
