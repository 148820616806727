/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import {
  createProcedure,
  updateProcedure,
} from "../../../../services/procedures";
import Select from "../../../../components/Forms/Select";
import DataGroupForm from "./DataGroupForm";
import IdentifiersForm from "./IdentifiersForm";
import DoctorCaseFieldsForm from "./DoctorCaseFieldsForm";
import ProcedureStepForm from "./ProcedureStepForm";
import Errors from "../../../../components/Layouts/Errors";
import RequiredLabel from "../../../../components/Forms/RequiredLabel";

registerLocale("es", es);

const ProceduresForm = (props) => {
  const {
    procedure,
    dataGroupsProp,
    procedureStepsProp,
    procedureIdentifierProp,
    procedureDoctorCaseFieldProp,
    selectFieldTypes,
    maintainers,
    csrf,
  } = props;
  const [form, setForm] = useState({
    insurable: procedure.insurable,
    name: procedure.name || "",
    description: procedure.description || "",
    data_groups_attributes: dataGroupsProp,
    procedure_identifier_attributes: procedureIdentifierProp,
    procedure_case_doctor_field_attributes: procedureDoctorCaseFieldProp,
    procedure_stages_attributes: procedureStepsProp,
  });
  const [errors, setErrors] = useState(procedure.errors || []);

  const insurableOptions = [
    { label: "Siniestro", value: "true" },
    { label: "No siniestro", value: "false" },
  ];

  const handleChangeInsurable = useCallback((event) => {
    const { value } = event;
    setForm({ ...form, insurable: value });
  });

  const handleInputChange = useCallback((event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value });
  });

  const createOrUpdate = useCallback(() => {
    const { id } = procedure;
    return id ? updateProcedure(id, csrf, form) : createProcedure(csrf, form);
  });

  const submitSuccess = useCallback(() => {
    const { id } = procedure;
    const message = `Materia ${id ? "actualizado" : "creado"} con éxito`;
    // eslint-disable-next-line no-alert
    alert(message);
    window.location.href = "/procedures";
  });

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    createOrUpdate()
      .then(() => submitSuccess())
      .catch((error) => {
        setErrors(error.response.data);
      });
  });

  const {
    insurable,
    name,
    description,
    data_groups_attributes,
    procedure_stages_attributes,
  } = form;

  return (
    <form className="row" onSubmit={handleSubmit}>
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-12">
                <h4 className="font-weight-bold mb-3">
                  <div className="badge badge-pill badge-info font-16 mr-2">
                    1
                  </div>
                  Definición de la Materia
                </h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <RequiredLabel label={"Asegurabilidad"} />
                  <Select
                    options={insurableOptions}
                    value={
                      insurable &&
                      insurableOptions.find((d) => d.value == String(insurable))
                    }
                    onChange={handleChangeInsurable}
                    placeholder="Seleccione asegurabilidad"
                    name="insurable"
                  />
                </div>
              </div>

              <div className="form-group col-12">
                <RequiredLabel label={"Nombre"} htmlFor="procedure_name" />
                <input
                  className="form-control"
                  type="text"
                  defaultValue={name}
                  name="name"
                  id="procedure_name"
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group col-12">
                <label
                  className="form-label"
                  htmlFor="procedure_second_description"
                >
                  Descripción
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  rows="5"
                  defaultValue={description}
                  name="description"
                  id="procedure_description"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="card-header">
            <div className="row">
              <div className="col-12">
                <h4 className="font-weight-bold mb-3">
                  <div className="badge badge-pill badge-info font-16 mr-2">
                    2
                  </div>
                  Identificadores
                </h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <IdentifiersForm form={form} setForm={setForm} />
          </div>
          <div className="card-header">
            <div className="row">
              <div className="col-12">
                <h4 className="font-weight-bold mb-3">
                  <div className="badge badge-pill badge-info font-16 mr-2">
                    3
                  </div>
                  Grupos de datos
                </h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <DataGroupForm
              form={form}
              setForm={setForm}
              dataGroupProp={data_groups_attributes}
              selectFieldTypes={selectFieldTypes}
              maintainers={maintainers}
              onChange={handleInputChange}
            />
          </div>
          <div className="card-header">
            <div className="row">
              <div className="col-12">
                <h4 className="font-weight-bold mb-3">
                  <div className="badge badge-pill badge-info font-16 mr-2">
                    4
                  </div>
                  Etapas
                </h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <ProcedureStepForm
              form={form}
              setForm={setForm}
              procedureStepsProp={procedure_stages_attributes}
              onChange={handleInputChange}
            />
          </div>
          <div className="card-header">
            <div className="row">
              <div className="col-12">
                <h4 className="font-weight-bold mb-3">
                  <div className="badge badge-pill badge-info font-16 mr-2">
                    5
                  </div>
                  Campos
                </h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <DoctorCaseFieldsForm form={form} setForm={setForm} />
          </div>
        </div>

        <div className="actions">
          <button type="submit" className="btn btn-success float-right">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProceduresForm;
