/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import Pagination from "react-bootstrap/Pagination";

const BootstrapPagination = (props) => {
  const {
    setGetParameter,
    pagination: { page, pages },
  } = props;

  const handleOnClick = useCallback((number) => {
    return setGetParameter("page", number);
  });

  function renderPaginationItem(number) {
    return (
      <Pagination.Item
        key={number}
        active={number === page}
        onClick={() => handleOnClick(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const items = [];
  if (pages < 10) {
    for (let number = 1; number <= pages; number += 1) {
      items.push(renderPaginationItem(number));
    }
  } else {
    items.push(renderPaginationItem(1));

    if (page > 4) {
      items.push(<Pagination.Ellipsis key="e1" />);
    }

    for (let number = page - 2; number <= page + 2; number += 1) {
      if (number > 1 && number < pages) {
        items.push(renderPaginationItem(number));
      }
    }

    if (page < pages - 3) {
      items.push(<Pagination.Ellipsis key="e2" />);
    }
    items.push(renderPaginationItem(pages));
  }

  return (
    <Pagination>
      <Pagination.First onClick={() => handleOnClick(1)} />
      <Pagination.Prev
        onClick={() => {
          if (page - 1 > 0) {
            handleOnClick(page - 1);
          }
        }}
      />
      {items}
      <Pagination.Next
        onClick={() => {
          if (page + 1 > 0 && page + 1 <= pages) {
            handleOnClick(page + 1);
          }
        }}
      />
      <Pagination.Last onClick={() => handleOnClick(pages)} />
    </Pagination>
  );
};

export default BootstrapPagination;
