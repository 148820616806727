/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from "react";
import Table from "../../../../components/Table";

const Index = ({ document_requests, pagination }) => {
  const columns = [
    { label: "Nombre", key: "name", value: (d) => d.name },
    { label: "Modelo", key: "record_type", value: (d) => d.record_type },
    {
      label: "ID Modelo",
      key: "record_type",
      value: (d) => d.record_id || "Archivo ya ha sido eliminado",
    },
    {
      label: "Solicitante",
      key: "user_id",
      value: (d) => `${d.first_name || ""} ${d.last_name || ""}`,
    },
  ];

  const customActions = (d) => [
    <a
      className="btn btn-sm btn-success mr-2"
      href={`/document_requests/${d.id}/approve`}
      data-confirm="¿Estás seguro que desea eliminar el documento?"
      data-toggle="tooltip"
      data-placement="top"
      data-title="Confirmar solicitud"
    >
      <i className="mdi mdi-check"></i>
    </a>,
    <a
      className="btn btn-sm btn-danger mr-2"
      href={`/document_requests/${d.id}/disapprove`}
      data-confirm="¿Estás seguro que desea desestimar la solicitud?"
      data-toggle="tooltip"
      data-placement="top"
      data-title="Desestimar solicitud"
    >
      <i className="mdi mdi-close"></i>
    </a>,
  ];

  const rows = document_requests.map((d) => ({
    ...d,
    enabled: d.record_id,
  }));

  return (
    <div className="row">
      <Table
        columns={columns}
        entityClass={"document_requests"}
        rows={rows}
        pagination={pagination}
        customActions={customActions}
        actions={[]}
      />
    </div>
  );
};

export default Index;
