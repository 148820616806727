/* eslint-disable import/extensions */
import axios from "axios";
import { COMMENTS_URL } from "./constants";

export const createComment = (csrf, comment) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post(`${COMMENTS_URL}.json`, {
    comment,
    withCredentials: true,
  });
};

export const updateComment = (id, csrf, comment) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`${COMMENTS_URL}/${id}.json`, {
    comment,
    withCredentials: true,
  });
};
