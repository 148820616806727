/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { components } from "react-select";
import Select from "../../../../components/Forms/Select";
import getDoctorSelect from "../../../../services/doctors/index";
import { ContinuousColorLegend } from "react-vis";

const Option = (props) => {
  const {
    selectProps: { lastItemRef, lastItemValue },
    value,
  } = props;
  const propsRef = lastItemValue == value ? { innerRef: lastItemRef } : {};

  return <components.Option {...props} {...propsRef} />;
};

const DoctorsSelect = (props) => {
  const { index, doctor_id, handleOnChangeSelect, csrf } = props;
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1000);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const timeOut = useRef(true);
  const lastItemRef = useRef(null);

  const onGetDoctorsSuccess = (response) => {
    const {
      doctors,
      pagination: { next, pages: totalPages },
    } = response;

    let enabledDoctors = doctors.filter(doc => doc.doctor.enabled);
    if (page === 1) {
      setDoctorOptions(enabledDoctors);
    } else {
      setDoctorOptions([...doctorOptions, ...enabledDoctors]);
    }
    setPage(next || totalPages);
    setPages(totalPages);
  };

  const loadMore = (params, currentPage = page) => {
    setLoading(true);
    getDoctorSelect(csrf, currentPage, params, doctor_id)
      .then(
        (response) => onGetDoctorsSuccess(response.data),
        // eslint-disable-next-line no-console
        (err) => console.log(err)
      )
      .finally(() => setLoading(false));
  };

  const onInputChange = useCallback((event) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    if (event) {
      setPage(1);
      setQuery(event);
    } else {
      setQuery("");
    }
  });

  const handleObserver = useCallback(
    (entities) => {
      const [target] = entities;
      if (target.isIntersecting) {
        loadMore(query, page + 1);
      }
    },
    [page]
  );

  useEffect(() => {
    let timeout;
    if (timeOut.current) {
      timeout = setTimeout(() => loadMore(query), 430);
    } else {
      timeOut.current = true;
    }

    return () => clearTimeout(timeout);
  }, [query]);

  useEffect(() => {
    if (page < pages) {
      const options = {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      };
      const observer = new IntersectionObserver(handleObserver, options);
      if (lastItemRef.current) {
        observer.observe(lastItemRef.current);
      }
    }
  }, [lastItemRef?.current]);
  const { disabled = false } = props;

  return (
    <Select
      isLoading={loading}
      components={{ Option }}
      lastItemRef={lastItemRef}
      lastItemValue={doctorOptions[doctorOptions.length - 1]?.value}
      loadingMessage={() => "Cargando"}
      options={doctorOptions}
      value={doctorOptions.find((e) => e.value == doctor_id)}
      placeholder="Seleccione un médico"
      name="doctor_id"
      onChange={(e, t) => handleOnChangeSelect(e, t, index, "doctors")}
      onInputChange={onInputChange}
      isDisabled={disabled}
      filterOption={(options) => options}
    />
  );
};

export default DoctorsSelect;
