/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from "react";
import consumer from "../../channels/consumer";
import {
  ViewNotification,
  BringNotifications,
} from "../../services/notifications/services";
import NotificationRow from "./NotificationRow";

const NotificationBox = ({ csrf }) => {
  const [notifications, setNotifications] = useState([]);
  const [notViewed, setNotViewed] = useState(0);
  consumer.subscriptions.create("NotificationsChannel", {
    received(notification) {
      setNotifications([notification, ...notifications]);
    },
  });

  const onViewSuccess = useCallback((id, notification) => {
    const newNotifications = notifications.map((n) => ({ ...n }));
    const notificationIndex = newNotifications.findIndex((n) => n.id === id);
    newNotifications[notificationIndex] = notification;
    setNotViewed(notViewed - 1);
    setNotifications(newNotifications);
  });

  const handleOnView = useCallback((event, id) => {
    event.preventDefault();
    ViewNotification(csrf, id).then(
      (response) => onViewSuccess(id, response.data),
      (error) => console.log(error.response)
    );
  });

  const bringMoreSuccess = useCallback((response) => {
    const { notifications: responseNotifications, not_viewed } = response;
    setNotViewed(not_viewed);
    setNotifications(responseNotifications);
  });

  const handleBringMore = (e) => {
    if (e) e.preventDefault();
    BringNotifications(csrf, notifications.length).then(
      (response) => bringMoreSuccess(response.data),
      (error) => console.log(error.response)
    );
  };

  useEffect(() => {
    handleBringMore();
  }, []);

  useEffect(() => {
    if (notifications.length) {
      setNotViewed(notifications.filter((n) => !n.viewed).length);
    }
  }, [notifications.length]);

  return (
    <li className="nav-item dropdown" style={{ height: "100%" }}>
      <a
        className="nav-link dropdown-toggle waves-effect waves-dark"
        href=""
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{ height: "100%" }}
      >
        <i className="mdi mdi-bell-outline text-teal md-30" />
        <i
          className="badge badge-danger badge-counter notification-badge"
          style={{ fontSize: "65%" }}
        >
          {notViewed}
        </i>
      </a>
      <div
        className="dropdown-list dropdown-menu dropdown-menu-right
            shadow animated--grow-in notifications-box"
        aria-labelledby="alertsDropdown"
        id="notifications-tray"
      >
        <h6 className="dropdown-header font-weight-bold text-center">
          Notificaciones
        </h6>
        {notifications.map((notification) => (
          <NotificationRow
            key={notification.id}
            notification={notification}
            handleOnView={handleOnView}
          />
        ))}
        <div className="dropdown-footer font-weight-bold text-center p-3">
          <a
            type="button"
            href="#"
            onClick={(e) => handleBringMore(e)}
            className="md-16"
          >
            Ver más
          </a>
        </div>
      </div>
    </li>
  );
};

export default NotificationBox;
