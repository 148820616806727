/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useState, useCallback } from "react";
import PaymentsForm from "../Form/index";
import PaymentRow from "./PaymentRow";
import { deletePayment } from "../../../../services/payments";
import PaymentAttach from "./PaymentAttach";
import { sortedArray } from "../../../../components/Forms/Utils";

const PaymentsIndex = (props) => {
  const { caseProps, paymentsProps, paymentTypes, csrf } = props;
  const { id: caseId } = caseProps;
  const [payments, setPayments] = useState(sortedArray(paymentsProps, "date"));
  const [newPayment, setNewPayment] = useState({
    date: new Date(),
    enabled: true,
    documents_with_url: [],
    case_id: caseId,
    loading: false,
  });

  const handleSavePayment = useCallback((payment) => {
    const index = payments.findIndex((p) => p.id == payment.id);
    const paymentIndex = index >= 0 ? index : payments.length;
    const paymentsToRemove = index >= 0 ? 1 : 0;
    payments.splice(paymentIndex, paymentsToRemove, {
      ...payment,
      loading: false,
    });
    setPayments([...payments]);
    if (!paymentsToRemove) {
      setNewPayment({
        date: new Date(),
        enabled: true,
        title: "",
        detail: "",
        value: "",
        documents_with_url: [],
        loading: false,
      });
    }
  });

  const handleChangePayment = useCallback((name, value, id) => {
    const paymentIndex = payments.findIndex(p => p.id == id);
    if (paymentIndex >= 0) {
      const payment = payments[paymentIndex];
      payments.splice(paymentIndex, 1, { ...payment, [name]: value });
      setPayments([...sortedArray(payments, "date")]);
    } else {
      setNewPayment({ ...newPayment, [name]: value });
    }
  });

  const deletePaymentSuccess = useCallback((index) => {
    payments.splice(index, 1);
    setPayments([...payments]);
    alert("Gasto eliminado con éxito");
  });

  const deletePaymentError = useCallback((error) => {
    /* eslint-disable no-console */
    console.log(error.response.data);
    alert(error.response.data);
  });

  const handleEditPayment = useCallback((payment) => {
    const paymentIndex = payments.findIndex((m) => m.id === payment.id);
    payments[paymentIndex].editing = true;
    setPayments([...payments]);
  });

  const handleDeletePayment = useCallback((payment) => {
    const { id, case_id } = payment;
    if (confirm("¿Estás seguro que deseas eliminar el gasto?")) {
      const paymentIndex = payments.findIndex((m) => m.id === payment.id);
      deletePayment(case_id, id, csrf).then(
        () => deletePaymentSuccess(paymentIndex),
        (error) => deletePaymentError(error)
      );
    }
  });

  return (
    <div className="table-responsive">
      <table className="table table-hover table-outline table-vcenter card-table">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Tipo de gasto</th>
            <th>Título</th>
            <th>Descripción</th>
            <th>Monto</th>
            <th>Adjuntos</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {payments
            .filter((d) => d.enabled)
            .map((payment, key) => {
              const { editing } = payment;
              if (editing) {
                return (
                  <PaymentsForm
                    index={key}
                    key={key}
                    caseProps={caseProps}
                    csrf={csrf}
                    form={payment}
                    handleSavePayment={handleSavePayment}
                    paymentTypes={paymentTypes}
                    setPayments={handleChangePayment}
                  />
                );
              }
              return (
                <PaymentRow
                  index={key}
                  key={key}
                  payment={payment}
                  editPayment={handleEditPayment}
                  deletePayment={handleDeletePayment}
                />
              );
            })}
          <PaymentsForm
            caseProps={caseProps}
            csrf={csrf}
            form={newPayment}
            handleSavePayment={handleSavePayment}
            paymentTypes={paymentTypes}
            setPayments={handleChangePayment}
          />
        </tbody>
      </table>

      <div>
        {payments
          .filter((d) => d.enabled)
          .map((payment, key) => {
            const { documents_with_url, id } = payment;
            return (
              <PaymentAttach
                documents={documents_with_url}
                id={id}
                index={key}
                key={key}
                changeDocument={handleChangePayment}
              />
            );
          })}
        <PaymentAttach
          documents={newPayment.documents_with_url}
          changeDocument={handleChangePayment}
        />
      </div>
    </div>
  );
};

export default PaymentsIndex;
