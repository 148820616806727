// eslint-disable-next-line no-unused-vars
import React, { useState, useCallback, useEffect } from "react";

const Flash = () => {
  const [messages, setMessages] = useState([]);

  window.addMessage = (message) => {
    messages.push(message);
    setMessages(messages);
  };

  const removeMessage = useCallback((index) => {
    messages.splice(index, 1);
    setMessages(messages);
  });

  return (
    <div>
      {messages.map((message, index) => {
        return (
          <div
            className="text-center alert alert-success"
            role="alert"
            key={index}
          >
            {message}
            <button
              type="button"
              className="close"
              onClick={() => removeMessage(index)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default Flash;
