/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

registerLocale("es", es);

class CustomInput extends Component {
  handleOnChange = (e) => {
    const { onChange } = this.props;
    const {
      target: { value },
    } = e;
    if (!value) return onChange(e);
  };

  render() {
    const {
      value,
      onClick,
      icon,
      required,
      placeholder,
      disabled,
    } = this.props;
    return (
      <div style={{ position: "relative" }}>
        <input
          onClick={onClick}
          value={value}
          type="text"
          className="form-control"
          required={required}
          onChange={this.handleOnChange}
          placeholder={placeholder}
          disabled={disabled}
        />
        <i
          onClick={onClick}
          aria-hidden="true"
          className={icon}
          style={{
            position: "absolute",
            top: "30%",
            right: "5%",
          }}
        ></i>
      </div>
    );
  }
}

const DateSelect = ({ icon, ...props }) => {
  return (
    <DatePicker
      showYearDropdown={true}
      locale="es"
      customInput={<CustomInput icon={icon} />}
      {...props}
    />
  );
};
DateSelect.propTypes = {
  icon: PropTypes.string,
};

DateSelect.defaultProps = {
  icon: "fa fa-calendar",
};

export default DateSelect;
