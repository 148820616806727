
/* eslint-disable no-unused-vars */
import React from "react";

const Loader = (props) => (
  <div className="" {...props}>
    <div className="d-flex justify-content-center">
      <div className="spinner-border text-secondary">
        <span className="sr-only"></span>
      </div>
    </div>
  </div>
);

export default Loader;
