/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from "react";
import Table from "../../../../components/Table";
import Errors from "../../../../components/Layouts/Errors";

const Index = (props) => {
  const { errors, templates_managements, pagination } = props;

  const columns = [
    { label: "Nombre", key: "name", value: (d) => d.name },
    {
      label: "Materia",
      key: "procedures.name",
      value: (d) => d.procedure_name,
    },
  ];

  return (
    <div className="row">
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <Table
        columns={columns}
        entityClass={"templates_managements"}
        rows={templates_managements}
        pagination={pagination}
      />
    </div>
  );
};

export default Index;
