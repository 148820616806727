/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SunburstGraph from "./SunburstGraph";
import { separateData } from "../../utils";

const SunburstWrapper = ({ data, title, dateType, type, titles, compare }) => {
  const [actual, previous] = titles;
  const separatedData = separateData(data, dateType, titles);


  return (
    <Fragment>
      <div className={`col-md-${compare ? "6" : "12"} col-xs-12`}>
        <SunburstGraph
          data={separatedData[actual]}
          title={`${title}: ${actual}`}
          type={type}
          dateType={dateType}
          titles={titles}
        />
      </div>
      {compare && (
        <div className="col-md-6 col-xs-12">
          <SunburstGraph
            data={separatedData[previous]}
            title={`${title}: ${previous}`}
            type={type}
            dateType={dateType}
            titles={titles}
          />
        </div>
      )}
    </Fragment>
  );
};

SunburstWrapper.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  titles: PropTypes.array,
  type: PropTypes.string,
  dateType: PropTypes.string,
  compare: PropTypes.bool,
};

SunburstWrapper.defaultProps = {
  title: "Casos",
  titles: [],
  type: "cases",
  dateType: "month",
  compare: true,
};

export default SunburstWrapper;
