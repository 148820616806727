/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import SelectCommune from "../../../../components/Forms/SelectCommune";
import RequiredLabel from "../../../../components/Forms/RequiredLabel";

registerLocale("es", es);

const CaseDoctorsForm = (props) => {
  const {
    doctorProps,
    doctorOffice,
    doctorSpeciality,
    doctorAgreements,
    nationalities,
    civilStatuses,
    states,
    communes,
  } = props;
  const [doctor, setDoctor] = useState(doctorProps);
  const [agreements, setAgreements] = useState([]);

  useEffect(() => {
    setAgreements(doctorAgreements);
  }, [props])

  // eslint-disable-next-line camelcase
  const handleBirthDateChange = useCallback((birth_date) => {
    setDoctor({ ...doctor, birth_date });
  });

  const addRowConvenio = () => {
    const aggr = { id: null, date_agreement: null, date_from: null, date_to: null };
    setAgreements(agreements => [...agreements, aggr]);
  }

  const removeAgreement = (i) => {
    const temp = [...agreements];
    temp.splice(i, 1);
    setAgreements(temp);
  }

  const handleOnChange = useCallback((e, index) => {
    console.log(e.target.value);
    agreements[index][e.target.name] = e.target.value;
    setAgreements({...agreements});
  });

  return (
    <div className="doctors-form">
      <div className="card">
        <div
          className="card-header"
          type="button"
          data-toggle="collapse"
          data-target="#collapseOne"
        >
          Datos personales (SGR)
        </div>
        <div id="collapseOne" className="collapse show">
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-3">
                <RequiredLabel htmlFor="doctor_nin" label={"RUT"} />
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctor.nin}
                  name="doctor[nin]"
                  id="doctor_nin"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="doctor_surname">
                  Primer apellido
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctor.surname}
                  name="doctor[surname]"
                  id="doctor_surname"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="doctor_second_surname">
                  Segundo apellido
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctor.second_surname}
                  name="doctor[second_surname]"
                  id="doctor_second_surname"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="doctor_name">
                  Nombres
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctor.name}
                  name="doctor[name]"
                  id="doctor_name"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="doctor_nationality_id">
                  Nacionalidad
                </label>
                <br />
                <select
                  className="form-control custom-select"
                  name="doctor[nationality_id]"
                  id="doctor_nationality_id"
                  defaultValue={doctor.nationality_id}
                >
                  <option value="">Selecciona una nacionalidad</option>
                  {nationalities.map((d, index) => (
                    <option key={index} value={d[1]}>
                      {d[0]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="doctor_civil_status_id">
                  Estado civil
                </label>
                <br />
                <select
                  className="form-control custom-select"
                  name="doctor[civil_status_id]"
                  id="doctor_civil_status_id"
                  defaultValue={doctor.civil_status_id}
                >
                  <option value="">Selecciona un estado civil</option>
                  {civilStatuses.map((d, index) => (
                    <option key={index} value={d[1]}>
                      {d[0]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="doctor_gender">
                  Género
                </label>
                <select
                  className="form-control custom-select"
                  name="doctor[gender]"
                  id="doctor_gender"
                  defaultValue={doctor.gender}
                >
                  <option value="male">Masculino</option>
                  <option value="female">Femenino</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-3">
                <label className="form-label" htmlFor="doctor_birth_date">
                  Fecha de Nacimiento
                </label>
                <DatePicker
                  locale="es"
                  wrapperClassName="input-group"
                  className="form-control"
                  ariaLabelledBy=""
                  id="doctor_birth_date"
                  name="doctor[birth_date]"
                  dateFormat="dd/MM/yyyy"
                  onChange={handleBirthDateChange}
                  selected={Date.parse(moment(doctor.birth_date))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className="card-header"
          type="button"
          data-toggle="collapse"
          data-target="#collapseTwo"
        >
          Datos de contacto
        </div>
        <div id="collapseTwo" className="collapse show">
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="doctor_mobile">
                  Número móvil
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctor.mobile}
                  name="doctor[mobile]"
                  id="doctor_mobile"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="doctor_phone">
                  Teléfono
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctor.phone}
                  name="doctor[phone]"
                  id="doctor_phone"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="doctor_email">
                  Correo electrónico
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctor.email}
                  name="doctor[email]"
                  id="doctor_email"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-header"
          type="button"
          style={{ borderTop: "1px solid rgba(0, 0, 0, 0.125)" }}
          data-toggle="collapse"
          data-target="#collapseThree"
        >
          Dirección
        </div>
        <div id="collapseThree" className="collapse show">
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label" htmlFor="doctor_address_street">
                  Calle
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctor.address_street}
                  name="doctor[address_street]"
                  id="doctor_address_street"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="doctor_comuna_number">
                  Número
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctor.address_number}
                  name="doctor[address_number]"
                  id="doctor_address_number"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="form-label" htmlFor="doctor_building_number">
                  Departamento
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctor.address_floor}
                  name="doctor[address_floor]"
                  id="doctor_address_floor"
                />
              </div>
              <SelectCommune
                className="doctor"
                entityProps={doctor}
                states={states}
                communes={communes}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className="card-header"
          type="button"
          data-toggle="collapse"
          data-target="#collapseFour"
        >
          Datos de Hospital / Clinica / Consulta
        </div>
        <div id="collapseFour" className="collapse show">
          <div className="card-body">
            {doctorOffice && doctorOffice.id ? (
              <input
                type="hidden"
                name="c"
                value={doctorOffice.id}
              ></input>
            ) : (
              ""
            )}
            <div className="row">
              <div className="form-group col-md-6">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_office_address_street"
                >
                  Calle
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctorOffice && doctorOffice.address_street}
                  name="doctor[doctor_office_attributes][address_street]"
                  id="doctor_doctor_office_address_street"
                />
              </div>
              <div className="form-group col-md-3">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_office_address_number"
                >
                  Número
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctorOffice && doctorOffice.address_number}
                  name="doctor[doctor_office_attributes][address_number]"
                  id="doctor_doctor_office_address_number"
                />
              </div>
              <div className="form-group col-md-3">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_office_address_floor"
                >
                  Departamento
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctorOffice && doctorOffice.address_floor}
                  name="doctor[doctor_office_attributes][address_floor]"
                  id="doctor_doctor_office_address_floor"
                />
              </div>
            </div>
            <div className="row">
              <SelectCommune
                className="doctor[doctor_office_attributes]"
                entityProps={doctorOffice}
                states={states}
                communes={communes}
              />
              <div className="form-group col-md-3">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_office_address_building"
                >
                  Edificio
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctorOffice && doctorOffice.address_building}
                  name="doctor[doctor_office_attributes][address_building]"
                  id="doctor_doctor_office_address_building"
                />
              </div>
              <div className="form-group col-md-3">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_office_address_mailbox"
                >
                  Casilla
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctorOffice && doctorOffice.address_mailbox}
                  name="doctor[doctor_office_attributes][address_mailbox]"
                  id="doctor_doctor_office_address_mailbox"
                />
              </div>
              <div className="form-group col-md-3">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_office_address_phone"
                >
                  Fono
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctorOffice && doctorOffice.address_phone}
                  name="doctor[doctor_office_attributes][address_phone]"
                  id="doctor_doctor_office_address_phone"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className="card-header"
          type="button"
          data-toggle="collapse"
          data-target="#collapseFive"
        >
          Datos profesionales
        </div>
        <div id="collapseFive" className="collapse show">
          <div className="card-body">
            {doctorSpeciality && doctorSpeciality.id ? (
              <input
                type="hidden"
                name="doctor[doctor_speciality_attributes][id]"
                value={doctorSpeciality.id}
              ></input>
            ) : (
              ""
            )}
            <div className="row">
              <div className="form-group col-md-6">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_speciality_university"
                >
                  Universidad de egreso
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctorSpeciality && doctorSpeciality.university}
                  name="doctor[doctor_speciality_attributes][university]"
                  id="doctor_doctor_speciality_university"
                />
              </div>
              <div className="form-group col-md-3">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_speciality_date_graduation"
                >
                  Año de egreso
                </label>
                <input
                  className="form-control"
                  type="number"
                  defaultValue={
                    doctorSpeciality && doctorSpeciality.date_graduation
                  }
                  name="doctor[doctor_speciality_attributes][date_graduation]"
                  id="doctor_doctor_speciality_date_graduation"
                />
              </div>
              <div className="form-group col-md-6">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_speciality_speciality"
                >
                  Especialidad
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={doctorSpeciality && doctorSpeciality.speciality}
                  name="doctor[doctor_speciality_attributes][speciality]"
                  id="doctor_doctor_speciality_speciality"
                />
              </div>
              <div className="form-group col-md-3">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_speciality_date_speciality"
                >
                  Año de especialidad
                </label>
                <input
                  className="form-control"
                  type="number"
                  defaultValue={
                    doctorSpeciality && doctorSpeciality.date_speciality
                  }
                  name="doctor[doctor_speciality_attributes][date_speciality]"
                  id="doctor_doctor_speciality_date_speciality"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_speciality_superintendence"
                >
                  Especialidad superintendencia
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={
                    doctorSpeciality &&
                    doctorSpeciality.speciality_superintendence
                  }
                  name="doctor[doctor_speciality_attributes][speciality_superintendence]"
                  id="doctor_doctor_speciality_superintendence"
                />
              </div>
              <div className="form-group col-6">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_speciality_sub"
                >
                  Sub-Especialidad
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={
                    doctorSpeciality && doctorSpeciality.speciality_sub
                  }
                  name="doctor[doctor_speciality_attributes][speciality_sub]"
                  id="doctor_doctor_speciality_sub"
                />
              </div>
              <div className="form-group col-md-6">
                <label
                  className="form-label"
                  htmlFor="doctor_doctor_speciality_other"
                >
                  Otros
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={
                    doctorSpeciality && doctorSpeciality.speciality_other
                  }
                  name="doctor[doctor_speciality_attributes][speciality_other]"
                  id="doctor_doctor_speciality_other"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div
            className="card-header"
            type="button"
            data-toggle="collapse"
            data-target="#collapseSix"
          >
            Convenios Falmed
          </div>
          <div id="collapseSix" className="collapse show">
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-12">
                <div className="btn btn-success text-right" onClick={() => addRowConvenio()}>Agregar Convenio</div>
              </div>
            </div>
            <div className="table-responsive">
                <table className="table table-hover table-outline table-vcenter text-nowrap card-table ">
                  <thead>
                    <tr>
                      <th>Fecha convenio</th>
                      <th>Convenio</th>
                      <th>Fecha desde</th>
                      <th>Fecha hasta</th>
                      <th></th>
                  </tr>
                  </thead>
                  <tbody>
                    {agreements && agreements.map((aggr, i) => 
                      <tr key={i}>
                        <td>
                          <input 
                            defaultValue={aggr.date_agreement}
                            type="date"
                            className="form-control"
                            name="doctor[doctor_falmed_agreements][][date_agreement]"
                            onChange={() => handleOnChange()}
                            required={true}
                          />
                        </td>
                        <td>
                          <input 
                            defaultValue={aggr.falmed_agreement}
                            type="text"
                            className="form-control"
                            name="doctor[doctor_falmed_agreements][][falmed_agreement]"
                            onChange={() => handleOnChange()}
                            required={true}
                          />
                        </td>                        
                        <td>
                          <input 
                            defaultValue={aggr.date_from}
                            type="date"
                            className="form-control"
                            name="doctor[doctor_falmed_agreements][][date_from]"
                            onChange={() => handleOnChange()}
                            required={true}
                          />
                        </td>
                        <td>
                          <input 
                            defaultValue={aggr.date_to}
                            type="date"
                            className="form-control"
                            name="doctor[doctor_falmed_agreements][][date_to]"
                            onChange={() => handleOnChange()}
                            required={true}
                          />
                        </td>
                        <td className="nowrap">
                          <div className="btn btn-sm btn-danger" onClick={()=> removeAgreement()} data-toggle="tooltip" data-placement="top" data-title="Eliminar">
                            <i className="mdi mdi-toggle-switch"></i>
                          </div>
                        </td>
                      </tr>
                    )}          
                  </tbody>
                </table>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseDoctorsForm;
