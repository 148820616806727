/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from "react";
import { setDocumentIcon } from "./Utils";

const DocumentIcon = ({ document }) => {
  const { name, url } = document;

  return (
    <span title={name}>
      <a href={url} target="_blank">
        <i
          className={`mdi ${setDocumentIcon(name)} text-primary`}
          style={{ fontSize: "25px" }}
        />
      </a>
    </span>
  );
};

export default DocumentIcon;
