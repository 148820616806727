/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable-next-line no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const Wrapper = ({ link, children, color }) => {
  const className = `font-weight-bold ${color}`;
  return link ? (
    <a href={link} target="_blank">
      <h5 className={className}>{children}</h5>
    </a>
  ) : (
    <h5 className={className}>{children}</h5>
  );
};

const DescriptionWrapper = ({ description, viewed, ...rest }) => {
  const html = viewed
    ? description.replace(/href="[\s\w\d;=/&?]*"/g, "")
    : description;

  return <div dangerouslySetInnerHTML={{ __html: html }} {...rest} />;
};

const NotificationRow = ({ notification, handleOnView }) => {
  const { viewed, title, description, created_at, id, link } = notification;
  const textColor = viewed ? " text-dark" : "text-white";
  const backgroundColor = viewed ? "" : "bg-primary";
  const textStyle = { fontSize: "14px" };

  return (
    <div
      className={`dropdown-item d-flex align-items-center notification-row ${textColor} ${backgroundColor}`}
      key={id}
    >
      <div className="">
        <div className="icon-circle bg-pistachio">
          <i className="fa fa-bell"></i>
        </div>
      </div>
      <div className="ml-3">
        <div>
          <Wrapper link={link} color={textColor}>
            {title}
          </Wrapper>
          <DescriptionWrapper
            className="notification-text"
            style={{ ...textStyle }}
            viewed={viewed}
            description={description}
          />
          <div>
            {!viewed && (
              <a
                type="button float-left"
                style={{ ...textStyle }}
                href="#"
                onClick={(e) => handleOnView(e, id)}
              >
                Marcar como leído
              </a>
            )}
            <div className="small text-gray font-italic float-right">
              {moment(created_at).format("DD/MM/YYYY HH:mm")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NotificationRow.propTypes = {
  notification: PropTypes.object.isRequired,
  handleOnView: PropTypes.func.isRequired,
};

export default NotificationRow;
