export const BAR_TYPE = "bar";
export const PIE_TYPE = "pie";
export const SUNBURST_TYPE = "sunburst";
export const GRAPH_TYPES = [
  { label: "Barra", value: BAR_TYPE },
  { label: "Circular", value: PIE_TYPE },
  { label: "Jerarquía", value: SUNBURST_TYPE },
];

export const DATE_TYPE_DAY = "day";
export const DATE_TYPE_MONTH = "month";
export const DATE_TYPE_YEAR = "year";
export const DATE_LAST_12_MONTHS = "last_12_months";
export const DATE_LAST_10_YEARS = "last_10_years";

export const GRAPH_COLORS = [
  "#072DF9",
  "#07D0DB",
  "#07F9C3",
  "#8B04FA",
  "#FA05E8",
  "#FFF319",
  "#FA0112",
  "#FA4E08",
  "#9E3200",
  "#C2900A",
  "#0A80C2",
  "#4D0A04",
  "#084700",
  "#05435E",
  "#9FEFFF",
];

export const EXTENDED_DISCRETE_COLOR_RANGE = [
  "#19CDD7",
  "#DDB27C",
  "#88572C",
  "#FF991F",
  "#F15C17",
  "#223F9A",
  "#DA70BF",
  "#125C77",
  "#4DC19C",
  "#776E57",
  "#12939A",
  "#17B8BE",
  "#F6D18A",
  "#B7885E",
  "#FFCB99",
  "#F89570",
  "#829AE3",
  "#E79FD5",
  "#1E96BE",
  "#89DAC1",
  "#B3AD9E",
];

export const tipStyle = {
  display: "flex",
  color: "#fff",
  background: "#000",
  alignItems: "center",
  padding: "5px",
};

export const boxStyle = { height: "10px", width: "10px" };

export const LABEL_STYLE = {
  fontSize: "12px",
  textAnchor: "middle",
};
