/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getProcedureDefinition } from "../../../../services/procedures";
import { createCase, updateCase } from "../../../../services/cases";
import CaseDoctorsForm from "./CaseDoctorsForm";
import Errors from "../../../../components/Layouts/Errors";
import LawyersForm from "../../lawyers/Form/index";
import Select from "../../../../components/Forms/Select";
import RequiredLabel from "../../../../components/Forms/RequiredLabel";
import DateSelect from "../../../../components/Forms/DateSelect";
import DataFieldForm from "./DataFieldForm";
import IdentifiersForm from "./IdentifiersForm";
import Loader from "../../../../components/Loader";
import { dateUtcFormat } from "../../../../components/Dates";

const CaseForm = (props) => {
  const {
    request_id,
    caseProps,
    judgmentTypes,
    judgmentSubtypes,
    procedures,
    lawyers,
    currentLayer,
    selectFields,
    selectMaintainersProps,
    dataGroupsProps,
    procedureStagesProps,
    procedureInsurableProps,
    procedureIdentifierProps,
    selectedLawyersProps,
    selectedDoctorsProps,
    causeStatuses,
    subCauseStatuses,
    extraordinaryStatuses,
    csrf,
  } = props;
  const subCauseProps = subCauseStatuses.find(
    (s) => s.value === caseProps.sub_cause_status_id
  );
  const [form, setForm] = useState({
    id: caseProps.id,
    date: caseProps.date || new Date(),
    procedure_id: caseProps.procedure_id,
    judgment_type_id: caseProps.judgment_type_id,
    judgment_subtype_id: caseProps.judgment_subtype_id,
    date_of_event: dateUtcFormat(caseProps.date_of_event),
    data: caseProps.data || {},
    case_lawyers_attributes: selectedLawyersProps,
    case_doctors_attributes: selectedDoctorsProps,
    request_cases_attributes:
      !caseProps.id && request_id ? [{ request_id }] : [],
    cause_status_id: causeStatuses.find(
      (c) => c.value === subCauseProps?.cause_status_id
    )?.value,
    sub_cause_status_id: caseProps.sub_cause_status_id,
  });
  const [errors, setErrors] = useState(caseProps.errors || []);

  const [dataGroups, setDataGroups] = useState(dataGroupsProps);
  const [procedureInsurable, setProcedureInsurable] = useState(
    procedureInsurableProps
  );
  const [procedureIdentifier, setProcedureIdentifier] = useState(
    procedureIdentifierProps
  );
  const [selectMaintainers, setSelectMaintainers] = useState(
    selectMaintainersProps
  );
  const [procedureStages, setProcedureStages] = useState(
    procedureStagesProps || []
  );
  const [loaded, setLoaded] = useState(false);

  const submitProcedureMaintanersSuccess = useCallback((response) => {
    setProcedureInsurable(response.data.procedure_insurable);
    setProcedureIdentifier(response.data.procedure_identifier);
    setSelectMaintainers(response.data.maintainers);
    setDataGroups(response.data.data_groups);
    setProcedureStages(response.data.procedure_stages);
    setLoaded(true);

    console.log(response.data.data_groups);
  });

  useEffect(() => {
    const { procedure_id } = form;
    if (procedure_id) {
      setLoaded(false);
      getProcedureDefinition(procedure_id, csrf).then((response) =>
        submitProcedureMaintanersSuccess(response)
      );
    }
  }, [form.procedure_id]);

  const handleChangeProcedure = useCallback((event) => {
    const { value } = event;
    setForm({
      ...form,
      procedure_id: value,
      judgment_type_id: null,
      judgment_subtype_id: null,
    });
  });

  const handleChangeJudgmentType = useCallback((event) => {
    const { value } = event;
    setForm({
      ...form,
      judgment_type_id: value,
      judgment_subtype_id: null,
    });
  });

  const handleChangeJudgmentSubType = useCallback((event) => {
    const { value } = event;
    setForm({ ...form, judgment_subtype_id: value });
  });

  const createOrUpdate = useCallback(() => {
    const { id } = caseProps;
    return id ? updateCase(id, csrf, form) : createCase(csrf, form);
  });

  const handleOnChangeSelect = useCallback((event, target, index, entities) => {
    const { value } = event;
    const { name } = target;
    const entitiesSelected = form[`case_${entities}_attributes`];
    entitiesSelected[index][name] = value;
    setForm({ ...form, [`case_${entities}_attributes`]: entitiesSelected });
  });

  const handleOnRemoveSelect = useCallback((index, entities) => {
    const entitiesSelected = form[`case_${entities}_attributes`];
    const modifiedEntity = entitiesSelected.splice(index, 1);
    if (modifiedEntity[0].id) {
      modifiedEntity[0].enabled = false;
      entitiesSelected.splice(index, 0, modifiedEntity[0]);
    }
    setForm({ ...form, [`case_${entities}_attributes`]: entitiesSelected });
  });

  const handleAddSelect = useCallback((entities) => {
    const entitiesSelected = form[`case_${entities}_attributes`];
    entitiesSelected.push({ enabled: true });
    setForm({ ...form, [`case_${entities}_attributes`]: entitiesSelected });
  });

  const handleDateSelect = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const handleSelectChange = (valueObj, obj) => {
    const { value } = valueObj;
    const { name } = obj;
    setForm({ ...form, [name]: value });
  };

  const submitSuccess = useCallback((response) => {
    const {
      data: { id },
    } = response;
    const message = `Caso ${caseProps.id ? "actualizada" : "creada"} con éxito`;
    // eslint-disable-next-line no-alert
    alert(message);
    window.location.href = `/cases/${id}/edit_second_step`;
  });

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    createOrUpdate()
      .then((response) => submitSuccess(response))
      .catch((error) => {
        setErrors(error.response.data);
      });
  });

  const {
    procedure_id,
    judgment_type_id,
    judgment_subtype_id,
    case_lawyers_attributes,
    date_of_event,
  } = form;

  return (
    <form className="row" onSubmit={handleSubmit}>
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className="col-12">
              <h4 className="font-weight-bold mb-3">
                <div className="badge badge-pill badge-info font-16 mr-2">
                  1.1
                </div>
                Datos caso
              </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="form-group">
              <div className="col-12">
                <RequiredLabel label={"Materia"} />
                <Select
                  options={procedures}
                  value={
                    procedure_id &&
                    procedures.find((d) => d.value == procedure_id)
                  }
                  onChange={handleChangeProcedure}
                  placeholder="Seleccione una materia"
                  name="procedure_id"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-12">
                <RequiredLabel label={"Tipo de juicio"} />
                <Select
                  disabled={!procedure_id}
                  options={judgmentTypes.filter(
                    (d) => d.procedure_id == procedure_id
                  )}
                  value={judgmentTypes.find((d) => d.value == judgment_type_id)}
                  onChange={handleChangeJudgmentType}
                  placeholder="Seleccione un tipo de juicio"
                  name="judgment_type_id"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-12">
                <label className="form-label">Subtipo de juicio</label>
                <Select
                  disabled={!judgment_type_id}
                  options={judgmentSubtypes.filter(
                    (d) => d.judgment_type_id == judgment_type_id
                  )}
                  value={judgmentSubtypes.find(
                    (d) => d.value == judgment_subtype_id
                  )}
                  onChange={handleChangeJudgmentSubType}
                  placeholder="Seleccione un subtipo de juicio"
                  name="judgment_subtype_id"
                />
              </div>
            </div>
            {procedureInsurable && (
              <div className="form-group">
                <div className="col-12">
                  <label className="form-label">Fecha de los hechos</label>
                  <DateSelect
                    ariaLabelledBy=""
                    dateFormat="dd/MM/yyyy"
                    selected={date_of_event && Date.parse(date_of_event)}
                    onChange={(e) => handleDateSelect(e, "date_of_event")}
                    required
                  />
                </div>
              </div>
            )}
          </div>

          <LawyersForm
            handleAddSelect={handleAddSelect}
            handleOnRemoveSelect={handleOnRemoveSelect}
            handleOnChangeSelect={handleOnChangeSelect}
            lawyers={lawyers.filter((d) =>
              d.procedures.map((e) => e.id).includes(procedure_id)
            )}
            selectedLawyers={case_lawyers_attributes}
            currentLawyer={currentLayer} 
            stepNumber={1.2}
          />

          <CaseDoctorsForm
            handleOnRemoveSelect={handleOnRemoveSelect}
            handleOnChangeSelect={handleOnChangeSelect}
            procedureStages={procedureStages}
            stepNumber={1.3}
            form={form}
            setForm={setForm}
            extraordinaryStatuses={extraordinaryStatuses}
            csrf={csrf}
          />

          <div className="card-header">
            <div className="col-12">
              <h4 className="font-weight-bold mb-3">
                <div className="badge badge-pill badge-info font-16 mr-2">
                  1.4
                </div>
                Datos principales
              </h4>
            </div>
          </div>
          <div className="card-body">
            {!procedure_id && (
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  Seleccione un proceso
                </div>
              </div>
            )}
            {procedure_id && !loaded && <Loader />}
            {loaded && procedureIdentifier && (
              <IdentifiersForm
                procedureIdentifier={procedureIdentifier}
                form={form}
                setForm={setForm}
              />
            )}
            {loaded &&
              dataGroups &&
              dataGroups.map((dataGroup, i) => (
                <div className="card" key={i}>
                  <div className="card-header">{dataGroup.name}</div>
                  <div className="card-body mt-4 mb-5" key={i}>
                    {dataGroup.data_fields.map(
                      (dataField, j) =>
                        dataField.enabled && (
                          <div key={j} className="row form-group">
                            <DataFieldForm
                              dataGroup={dataGroup}
                              dataField={dataField}
                              selectFields={selectFields}
                              selectMaintainers={selectMaintainers}
                              form={form}
                              setForm={setForm}
                            />
                          </div>
                        )
                    )}
                  </div>
                </div>
              ))}
          </div>

          <div className="card-body">
            <div className="actions">
              <button type="submit" className="btn btn-success float-right">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CaseForm;
