/* eslint-disable camelcase */
/* eslint-disable import/extensions */
import axios from "axios";
import { CASES_URL } from "./constants";

export const getCases = (csrf, procedure_id) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;
  const url = `${CASES_URL}.json?procedure_id=${procedure_id}`;

  return axios.get(url, {
    withCredentials: true,
  });
};

export const createCase = (csrf, data) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post(`${CASES_URL}.json`, {
    case: data,
    withCredentials: true,
  });
};

export const updateCase = (id, csrf, data) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`${CASES_URL}/${id}.json`, {
    case: data,
    withCredentials: true,
  });
};

export const downloadTemplateManagement = (
  id,
  caseDoctorId,
  templateId,
  csrf
) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;
  const url = `${CASES_URL}/${id}/templates/${templateId}.docx?case_doctor_id=${caseDoctorId}`;

  return axios({
    url,
    method: "GET",
    responseType: "blob",
    data: { withCredentials: true },
  });
};
