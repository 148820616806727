/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";

const DataField = ({
  dataGroup: { id: data_group_id },
  dataField: { id: data_field_id, field_type, data_type },
  selectFields,
  selectMaintainers,
  caseProps,
}) => {
  let value = null;
  const { data } = caseProps;
  if (data && data[data_group_id] && data[data_group_id][data_field_id]) {
    value = data[data_group_id][data_field_id];
  }
  if (field_type == "input") {
    if (data_type == "date") {
      return value && moment(value).format("DD/MM/YYYY");
    }
    if (data_type == "bool") {
      return value ? "Verdadero" : "Falso";
    }
    return value;
  }
  if (field_type == "select") {
    const selectedFieldsByDataType = selectFields.filter(
      (d) => d.select_field_type_id == data_type
    );
    if (selectedFieldsByDataType) {
      const selectedField = selectedFieldsByDataType.find(
        (d) => d.value == value
      );
      if (selectedField) {
        return selectedField.label;
      }
    }
    return "";
  }
  if (field_type == "maintainer") {
    const selectMaintainersByDataType = selectMaintainers.filter(
      (d) => d.data_type == data_type
    );
    const selectedMaintainer = selectMaintainersByDataType.find(
      (d) => d.value == value
    );
    if (selectedMaintainer) {
      return selectedMaintainer.label;
    }
    return "";
  }
  return <div></div>;
};

export default DataField;
