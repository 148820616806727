// eslint-disable-next-line no-unused-vars
import React, { useState, useCallback } from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import moment from "moment";
import XLSX from "xlsx";
import $ from "jquery";

const MassiveLoad = (props) => {
  const { downloadPath } = props;
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const handleOnLoad = (ef) => {
    const {
      target: { result },
    } = ef;
    const workbook = XLSX.read(result, { type: "binary", cellDates: true });
    const newRows = [];
    workbook.SheetNames.forEach((sheetName) => {
      const row = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        range: "A1:AH50",
      });
      setColumns(Object.keys(row[0]));
      if (row.length) {
        newRows.push(row);
      }
    });
    setRows(newRows[0]);
  };
  const handleFileChange = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    const outputId = "preview_html";
    const itmeFilterError = "#label-error-preview";
    const validFileTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    const output = document.getElementById(outputId);
    const {
      target: { files },
    } = e;
    const f = files[0];
    const reader = new FileReader();
    reader.onload = handleOnLoad;
    if (validFileTypes.indexOf(f.type) !== -1) {
      $(itmeFilterError).addClass("d-none");
      reader.readAsBinaryString(f);
    } else {
      $(itmeFilterError).removeClass("d-none");
      $(output).empty();
    }
  });

  const isDate = useCallback((column) => {
    return column.indexOf("Fecha") >= 0;
  }, []);

  const renderPreview = () => {
    if (rows && columns) {
      return (
        <table
          id="preview_html"
          className="table table-hover table-outline table-vcenter text-nowrap card-table table-info"
        >
          <thead>
            <tr>
              {columns.map((d, i) => (
                <th key={i}>{d}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((d, i) => {
              return (
                <tr key={i}>
                  {columns.map((column, j) => {
                    if (Object.prototype.hasOwnProperty.call(d, column)) {
                      return (
                        <td key={j}>
                          {isDate(column)
                            ? moment(d[column]).format("DD/MM/YYYY")
                            : d[column]?.toString()}
                        </td>
                      );
                    }
                    return <td key={j}></td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return "";
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="form-group col-md-6">
              <div className="form-label">Descargar Template</div>
              <a href={downloadPath}>
                <div className="btn btn-primary">Descargar</div>
              </a>
            </div>
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="massive_load">
                Carga masiva
              </label>
              <div className="custom-file">
                <label
                  className="custom-file-label file-label-es"
                  htmlFor="massive_load"
                >
                  Buscar Archivo
                </label>
                <input
                  className="form-control custom-file-input"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  type="file"
                  name="massive_load"
                  id="massive_load"
                  onChange={handleFileChange}
                  required={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row d-none" id="title_preview_html">
          <div className="col-md-12">
            <h4>Vista Previa:</h4>
            <div
              className="alert alert-danger"
              role="alert"
              id="label-error-preview"
            ></div>
          </div>
        </div>
        <div className="table-responsive">{renderPreview()}</div>
      </div>
    </div>
  );
};

export default MassiveLoad;
