/* eslint-disable camelcase */
/* eslint-disable import/extensions */
import axios from "axios";
import { DOCTORS_URL } from "./constants";

const getDoctorSelect = (csrf, page, params, doctorId = null) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;
  const url = `${DOCTORS_URL}/select.json?page=${page}${
    params ? `&query=${params}` : ""
  }${doctorId ? `&doctor_id=${doctorId}` : ""}`;

  return axios.get(url, {
    withCredentials: true,
  });
};

export default getDoctorSelect;
