/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { Fragment, useCallback, useState } from "react";
import PropTypes from "prop-types";
import Select from "../../components/Forms/Select";
import MultiSelect from "../../components/Forms/MultiSelect";
import Input from "../../components/Forms/Input";
import DateSelect from "../../components/Forms/DateSelect";
import { updateUser } from "../../services/users";
import GraphWrapper from "./GraphWrapper";
import {
  GRAPH_TYPES,
  DATE_LAST_12_MONTHS,
  DATE_LAST_10_YEARS,
} from "./constants";
import DoctorsSelect from "../maintainers/doctors/Select";

const DashboardGraph = ({
  csrf,
  lawyers,
  address_communes,
  address_states,
  specialities,
  user,
  procedures,
  requests,
  judgment_types,
  judgment_subtypes,
  cause_statues,
}) => {
  const { graph_type } = user;
  const [filters, setFilters] = useState({
    graphType: graph_type,
    lawyer_id: null,
    doctor_id: null,
    speciality_id: null,
    ruc: "",
    icm: "",
    rut: "",
    status: "",
    entry_date_begin: null,
    entry_date_end: null,
    fact_date_begin: null,
    fact_date_end: null,
    date_type: DATE_LAST_12_MONTHS,
    cause_status_name: null,
    requests_id: [],
    procedure_id: [],
    judgment_type_id: [],
    judgment_subtype_id: [],
    address_state_id: [],
    address_commune_id: [],
  });
  const [loadingCases, setLoadingCases] = useState(false);
  const [loadingPayments, setLoadingPayments] = useState(false);

  const handleSelectChange = useCallback((value, object) => {
    const { name } = object;
    setFilters({ ...filters, [name]: value.value });

    if (name === "graphType") {
      const { id } = user;
      const data = {
        graph_type: value.value,
      };
      updateUser(id, csrf, data);
    }
  });

  const handleMultiSelectChange = useCallback((value, object) => {
    const { name } = object;
    setFilters({ ...filters, [name]: value });
  })

  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setFilters({ ...filters, [name]: value });
  };

  const handleDateChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const {
    graphType,
    lawyer_id,
    doctor_id,
    address_state_id,
    address_commune_id,
    speciality_id,
    ruc,
    icm,
    rut,
    entry_date_begin,
    entry_date_end,
    fact_date_begin,
    fact_date_end,
    date_type,
    procedure_id,
    judgment_type_id,
    judgment_subtype_id,
    requests_id,
    cause_status_name,
  } = filters;
  const blankOption = { label: "Todos", value: null };

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-3 col-xs-12">
          <Select
            options={GRAPH_TYPES}
            value={GRAPH_TYPES.find((g) => g.value === graphType)}
            placeholder="Seleccione tipo de gráfico"
            name="graphType"
            onChange={handleSelectChange}
            disabled={loadingCases || loadingPayments}
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <MultiSelect
            options={lawyers}
            value={lawyer_id}
            placeholder="Seleccione Abogados"
            name="lawyer_id"
            onChange={handleMultiSelectChange}
            disabled={loadingCases || loadingPayments}
            title="Abogados"
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <DoctorsSelect
            doctor_id={doctor_id}
            handleOnChangeSelect={handleSelectChange}
            csrf={csrf}
            disabled={loadingCases || loadingPayments}
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <MultiSelect
            options={address_states}
            value={address_state_id}
            placeholder="Seleccione Regiones"
            name="address_state_id"
            onChange={handleMultiSelectChange}
            disabled={loadingCases || loadingPayments}
            title="Regiones"
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <MultiSelect
            options={address_communes}
            value={address_commune_id}
            placeholder="Seleccione Comunas"
            name="address_commune_id"
            onChange={handleMultiSelectChange}
            disabled={loadingCases || loadingPayments}
            title="Comunas"
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <MultiSelect
            options={specialities}
            value={speciality_id}
            placeholder="Seleccione Especialidades"
            name="speciality_id"
            onChange={handleMultiSelectChange}
            disabled={loadingCases || loadingPayments}
            title="Especialidades"
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <MultiSelect
            options={procedures}
            value={procedure_id}
            placeholder="Seleccione Materias"
            name="procedure_id"
            onChange={handleMultiSelectChange}
            disabled={loadingCases || loadingPayments}
            title="Materias"
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <MultiSelect
            options={judgment_types}
            value={judgment_type_id}
            placeholder="Seleccione Tipo de juicio"
            name="judgment_type_id"
            onChange={handleMultiSelectChange}
            disabled={loadingCases || loadingPayments}
            title="Tipos de Juicio"
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <MultiSelect
            options={judgment_subtypes}
            value={judgment_subtype_id}
            placeholder="Seleccione subtipo de juicio"
            name="judgment_subtype_id"
            onChange={handleMultiSelectChange}
            disabled={loadingCases || loadingPayments}
            title="Subtipos de Juicio"
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <MultiSelect
            options={requests}
            value={requests_id}
            placeholder="Seleccione Consultas"
            name="requests_id"
            onChange={handleMultiSelectChange}
            disabled={loadingCases || loadingPayments}
            title="Consultas"
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <Select
            options={[blankOption, ...cause_statues]}
            value={cause_statues.find((d) => d.value === cause_status_name)}
            placeholder="Seleccione Estado de causa"
            name="cause_status_name"
            onChange={handleSelectChange}
            disabled={loadingCases || loadingPayments}
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <select
            className="form-control"
            value={date_type}
            onChange={handleInputChange}
            name="date_type"
            disabled={loadingCases || loadingPayments}
          >
            <option value={DATE_LAST_12_MONTHS}>Últimos 12 meses</option>
            <option value={DATE_LAST_10_YEARS}>Últimos 10 años</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-xs-12">
          <DateSelect
            selected={entry_date_begin}
            placeholderText="Desde Fecha Ingreso"
            onChange={(e) => handleDateChange(e, "entry_date_begin")}
            disabled={loadingCases || loadingPayments}
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <DateSelect
            selected={entry_date_end}
            placeholderText="Hasta Fecha Ingreso"
            onChange={(e) => handleDateChange(e, "entry_date_end")}
            disabled={loadingCases || loadingPayments}
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <DateSelect
            selected={fact_date_begin}
            placeholderText="Desde Fecha Hecho"
            onChange={(e) => handleDateChange(e, "fact_date_begin")}
            disabled={loadingCases || loadingPayments}
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <DateSelect
            selected={fact_date_end}
            placeholderText="Hasta Fecha Hecho"
            onChange={(e) => handleDateChange(e, "fact_date_end")}
            disabled={loadingCases || loadingPayments}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-xs-12">
          <Input
            value={ruc}
            placeholder="RUC"
            name="ruc"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <Input
            value={rut}
            placeholder="RUT"
            name="rut"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-3 col-xs-12">
          <Input
            value={icm}
            placeholder="ICM"
            name="icm"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="row">
        <GraphWrapper
          filters={filters}
          csrf={csrf}
          loadingCases={loadingCases}
          setLoadingCases={setLoadingCases}
          loadingPayments={loadingPayments}
          setLoadingPayments={setLoadingPayments}
          addressStates={address_states}
        />
      </div>
    </Fragment>
  );
};

DashboardGraph.propTypes = {
  csrf: PropTypes.string.isRequired,
  lawyers: PropTypes.array,
  doctors: PropTypes.array,
  address_communes: PropTypes.array,
  address_states: PropTypes.array,
  specialities: PropTypes.array,
  graph_type: PropTypes.string,
  procedures: PropTypes.array,
  requests: PropTypes.array,
  judgment_types: PropTypes.array,
  judgment_subtypes: PropTypes.array,
  cause_statues: PropTypes.array,
};

DashboardGraph.defaultProps = {
  lawyers: [],
  doctors: [],
  address_communes: [],
  address_states: [],
  specialities: [],
  graph_type: "bar",
  procedures: [],
  requests: [],
  judgment_types: [],
  judgment_subtypes: [],
  cause_statues: [],
};

export default DashboardGraph;
