/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useRef, useState } from "react";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import ImageEditor from "@uppy/image-editor";
import XHRUpload from "@uppy/xhr-upload";
// eslint-disable-next-line no-unused-vars
import { DragDrop } from "@uppy/react";
import Spanish from "@uppy/locales/lib/es_ES";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

const MassiveLoad = ({ url, csrf }) => {
  const ref = useRef();
  const [drag, setDrag] = useState(null);

  const handleFileName = useCallback((currentFile) => {
    const {
      data: { name, relativePath, meta },
    } = currentFile;
    let filename = name;
    if (relativePath) {
      const names = relativePath.split("/");
      const caseDoctorId = names[names.length - 2];
      filename = `_${caseDoctorId}_${filename}`;
    }

    return { ...currentFile, meta: { ...meta, inner_name: filename } };
  });

  useEffect(() => {
    if (ref.current) {
      const uppy = new Uppy({
        meta: { type: "document" },
        restrictions: {
          maxFileSize: 300000000, // 300 MB
        },
        autoProceed: false,
        onBeforeFileAdded: handleFileName,
      })
        .use(Dashboard, {
          trigger: ".UppyModalOpenerBtn",
          inline: true,
          target: ".DashboardContainer",
          replaceTargetContent: true,
          showProgressDetails: true,
          height: 470,
          width: "100%",
          metaFields: [
            { id: "name", name: "Nombre", placeholder: "Nombre del archivo" },
          ],
          browserBackButtonClose: false,
          locale: Spanish,
        })
        .use(ImageEditor, { target: Dashboard })
        .use(XHRUpload, {
          endpoint: url,
          headers: {
            "X-CSRF-Token": csrf,
          },
          withCredentials: true,
          getResponseError: (responseText) => {
            return JSON.parse(responseText).join(". ");
          },
        });

      uppy.on("complete", (result) => {
        console.log("successful files:", result.successful);
        console.log("failed files:", result.failed);
      });
      uppy.on("file-added", (file) => console.log(file));
      setDrag(uppy);
    }
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="form-group col-12">
              <div className="DashboardContainer" ref={ref}>
                {drag && (
                  <DragDrop
                    uppy={drag}
                    locale={{
                      strings: {
                        dropHereOr: "Arrastrar para adjuntar o %{browse}",
                        browse: "navegar sistema",
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MassiveLoad;
