/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import Checkbox from "../../../../components/Forms/Checkbox";

const IdentifiersForm = (props) => {
  const { form, setForm } = props;
  const identifiers = [
    { name: "ROL", field_key: "rol_id" },
    { name: "RUC", field_key: "ruc_id" },
    { name: "RIT", field_key: "rit_id" },
    { name: "Auxiliar", field_key: "aux_id" },
  ];

  const onChangeCheckbox = useCallback((event) => {
    const {
      target: { checked, name },
    } = event;
    form.procedure_identifier_attributes[name] = checked;
    setForm({ ...form });
  });

  const { procedure_identifier_attributes } = form;

  return (
    <div>
      {identifiers.map((entry, index) => {
        const { name, field_key } = entry;

        return (
          <div className="col-12" key={index}>
            <Checkbox
              checked={procedure_identifier_attributes[field_key] || false}
              label={name}
              onChange={onChangeCheckbox}
              id={`identifier-${field_key}`}
              name={field_key}
            />
          </div>
        );
      })}
    </div>
  );
};

export default IdentifiersForm;
