/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import CaseStagesForm from "../../case_stages/Form";
import { deleteCaseStage } from "../../../../services/case_stage";
import CaseStageEntry from "./CaseStageEntry";

const CaseDoctorsShow = (props) => {
  const sorted = (m) => m.sort((a, b) => (a.date > b.date ? 1 : -1));

  const {
    caseProps,
    caseDoctorProps,
    caseStagesProps,
    csrf,
    procedureStagesProps,
  } = props;
  const [caseStages, setCaseStages] = useState(sorted(caseStagesProps));
  const newStage = useRef(null);

  useEffect(() => {
    const last = caseStages[caseStages.length - 1];
    if (newStage && newStage.current && !last?.id) {
      window.scrollTo({
        top: newStage.current.offsetTop + newStage.current.offsetHeight * 1.3,
        behavior: "smooth",
      });
    }
  }, [caseStages.length]);

  const deleteCaseStageSuccess = useCallback((index) => {
    caseStages.splice(index, 1);
    setCaseStages([...sorted(caseStages)]);
    alert("Cambio de etapa eliminado con éxito");
  });

  const deleteCaseStageError = useCallback((error) => {
    /* eslint-disable no-console */
    console.log(error);
    alert("No estás autorizado para eliminar la etapa");
  });

  const handleAddCaseStage = useCallback(() => {
    const stages = caseStages.slice();
    stages.push({ enabled: true });
    setCaseStages(stages);
  });

  const handleSaveCaseStage = useCallback(
    (case_stage, index) => {
      const stages = caseStages.slice();
      stages.splice(index, 1, { ...case_stage, editing: false });
      setCaseStages([...sorted(stages)]);
    },
    [caseStages]
  );

  const handleEditCaseStage = useCallback(
    (case_stage) => {
      const index = caseStages.findIndex((m) => m.id === case_stage.id);
      caseStages[index].editing = true;
      const stages = caseStages.slice();
      setCaseStages(stages);
    },
    [caseStages]
  );

  const handleDeleteCaseStage = useCallback((case_stage) => {
    if (confirm("¿Estás seguro que deseas eliminar el cambio de etapa?")) {
      const index = caseStages.findIndex((m) => m.id === case_stage.id);
      deleteCaseStage(
        caseDoctorProps.case_id,
        caseDoctorProps.id,
        case_stage.id,
        csrf
      ).then(
        () => deleteCaseStageSuccess(index),
        (error) => deleteCaseStageError(error.response)
      );
    }
  });

  return (
    <div>
      <div className="row my-2">
        <div className="col-12">
          <div className="float-right">
            <div className="btn btn-success" onClick={handleAddCaseStage}>
              Nueva etapa
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {caseStages.map((case_stage, index) => (
            <div
              ref={index == caseStages.length - 1 ? newStage : null}
              key={index}
            >
              {(!case_stage.id || case_stage.editing) && (
                <CaseStagesForm
                  index={index}
                  caseDoctorProps={caseDoctorProps}
                  caseStageProps={case_stage}
                  handleSaveCaseStage={handleSaveCaseStage}
                  csrf={csrf}
                  stagesOptions={procedureStagesProps}
                  previousStage={caseStages[index - 1]}
                />
              )}
              {case_stage.id && !case_stage.editing && (
                <CaseStageEntry
                  caseStage={case_stage}
                  csrf={csrf}
                  editCaseStage={handleEditCaseStage}
                  deleteCaseStage={handleDeleteCaseStage}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CaseDoctorsShow;
