/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import PropTypes from "prop-types";

const Input = ({
  value,
  onChange,
  error,
  type,
  disabled,
  label,
  required,
  name,
  ...rest
}) => (
  <Fragment>
    <label className="form-label">{label}</label>
    <input
      className={`form-control ${error && "error"}`}
      type={type}
      value={value || ""}
      onChange={onChange}
      disabled={disabled}
      required={required}
      name={name}
      {...rest}
    />
  </Fragment>
);

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
};

Input.defaultProps = {
  value: "",
  onChange: () => {},
  error: "",
  type: "text",
  disabled: false,
  label: "",
  required: false,
  name: "",
};

export default Input;
