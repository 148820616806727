/* eslint-disable import/extensions */
import axios from "axios";
import { PROCEDURES_URL } from "./constants";

export const createProcedure = (csrf, procedure) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post(`${PROCEDURES_URL}.json`, {
    procedure,
    withCredentials: true,
  });
};

export const updateProcedure = (id, csrf, procedure) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`${PROCEDURES_URL}/${id}.json`, {
    procedure,
    withCredentials: true,
  });
};

export const getProcedureDefinition = (id, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.get(`${PROCEDURES_URL}/${id}/definition.json`, {
    withCredentials: true,
  });
};
