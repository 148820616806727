/* eslint-disable camelcase */
/* eslint-disable import/extensions */
import axios from "axios";
import { CASES_URL } from "./constants";

const updateCaseDoctor = (case_id, id, csrf, data) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`/cases/${case_id}${CASES_URL}/${id}.json`, {
    case_doctor: data,
    withCredentials: true,
  });
};

export default updateCaseDoctor;
