/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../../components/Forms/Checkbox";
import DataFieldForm from "./DataFieldForm";

const DataGroupForm = (props) => {
  const { selectFieldTypes, maintainers, form, setForm } = props;
  const newDataField = (number) => {
    return {
      number,
      name: "",
      field_type: "",
      data_type: "",
      required: false,
      enabled: true,
      primary: false,
    };
  };

  const handleDataGroupCheckboxChange = (event, index) => {
    const {
      target: { name, checked },
    } = event;
    const { data_groups_attributes } = form;
    data_groups_attributes[index][name] = checked;
    setForm({ ...form, data_groups_attributes });
  };

  const handleDataGroupInputChange = useCallback((event, index) => {
    const {
      target: { value, name },
    } = event;
    const { data_groups_attributes } = form;
    data_groups_attributes[index][name] = value;
    setForm({ ...form, data_groups_attributes });
  });

  const handleOnClickRemoveDataGroup = useCallback((index) => {
    const { data_groups_attributes } = form;
    data_groups_attributes[index].enabled = false;
    setForm({ ...form, data_groups_attributes });
  });

  const handleOnClickAddDataGroup = useCallback(() => {
    const { data_groups_attributes } = form;
    data_groups_attributes.push({
      number: data_groups_attributes.length,
      name: "",
      data_fields_attributes: [newDataField(0)],
      enabled: true,
    });
    setForm({ ...form, data_groups_attributes });
  });

  const { data_groups_attributes } = form;
  return (
    <div className="row draggable-cards" id="draggable-area">
      {data_groups_attributes &&
        data_groups_attributes.map((dataGroup, index) => {
          if (!dataGroup.enabled) {
            return "";
          }
          const { id, name, primary } = dataGroup;
          return (
            <div className="col-lg-6 col-md-12" key={index}>
              <div className="card card-hover">
                <div className="card-header">
                  <div className="row">
                    <div className="col-8">
                      <input
                        className="form-control"
                        type="text"
                        value={name}
                        id={`data_group_name_${index}`}
                        name="name"
                        prompt="Ingrese un título"
                        onChange={(e) => handleDataGroupInputChange(e, index)}
                      />
                    </div>
                    <div className="row col-4">
                      <div className="col-9 d-flex flex-row">
                        <label
                          className="mr-2"
                          htmlFor={`data_group_primary${index}`}
                          style={{
                            alignText: "center",
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Primario:
                        </label>
                        <div className="d-flex flex-column jusrity-content-end">
                          <Checkbox
                            checked={primary}
                            name="primary"
                            id={`data_group_primary_${index}`}
                            onChange={(e) => {
                              handleDataGroupCheckboxChange(e, index);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div
                          className="btn btn-sm btn-danger"
                          onClick={() => handleOnClickRemoveDataGroup(index)}
                        >
                          <i className="mdi mdi-close"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <DataFieldForm
                  dataGroupIndex={index}
                  dataFields={dataGroup.data_fields_attributes}
                  selectFieldTypes={selectFieldTypes}
                  maintainers={maintainers}
                  form={form}
                  setForm={setForm}
                  newDataField={newDataField}
                />
              </div>
            </div>
          );
        })}
      <div className="col-12 mt-3">
        <div
          className="btn btn-sm btn-success"
          onClick={handleOnClickAddDataGroup}
        >
          <i className="mdi mdi-plus"></i>
          Agregar grupo de datos
        </div>
      </div>
    </div>
  );
};

export default DataGroupForm;
