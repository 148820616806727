/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import Uppy from "@uppy/core";
import { DragDrop } from "@uppy/react";
import { createMovement, updateMovement } from "../../../../services/movements";
import DateSelect from "../../../../components/Forms/DateSelect";
import Loader from "../../../../components/Loader";

const MovementsForm = (props) => {
  const { index, caseProps, movementProps, handleSaveMovement, csrf } = props;
  const [form, setForm] = useState({
    case_id: caseProps.id,
    id: movementProps.id,
    title: movementProps.title || "",
    date: movementProps.date || new Date(),
    documents: movementProps.documents_with_url || [],
  });
  const [loading, setLoading] = useState(false);

  const handleDateChange = useCallback((date) => {
    setForm({ ...form, date });
  });

  const uppy = new Uppy({
    meta: { type: "document" },
    restrictions: { maxNumberOfFiles: 1 },
    autoProceed: false,
  });

  uppy.on("file-added", (file) => {
    setForm({ ...form, documents: [...form.documents, file.data] });
  });

  const handleInputChange = useCallback((event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value });
  });

  const handleDeleteDocument = useCallback((documentName) => {
    const { documents } = form;
    const documentIndex = documents.findIndex(
      (document) => document.name === documentName
    );
    documents.splice(documentIndex, 1);
    setForm({ ...form, documents });
  });

  const createOrUpdate = useCallback(() => {
    const { id } = movementProps;
    const formData = new FormData();
    formData.append("movement[title]", form.title);
    formData.append("movement[date]", form.date);
    formData.append("movement[case_id]", form.case_id);
    form.documents.forEach((document) => {
      formData.append(
        "movement[documents][]",
        document.signed_id ? document.signed_id : document
      );
    });
    return id
      ? updateMovement(id, csrf, formData)
      : createMovement(csrf, formData);
  });

  const submitSuccess = useCallback((response) => {
    const { id } = caseProps;
    const message = `Movimiento ${id ? "actualizado" : "creado"} con éxito`;
    // eslint-disable-next-line no-alert
    alert(message);
    setLoading(false);
    handleSaveMovement(response.data, index);
  });

  const handleSubmit = useCallback(() => {
    setLoading(true);
    createOrUpdate().then(
      (response) => submitSuccess(response),
      // eslint-disable-next-line no-console
      (err) => {
        console.log(err);
        alert(err.response.data.join(".\n"));
        setLoading(false);
      }
    );
  });

  const { date, title } = form;

  return (
    <div className="card">
      <div
        className="card-body"
        style={{ ...(loading ? { opacity: 0.5 } : {}) }}
      >
        {loading && (
          <Loader
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 1000,
            }}
          />
        )}
        <div className="d-flex">
          <i className="mdi mdi-account-circle md-48 text-secondary"></i>
          <div className="row col-12">
            <div className="col-sm-12 col-md-6 form-group">
              <DateSelect
                wrapperClassName="input-group"
                className="form-control"
                ariaLabelledBy=""
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                name="date"
                selected={Date.parse(date) || new Date()}
                onChange={handleDateChange}
              />
            </div>
            <div className="col-12 form-group">
              <DragDrop
                uppy={uppy}
                value={form.documents}
                locale={{
                  strings: {
                    dropHereOr: "Arrastrar para adjuntar o %{browse}",
                    browse: "navegar sistema",
                  },
                }}
              />
            </div>
            {form.documents.length > 0 && (
              <div className="col-12">
                Archivos por subir:
                <div>
                  {form.documents.map((document, i) => (
                    <span key={i}>
                      <i
                        className="mdi mdi-delete text-danger p-5"
                        onClick={() => handleDeleteDocument(document.name)}
                      />
                      {i + 1}-. {document.name}
                      <br />
                    </span>
                  ))}
                </div>
              </div>
            )}
            <div className="col-12 form-group">
              <textarea
                className="col-12 form-control"
                type="text"
                rows="2"
                name="title"
                placeholder="Título"
                value={title}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="action float-right mt-3">
          <div className="btn btn-sm btn-success" onClick={handleSubmit}>
            Guardar
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovementsForm;
