/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import DateSelect from "../../../../components/Forms/DateSelect";
import Select from "../../../../components/Forms/Select";
import RequiredLabel from "../../../../components/Forms/RequiredLabel";

const DataFieldForm = (props) => {
  const [parentId, setParentId] = useState("");
  const {
    dataGroup: { id: data_group_id },
    dataField: {
      id: data_field_id,
      field_type,
      data_type,
      required,
      parent,
      name,
    },
    selectFields,
    selectMaintainers,
    form,
    setForm,
  } = props;

  const booleanOptions = [
    { label: "Verdadero", value: true },
    { label: "Falso", value: false },
  ];

  const handleValueChange = useCallback((value) => {
    if (!form.data[data_group_id]) {
      form.data[data_group_id] = {};
    }
    form.data[data_group_id][data_field_id] = value;
    setForm({ ...form });
  });

  const handleSelectChange = useCallback((event) => {
    const { value } = event;
    handleValueChange(value);
  });

  const handleInputChange = useCallback((event) => {
    const {
      target: { value },
    } = event;
    handleValueChange(value);
  });

  const handleDateChange = useCallback((value) => {
    handleValueChange(value);
  });

  const handleParentChange = useCallback(
    (event) => {
      setParentId(event.value);
      console.log('parent:', event, event.target);
    },
    [parentId]
  );

  const { data } = form;
  let value = null;
  const invalidValues = [null, undefined];
  if (
    data &&
    data[data_group_id] &&
    !invalidValues.includes(data[data_group_id][data_field_id])
  ) {
    value = form.data[data_group_id][data_field_id];
  }
  if (field_type == "input") {
    if (data_type == "date") {
      return (
        <Fragment>
          <div className="col-sm-12 col-md-4 form-label">
            {name}
            {required && <RequiredLabel />}
          </div>
          <DateSelect
            wrapperClassName="row col-sm-12 col-md-8"
            className="form-control"
            ariaLabelledBy=""
            dateFormat="dd/MM/yyyy"
            name={`data[${data_group_id}][${data_field_id}]`}
            selected={value && Date.parse(value)}
            onChange={handleDateChange}
            required={required}
          />
        </Fragment>
      );
    }
    if (data_type == "bool") {
      return (
        <Fragment>
          <div className="col-sm-12 col-md-4 form-label">
            {name}
            {required && <RequiredLabel />}
          </div>
          <Select
            className="col-sm-12 col-md-8"
            options={booleanOptions}
            value={booleanOptions.find((d) => d.value == value)}
            placeholder={"Seleccione un valor"}
            onChange={handleSelectChange}
            name={`data[${data_group_id}][${data_field_id}]`}
            required={required}
          />
        </Fragment>
      );
    }
    const rest = name === "RUT" ? { pattern: "[0-9]{7,8}-([0-9]|k)" } : {};
    return (
      <Fragment>
        <div className="col-sm-12 col-md-4 form-label">
          {name}
          {required && <RequiredLabel />}
        </div>
        <div className="row col-sm-12 col-md-8">
          <input
            type={data_type == "string" ? "text" : "number"}
            className="form-control"
            onChange={handleInputChange}
            name={`data[${data_group_id}][${data_field_id}]`}
            required={required}
            defaultValue={value}
            {...rest}
          />
        </div>
      </Fragment>
    );
  }
  if (field_type == "select") {
    return (
      <Fragment>
        <div className="col-sm-12 col-md-4 form-label">
          {name}
          {required && <RequiredLabel />}
        </div>
        <Select
          className="col-sm-12 col-md-8"
          options={selectFields.filter(
            (d) => d.select_field_type_id == data_type
          )}
          value={selectFields
            .filter((d) => d.select_field_type_id == data_type)
            .find((d) => d.value == value)}
          placeholder={"Seleccione un valor"}
          onChange={handleSelectChange}
          required={required}
        />
      </Fragment>
    );
  }
  if (field_type == "maintainer") {
    const parentMaintainers = parent
      ? selectMaintainers.filter((d) => d.data_type == parent?.name)
      : [];
    const filteredMaintainers = selectMaintainers.filter(
      (d) =>
        d.data_type == data_type &&
        (parent && parentId ? d.parent_id == parentId : true)
    );
    const selectedMaintainer = filteredMaintainers.find(
      (d) => d.value == value
    );

    return (
      <Fragment>
        {parent && (
          <Fragment>
            <div className="col-sm-12 col-md-4 form-label mb-3">
              {parent?.human_name} {name}
              {required && <RequiredLabel />}
            </div>
            <Select
              className="col-sm-12 col-md-8 mb-3"
              options={parentMaintainers}
              value={parentMaintainers.find((pm) => pm.value == parentId)}
              placeholder={"Seleccione un valor"}
              onChange={handleParentChange}
              required={required}
            />
          </Fragment>
        )}
        <div className="col-sm-12 col-md-4 form-label">
          {name}
          {required && <RequiredLabel />}
        </div>
        <Select
          className="col-sm-12 col-md-8"
          options={filteredMaintainers}
          value={selectedMaintainer}
          placeholder={"Seleccione un valor"}
          onChange={handleSelectChange}
          name={`data[${data_group_id}][${data_field_id}]`}
          required={required}
        />
      </Fragment>
    );
  }
  return <div></div>;
};

export default DataFieldForm;
