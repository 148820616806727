/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from "react";
import moment from "moment";
import Document from "../../../../components/Document";

const PaymentRow = ({ payment, editPayment, deletePayment }) => {
  const {
    id,
    payment_type: { name },
    date,
    detail,
    title,
    value,
    documents_with_url: documents,
  } = payment;

  return (
    <tr>
      <td>{moment(date).format("DD/MM/YYYY")}</td>
      <td>{name}</td>
      <td>{title}</td>
      <td
        className="no-wrap text-truncate"
        style={{ maxWidth: "150px" }}
        title={detail || ""}
      >
        {detail}
      </td>
      <td className="text-right">{window.formatAmount(value)}</td>
      <td>
        <div className="d-inline">
          {documents.map((document) => (
            <Document key={document.id} document={document} />
          ))}
        </div>
      </td>
      <td>
        <div className="dropdown">
          <i
            data-toggle="dropdown"
            className="mdi mdi-dots-vertical"
            style={{ fontSize: "20px" }}
          />
          <div className="dropdown-menu dropdown-menu-right">
            <button
              className="dropdown-item"
              onClick={() => editPayment(payment)}
            >
              Editar
            </button>
            <button
              className="dropdown-item"
              onClick={() => deletePayment(payment)}
            >
              Eliminar
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PaymentRow;
