/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState, useCallback } from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";

const Errors = (props) => {
  const { errors } = props;
  const errorsWrapper = useRef(null);

  useEffect(() => {
    if (errors.length > 0) {
      window.scrollTo(0, errorsWrapper.current.offsetTop);
    }
  }, [errors]);

  if (errors.length == 0) {
    return "";
  }
  if (!Array.isArray(errors)) {
    return (
      <div className="alert alert-danger" role="alert" ref={errorsWrapper}>
        <h5>Ha ocurrido un error guardando los cambios</h5>
      </div>
    );
  }
  return (
    <div className="alert alert-danger" role="alert" ref={errorsWrapper}>
      <h4>Los siguientes errores impidieron que se aplicaran los cambios:</h4>
      <ul>
        {errors.map((message, i) => (
          <li key={i}>{message}</li>
        ))}
      </ul>
    </div>
  );
};

export default Errors;
