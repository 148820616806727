export const randomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const separateData = (data, dateType, titles) => {
  const [actual, previous] = titles;
  const separated = { [actual]: [], [previous]: [] };
  data.forEach((d) => {
    titles.forEach((title) => {
      if (d[title]) separated[title].push(d);
    });
  });
  return separated;
};

export const setSunBurstData = (cases, type, title) => {
  const data = { children: [] };
  const procedures = {};
  const [, dateKey] = title.split(":");
  cases.forEach((c) => {
    const { name: procedureName } = c;
    const key = Object.keys(c).find((k) => dateKey.includes(k));
    const value = c[key];
    if (procedures[procedureName]) {
      data.children.find((ch) => ch.name === procedureName).value += value;
    } else {
      const procedureColor = randomColor();
      procedures[procedureName] = procedureColor;
      data.children.push({
        name: procedureName,
        hex: procedureColor,
        value,
      });
    }
  });
  return data;
};
