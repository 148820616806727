/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";
import CommentsShow from "../Show/index";
import CommentsForm from "../Form/index";

const CaseStagesIndex = (props) => {
  const { owner, ownerType, csrf } = props;
  const [comments, setComments] = useState(owner.comments);
  const [newComment, setNewComment] = useState({ content: "" });

  const handleSaveComment = useCallback((comment) => {
    comments.push(comment);
    setComments([...comments]);
    setNewComment({ content: "" });
  });

  return (
    <div className="card-body">
      {comments
        .filter((d) => d.enabled)
        .sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
        .map((comment, key) => {
          return <CommentsShow key={key} comment={comment} />;
        })}

      <div className="form-group">
        <CommentsForm
          owner={owner}
          ownerType={ownerType}
          csrf={csrf}
          commentProps={newComment}
          handleSaveComment={handleSaveComment}
        />
      </div>
    </div>
  );
};

export default CaseStagesIndex;
