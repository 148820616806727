/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { NOTIFICATIONS_URL, NOTIFICATION_URL } from "./constants";

export const ViewNotification = (csrf, notificationId) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post(`${NOTIFICATION_URL}.json`, {
    notification_id: notificationId,
    withCredentials: true,
  });
};

export const BringNotifications = (csrf, length) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.get(`${NOTIFICATIONS_URL}.json?length=${length}`, {
    withCredentials: true,
  });
};
