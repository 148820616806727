// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";

const SelectCommune = (props) => {
  const { className, entityProps, states, communes } = props;
  const [entity, setEntity] = useState(entityProps);
  const [communesOptions, setCommunesOptions] = useState([]);

  const setCommunes = (value) => {
    setCommunesOptions(communes.filter((d) => d[2] == value));
  };

  useEffect(() => {
    if (entity && entity.address_state_id) {
      setCommunes(entity.address_state_id);
    }
  }, [communes]);

  const handleStateChange = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setCommunes(value);
    setEntity({ ...entity, address_commune_id: "" });
  });

  const handleCommuneChange = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setEntity({ ...entity, address_commune_id: value });
  });

  return (
    <div className="row col-6 px-0 mx-0">
      <div className="form-group col-md-6">
        <label className="form-label" htmlFor={`${className}_address_state_id`}>
          Región
        </label>
        <select
          className="form-control custom-select"
          name={`${className}[address_state_id]`}
          id={`${className}_address_state_id`}
          onChange={handleStateChange}
          defaultValue={entity && entity.address_state_id}
        >
          <option value="">Seleccione una región</option>
          {states.map((d, index) => (
            <option key={index} value={d[1]}>
              {d[0]}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group col-md-6">
        <label
          className="form-label"
          htmlFor={`${className}_address_commune_id`}
        >
          Comuna
        </label>
        <select
          className="form-control custom-select"
          name={`${className}[address_commune_id]`}
          id={`${className}_address_commune_id`}
          value={(entity && entity.address_commune_id) || ""}
          onChange={handleCommuneChange}
        >
          <option value="">Seleccione una comuna</option>
          {communesOptions.map((d, index) => (
            <option key={index} value={d[1]}>
              {d[0]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectCommune;
