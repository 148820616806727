/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from "react";
import Table from "../../../components/Table";

const Index = (props) => {
  const { versions } = props;

  const columns = [
    { label: "Tipo", key: "type", value: (d) => d.type },
    {
      label: "Usuario",
      key: "user_name",
      value: (d) => d.user_name,
    },
    {
      label: "Modelo",
      key: "model_name",
      value: (d) => d.model_name,
    },
    { label: "Fecha", key: "date", value: (d) => d.date },
    {
      label: "Campo",
      key: "field",
      value: (d) => d.field,
    },
    {
      label: "Valor previo",
      key: "previous",
      value: (d) => d.previous,
    },
    {
      label: "Valor actual",
      key: "actual",
      value: (d) => d.actual,
    },
  ];

  return (
    <div className="row">
      <Table
        columns={columns}
        entityClass={"versions"}
        rows={versions}
        pagination={{
          page: 1,
          pages: 1,
        }}
        customColumns={[]}
        clickeable={false}
      />
    </div>
  );
};

export default Index;
