/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { PieChart, Tooltip, Pie, ResponsiveContainer } from "recharts";
import Legend from "recharts/lib/component/Legend";
import Loader from "../../../components/Loader";

const PieGraph = ({ data, titles, loading, compare }) => {
  const colors = ["#8884d8", "#82ca9d"];
  return (
    <Fragment>
      {titles.map((title, index) => {
        if (!compare && index === 1) return null;

        return (
          <div className="col-md-6 col-xs-12">
            <ResponsiveContainer width={"99%"} height={300}>
              {loading ? (
                <Loader />
              ) : (
                <PieChart width={730} height={250}>
                  <Pie
                    data={data}
                    dataKey={title}
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={90}
                    fill={colors[index]}
                    label
                  />
                  <Tooltip
                    formatter={(value) =>
                      new Intl.NumberFormat("es").format(value)
                    }
                  />
                  <Legend payload={[{ value: title, type: "line" }]} />
                </PieChart>
              )}
            </ResponsiveContainer>
          </div>
        );
      })}
    </Fragment>
  );
};

PieGraph.propTypes = {
  data: PropTypes.array.isRequired,
  titles: PropTypes.array,
  compare: PropTypes.bool,
};

PieGraph.defaultProps = {
  titles: [],
  compare: true,
};

export default PieGraph;
