/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from "react";
import Table from "../../../../components/Table";
import Errors from "../../../../components/Layouts/Errors";

const Index = (props) => {
  const { errors, doctorFalmedAgreements, pagination } = props;
  const columns = [
    {
      label: "Fecha convenio",
      key: "date_agreement",
      value: (d) => d.date_agreement,
    },
    {
      label: "Fecha desde",
      key: "date_from",
      value: (d) => d.date_from,
    },
    {
      label: "Fecha hasta",
      key: "date_to",
      value: (d) => d.date_to,
    },
  ];

  return (
    <div className="row">
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <Table
        columns={columns}
        entityClass={"doctorFalmedAgreements"}
        rows={doctorFalmedAgreements}
        pagination={{ page: 1, pages: 1 }}
        customColumns={[]}
      />
    </div>
  );
};

export default Index;
