/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import Select from "../../../../components/Forms/Select";
import DoctorsSelect from "../../../../containers/maintainers/doctors/Select";

const styles = {
  fs1: {
    fontSize: '12px'
  }
};

const CaseDoctorsForm = (props) => {
  const {
    procedureStages,
    handleOnRemoveSelect,
    handleOnChangeSelect,
    stepNumber,
    form,
    setForm,
    extraordinaryStatuses,
    csrf,
  } = props;

  const irjs = Array.from(Array(26).keys()).map((d) => ({
    label: d,
    value: d,
  }));

  const handleAddSelect = useCallback((entities) => {
    form.case_doctors_attributes.push({
      case_stages_attributes: [{}],
      enabled: true,
    });
    setForm({ ...form });
  });

  const handleOnChangeStageSelect = useCallback((event, index) => {
    const { value } = event;
    if (
      form.case_doctors_attributes[index].case_stages_attributes.length === 0
    ) {
      form.case_doctors_attributes[index].case_stages_attributes.push({
        procedure_stage_id: null,
        case_doctor_id: form.case_doctors_attributes.id,
      });
    }
    form.case_doctors_attributes[
      index
    ].case_stages_attributes[0].procedure_stage_id = value;
    setForm({ ...form });
  });

  const handleOnChangeIRJSelect = useCallback((event, index) => {
    const { value } = event;
    form.case_doctors_attributes[index].irj = value;
    setForm({ ...form });
  });

  const handleOnChangeStateSelect = useCallback((event, index) => {
    const { value } = event;
    form.case_doctors_attributes[index].extraordinary_state = value;
    setForm({ ...form });
  });

  const handleOnChangeFirstAttentionDate = useCallback((event, index) => {
    form.case_doctors_attributes[index].first_attention_date = event.target.value;
    setForm({ ...form });
  })

  let index = 0;

  const { case_doctors_attributes } = form;
  return (
    <div>
      <div className="card-header">
        <div className="col-12">
          <h4 className="font-weight-bold mb-3">
            <div className="badge badge-pill badge-info font-16 mr-2">
              {stepNumber}
            </div>
            Seleccione Médicos
          </h4>
        </div>
      </div>
      <div className="card-body">
        {case_doctors_attributes.map((d, i) => {
          if (!d.enabled) {
            return "";
          }
          index += 1;

          const {
            doctor_id,
            irj,
            case_stages_attributes,
            extraordinary_state,
            first_attention_date
          } = d;
          
          const anyStage = case_stages_attributes.length > 0;
          const lastStage = anyStage
            ? case_stages_attributes[case_stages_attributes.length - 1]
            : null;
          const value = lastStage
            ? procedureStages.find(
                (p) => p.value === lastStage.procedure_stage_id
              )
            : null;
          const blankOption = { label: "Seleccionar", value: "" };
          const options = [
            ...(value && "id" in lastStage
              ? procedureStages.filter((p) => p.value >= value.value)
              : procedureStages),
            blankOption,
          ];
          return (
            <div className="row border border-light rounded p-3 mb-4" key={i}>
              <div className="col-md-1 col-6 first-order mb-1 pt-4">{index}</div>
              <div className="col-md-10 col-12 third-order">
                <div className="row">
                  <div className="col-md-4 col-12 mb-1">
                    <label className="mb-0" style={styles.fs1}>Médico</label>
                    <DoctorsSelect
                      index={i}
                      doctor_id={doctor_id}
                      handleOnChangeSelect={handleOnChangeSelect}
                      csrf={csrf}
                    />
                  </div>
                  <div className="col-md-2 col-12 mb-1">
                  <label className="mb-0" style={styles.fs1}>Etapa Inicial</label>
                    <Select
                      options={options}
                      value={value || blankOption}
                      placeholder="Etapa inicial"
                      name="procedure_stage_id"
                      disabled={!!form.id && lastStage?.id}
                      onChange={(e) => handleOnChangeStageSelect(e, i)}
                    />
                  </div>
                  {extraordinary_state && (
                    <div className="col-md-2 col-12 mb-1">
                      <label className="mb-0" style={styles.fs1}>Aceptación</label>
                      <Select
                        options={extraordinaryStatuses}
                        value={extraordinaryStatuses.find(
                          (e) => e.value == extraordinary_state
                        )}
                        name="extraordinary_state"
                        onChange={(e) => handleOnChangeStateSelect(e, i)}
                      />
                    </div>
                  )}
                  <div className="col-md-2 col-12">
                  <label className="mb-0" style={styles.fs1}>IRJ</label>
                    <Select
                      options={irjs}
                      value={irjs.find((e) => e.value == irj)}
                      placeholder="IRJ"
                      name="irj"
                      onChange={(e) => handleOnChangeIRJSelect(e, i)}
                    />
                  </div>
                  <div className="col-md-2 col-12">
                    <label className="mb-0" style={styles.fs1}>Primera atención al médico</label>
                    <input 
                      defaultValue={first_attention_date}
                      type="date"
                      className="form-control"
                      name="first_attention_date"
                      onChange={(e) => handleOnChangeFirstAttentionDate(e, i)}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-1 col-6 second-order mb-1 pt-4">
                <div
                  className="btn btn-sm btn-danger"
                  onClick={() => handleOnRemoveSelect(i, "doctors")}
                >
                  <i className="mdi mdi-close"></i>
                  <span>Eliminar</span>
                </div>
              </div>
            </div>
          );
        })}
        <div className="col-12 mt-3">
          <div
            className="btn btn-sm btn-success"
            onClick={() => handleAddSelect("doctors")}
          >
            <i className="mdi mdi-plus"></i>
            Agregar Médico
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseDoctorsForm;
