/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import React from "react";
import Errors from "../../../../components/Layouts/Errors";
import BootstrapPagination from "../../../../components/Table/BootstrapPagination";

const Index = (props) => {
  const { errors, entityClass, entityReferences, entities, pagination } = props;

  const setGetParameter = (key, value) => {
    const urlParams = new URLSearchParams(location.search.substr(1));
    urlParams.set(key, value);
    location.search = urlParams.toString();
  };

  return (
    <div className="row">
      <div className="col-12">
        <Errors errors={errors} />
      </div>
      <div className="col-12">
        <div className="card">
          <div className="table-responsive">
            <table className="table table-hover table-outline table-vcenter card-table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  {entityReferences.map((d, i) => (
                    <th key={i}>{d.label}</th>
                  ))}
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {entities.map((entity, index) => (
                  <tr key={index}>
                    <td>{entity.name}</td>
                    {entityReferences.map((d, i) => (
                      <th key={i}>
                        {entity[d.attribute_name] &&
                          entity[d.attribute_name].name}
                      </th>
                    ))}
                    <td>
                      {entity.enabled ? (
                        <span className="badge badge-success">Activo</span>
                      ) : (
                        <span className="badge badge-light">Inactivo</span>
                      )}
                    </td>
                    <td className="nowrap">
                      <a
                        className="btn btn-sm btn-success"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-title="Editar"
                        href={`/maintainers/${entity.id}/edit?entity_class=${entityClass}`}
                        data-original-title=""
                        title=""
                      >
                        <i className="mdi mdi-account-edit"></i>
                      </a>
                      {entity.enabled ? (
                        <a
                          className="btn btn-sm btn-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          data-title="Desactivar"
                          rel="nofollow"
                          data-method="delete"
                          href={`/maintainers/${entity.id}?entity_class=${entityClass}`}
                          data-original-title=""
                          title=""
                        >
                          <i className="mdi mdi-toggle-switch"></i>
                        </a>
                      ) : (
                        <a
                          className="btn btn-sm btn-secondary"
                          data-toggle="tooltip"
                          data-placement="top"
                          data-title="Activar"
                          href={`/maintainers/${entity.id}/enable?entity_class=${entityClass}`}
                          data-original-title=""
                          title=""
                        >
                          <i className="mdi mdi-toggle-switch-off"></i>
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-12">
            <BootstrapPagination
              setGetParameter={setGetParameter}
              entityClass={entityClass}
              pagination={pagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
