/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  createTemplatesManagement,
  updateTemplatesManagement,
} from "../../../../services/templates_managements";
import Select from "../../../../components/Forms/Select";
import Errors from "../../../../components/Layouts/Errors";
import { CASE_DATA, DOCTOR_DATA, IDENTIFIERS } from "./constants";

const Form = ({
  csrf,
  templates_management: templateManagementProps,
  procedures,
  template: templateProps,
}) => {
  const [templateManagement, setTemplateManagement] = useState({
    ...templateManagementProps,
    template: templateProps,
  });
  const [errors, setErrors] = useState([]);

  const handleInputChange = useCallback((e) => {
    const {
      target: { name, value },
    } = e;
    setTemplateManagement({ ...templateManagement, [name]: value });
  });

  const handleSelectChange = useCallback((event, object) => {
    const { name } = object;
    const { value } = event;
    setTemplateManagement({ ...templateManagement, [name]: value });
  });

  const handleFileChange = useCallback((e) => {
    const {
      target: {
        files: [template],
      },
    } = e;
    setTemplateManagement({ ...templateManagement, template });
  });

  const createOrUpdate = useCallback(() => {
    const { id } = templateManagement;
    const formData = new FormData();
    const { template, ...acceptedKeysObject } = templateManagement;
    Object.keys(acceptedKeysObject).forEach((key) => {
      formData.append(`templates_management[${key}]`, templateManagement[key]);
    });
    formData.append(
      "templates_management[template]",
      template?.signed_id ? template.signed_id : template
    );
    return id
      ? updateTemplatesManagement(id, csrf, formData)
      : createTemplatesManagement(csrf, formData);
  });

  const submitSuccess = useCallback(() => {
    const { id } = templateManagement;
    const message = `Template ${id ? "actualizado" : "creado"} con éxito`;
    // eslint-disable-next-line no-alert
    alert(message);
    window.location.href = "/templates_managements";
  });

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    createOrUpdate()
      .then(() => submitSuccess())
      .catch((error) => {
        setErrors(error.response.data);
      });
  });

  const { name, procedure_id, template } = templateManagement;
  const procedure = procedures.find((p) => p.value == procedure_id);

  return (
    <form onSubmit={handleSubmit}>
      <Errors errors={errors} />
      <div className="row">
        <div className="form-group col-6">
          <label className="form-label">Nombre</label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={name}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-6">
          <Select
            label="Materia"
            options={procedures}
            value={procedure}
            name="procedure_id"
            onChange={handleSelectChange}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-6">
          <label className="form-label">
            Template (.docx)
            {template?.id ? (
              <a
                href={template?.url}
                key={document.id}
                className="ml-1"
                download
              >
                (Actual: Descargar)
              </a>
            ) : (
              ""
            )}
          </label>
          <input
            className="form-control"
            type="file"
            name="template"
            onChange={handleFileChange}
            required={!template?.signed_id}
          />
        </div>
      </div>
      {procedure?.value && (
        <div className="row p-2">
          <div className="font-weight-light font-italic">
            *Los valores modificables para esta materia son:
            <br />
            {Object.keys(procedure?.identifier)
              .filter((i) => IDENTIFIERS[i] && procedure?.identifier[i])
              .map((i) => `{{${IDENTIFIERS[i]}}}`)
              .join(", ")}
            ,
            <br />
            {CASE_DATA.join(", ")},
            <br />
            {DOCTOR_DATA.join(", ")}, y
            <br />
            {procedure?.fields
              .filter((field) => field.enabled && field.group_enabled)
              .map(
                (field) =>
                  `{{${field.data_group_name}.${field.data_field_name}}}`
              )
              .join(", ")}
            .
          </div>
        </div>
      )}
      <div className="actions">
        <input
          type="submit"
          className="btn btn-success float-right"
          name="commit"
          value="Guardar"
        />
      </div>
    </form>
  );
};

export default Form;
