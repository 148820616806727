/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import RequiredLabel from "../../../../components/Forms/RequiredLabel";

const DoctorsNew = (props) => {
  const { form, setForm } = props;

  const handleInputChange = useCallback((event) => {
    const {
      target: { value, name },
    } = event;
    form.doctor_attributes[name] = value;
    setForm({ ...form });
  });

  return (
    <div>
      <div className="row">
        <div className="form-group col-12">
          <RequiredLabel label="RUT" htmlFor="doctor_nin" />
          <input
            className="form-control"
            type="text"
            defaultValue={""}
            name="nin"
            id="doctor_nin"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <label className="form-label" htmlFor="doctor_surname">
            Primer apellido
          </label>
          <input
            className="form-control"
            type="text"
            defaultValue={""}
            name="surname"
            id="doctor_surname"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-12">
          <label className="form-label" htmlFor="doctor_second_surname">
            Segundo apellido
          </label>
          <input
            className="form-control"
            type="text"
            defaultValue={""}
            name="second_surname"
            id="doctor_second_surname"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-12">
          <label className="form-label" htmlFor="doctor_name">
            Nombres
          </label>
          <input
            className="form-control"
            type="text"
            defaultValue={""}
            name="name"
            id="doctor_name"
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DoctorsNew;
