import axios from "axios";
import { USERS_URL } from "./constants";

export const createUser = (csrf, user) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post(`${USERS_URL}.json`, {
    user,
    withCredentials: true,
  });
};

export const updateUser = (id, csrf, user) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`${USERS_URL}/${id}.json`, {
    user,
    withCredentials: true,
  });
};
