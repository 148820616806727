/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import AttributeRow from "./AttributeRow";

const DoctorInformation = ({ doctor }) =>
  doctor ? (
    <Fragment>
      <div className="row font-weight-bold m-2">{doctor.name}</div>
      <AttributeRow attribute="ICM" value={doctor.icm} />
      <AttributeRow attribute="Rut" value={doctor.nin} />
      <AttributeRow attribute="Especialidad" value={doctor.speciality} />
      <AttributeRow attribute="Teléfono" value={doctor.phone} />
      <AttributeRow
        attribute="Condición Socio Falmed"
        value={doctor.falmed_status}
      />
      <AttributeRow
        attribute="Condición Socio Colmed"
        value={doctor.colmed_status}
      />
    </Fragment>
  ) : (
    <div />
  );

export default DoctorInformation;
