/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";
import Select from "../../../../components/Forms/Select";
import DateSelect from "../../../../components/Forms/DateSelect";
import { createPayment, updatePayment } from "../../../../services/payments";
import Loader from "../../../../components/Loader";
import { numberFormatter } from "../../../../components/Forms/Utils";
import { NOT_NUMBER_REGEX } from "./constants";

const PaymentsForm = (props) => {
  const {
    caseProps,
    handleSavePayment,
    index,
    setPayments,
    form,
    paymentTypes,
    csrf,
  } = props;

  const handleDateChange = useCallback((date) => {
    const { id } = form;
    setPayments("date", date, id);
  });

  const handleInputChange = useCallback((event) => {
    const { id } = form;
    const {
      target: { value, name },
    } = event;
    setPayments(name, value, id);
  });

  const createOrUpdate = useCallback(() => {
    const { id: caseId } = caseProps;
    const { id } = form;
    const formData = new FormData();
    const { documents_with_url, ...acceptedKeysObject } = form;
    Object.keys(acceptedKeysObject).forEach((key) => {
      formData.append(`payment[${key}]`, form[key]);
    });
    documents_with_url.forEach((document) => {
      formData.append(
        "payment[documents][]",
        document.signed_id ? document.signed_id : document
      );
    });
    return id
      ? updatePayment(caseId, id, csrf, formData)
      : createPayment(caseId, csrf, formData);
  });

  const handleCurrencyChange = useCallback(
    (event) => {
      const { id } = form;
      const {
        target: { name, value },
      } = event;
      setPayments(name, value.replace(NOT_NUMBER_REGEX, ""), id);
    },
    [form]
  );

  const submitSuccess = useCallback((response) => {
    const { id } = caseProps;
    const message = `Gasto ${id ? "actualizado" : "creado"} con éxito`;
    // eslint-disable-next-line no-alert
    alert(message);
    handleSavePayment(response.data);
  });

  const handleSubmit = useCallback(() => {
    const { id } = form;
    setPayments("loading", true, id);
    createOrUpdate().then(
      (response) => submitSuccess(response),
      // eslint-disable-next-line no-console
      (err) => {
        setPayments("loading", false, id);
        window.alert(err.response.data.join(".\n"));
      }
    );
  });

  const { date, title, detail, value, id, payment_type_id, loading } = form;
  const modalId = id ? `#attach${id}-${index}` : "#attachNewPayment";

  return (
    <tr>
      <td>
        <DateSelect
          wrapperClassName="input-group"
          className="form-control"
          ariaLabelledBy=""
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
          name="date"
          selected={Date.parse(date) || new Date()}
          onChange={handleDateChange}
        />
      </td>
      <td>
        <select
          className="form-control"
          name="payment_type_id"
          onChange={handleInputChange}
          value={payment_type_id}
        >
          <option value="" className="font-italic">
            Seleccionar Tipo de Gasto
          </option>
          {paymentTypes.map((type) => (
            <option value={type.value} key={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      </td>
      <td>
        <input
          className="form-control"
          type="text"
          name="title"
          value={title || ""}
          onChange={handleInputChange}
        />
      </td>
      <td>
        <input
          className="form-control"
          type="text"
          name="detail"
          value={detail || ""}
          onChange={handleInputChange}
        />
      </td>
      <td>
        <input
          className="form-control"
          type="text"
          name="value"
          value={numberFormatter(value)}
          onChange={handleCurrencyChange}
        />
      </td>
      <td data-toggle="modal" data-target={modalId}>
        <a
          data-toggle="tooltip"
          data-placement="top"
          data-title="Adjuntar archivos"
          className="btn btn-primary"
        >
          <i className="mdi mdi-file"></i>
        </a>
      </td>
      <td>
        <div className="d-flex flex-row mb-4">
          <div className="btn btn-success" onClick={handleSubmit}>
            Guardar
            {loading && <Loader />}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PaymentsForm;
