/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Uppy from "@uppy/core";
import { DragDrop } from "@uppy/react";

const PaymentAttach = ({ id, index, documents, changeDocument }) => {
  const uppy = new Uppy({
    meta: { type: "document" },
    restrictions: { maxNumberOfFiles: 1 },
    autoProceed: false,
  });

  uppy.on("file-added", (file) => {
    changeDocument("documents_with_url", [...documents, file.data], id);
  });

  const deleteDocument = useCallback((ind) => {
    documents.splice(ind, 1);
    changeDocument("documents_with_url", documents, id);
  });

  const modalId = id ? `attach${id}-${index}` : "attachNewPayment";
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Adjuntar Archivos
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">x</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-12 form-group">
              <DragDrop
                uppy={uppy}
                locale={{
                  strings: {
                    dropHereOr: "Arrastrar para adjuntar o %{browse}",
                    browse: "navegar sistema",
                  },
                }}
              />
            </div>
            {documents.length > 0 && (
              <div className="col-12">
                Archivos por subir:
                <div>
                  {documents.map((document, i) => (
                    <span key={i}>
                      <i
                        className="mdi mdi-delete text-danger p-5"
                        onClick={() => deleteDocument(i)}
                      />
                      {i + 1}-. {document.name}
                      <br />
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentAttach.propTypes = {
  documents: PropTypes.array,
};

PaymentAttach.defaultProps = {
  documents: [],
};

export default PaymentAttach;
