/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Sunburst, LabelSeries, Hint } from "react-vis";
import {
  EXTENDED_DISCRETE_COLOR_RANGE,
  tipStyle,
  boxStyle,
  LABEL_STYLE,
} from "../../constants";
import { setSunBurstData } from "../../utils";

const SunburstGraph = ({ data, title, type }) => {
  const settedCases = setSunBurstData(data, type, title);
  const [clicked, setClicked] = useState(false);
  const [finalValue, setFinal] = useState(title);
  const [pathValue, setPathValue] = useState(false);

  const getKeyPath = (node) => {
    if (!node.parent) {
      return [title];
    }

    return [node.data?.name || node.name].concat(getKeyPath(node.parent));
  };

  const updateData = (node, keyPath) => {
    if (node.children) {
      node.children.map((child) => updateData(child, keyPath));
    }
    if (!node.hex) {
      node.style = {
        fill: EXTENDED_DISCRETE_COLOR_RANGE[5],
      };
    }
    const nonSelected = keyPath && !keyPath[node.name];
    node.style = {
      ...node.style,
      fillOpacity: nonSelected ? 0.2 : 1,
    };
    return node;
  };

  const [myData, setData] = useState(updateData(settedCases, false));
  const [hoveredCell, setCell] = useState(false);

  const handleMouseOver = (node) => {
    if (clicked) {
      return;
    }
    const path = getKeyPath(node).reverse();
    const pathAsMap = path.reduce((res, row) => {
      res[row] = true;
      return res;
    }, {});
    setFinal(path[path.length - 1]);
    setPathValue(path.join(" > "));
    setData(updateData(settedCases, pathAsMap));
    setCell(node);
  };

  const handleMouseOut = () => {
    if (!clicked) {
      setPathValue(false);
      setFinal(title);
      setData(updateData(settedCases, false));
    }
    setCell(false);
  };

  const handleOnClick = () => {
    setClicked(!clicked);
  };

  const buildValue = (cell) => {
    const { radius, angle, angle0 } = cell;
    const truedAngle = (angle + angle0) / 2;
    return {
      x: radius * Math.cos(truedAngle),
      y: radius * Math.sin(truedAngle),
    };
  };

  const cellCount = (cell) => {
    const label = type === "payments" ? "CLP" : "";
    const result = new Intl.NumberFormat("es").format(
      hoveredCell.children?.reduce((sum, c) => sum + c.value, 0) || cell.value
    );
    return `${result} ${label}`;
  };

  useEffect(() => {
    setData(updateData(settedCases, false));
  }, [data]);

  return (
    <Fragment>
      <div>
        <Sunburst
          animation
          hideRootNode
          colorType="literal"
          data={myData}
          height={300}
          width={350}
          style={{
            stroke: "#ddd",
            strokeOpacity: 0.3,
            strokeWidth: "0.5",
          }}
          getSize={(d) => d.value}
          getColor={(d) => d.hex}
          onValueMouseOver={handleMouseOver}
          onValueMouseOut={handleMouseOut}
          onValueClick={handleOnClick}
        >
          {finalValue && (
            <LabelSeries
              data={[{ x: 0, y: 0, label: finalValue, style: LABEL_STYLE }]}
            />
          )}
          {hoveredCell ? (
            <Hint value={buildValue(hoveredCell)}>
              <div style={tipStyle}>
                <div style={{ ...boxStyle, background: hoveredCell.clr }} />
                {cellCount(hoveredCell)}
              </div>
            </Hint>
          ) : null}
        </Sunburst>
        <div>{pathValue}</div>
      </div>
    </Fragment>
  );
};

SunburstGraph.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  dateType: PropTypes.string,
  titles: PropTypes.array,
};

SunburstGraph.defaultProps = {
  title: "Casos",
  type: "cases",
  dateType: "month",
  titles: [],
};

export default SunburstGraph;
