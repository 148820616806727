/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { Fragment, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import DataField from "./DataField";

const RequiredLabelStyled = styled.div`
  font-size: 0.75rem;
`;

const CaseShow = ({
  caseProps,
  dataGroups,
  selectMaintainers,
  selectFields,
}) => {
  const reduceToPairs = (arrayToReduce) => {
    return arrayToReduce.reduce((result, value, index, array) => {
      if (index % 2 === 0) result.push(array.slice(index, index + 2));
      return result;
    }, []);
  };

  const parentLabel = useCallback((field) => {
    const {
      data_type,
      parent: { name },
      id,
      data_group_id,
    } = field;
    const { data } = caseProps;
    const value = data && data[data_group_id] ? data[data_group_id][id] : null;
    const actualMaintainer = selectMaintainers.find(
      (m) => m.data_type == data_type && m.value == value
    );
    const parentMaintainer = selectMaintainers.find(
      (m) => m.data_type == name && m.value == actualMaintainer?.parent_id
    );
    return parentMaintainer?.label || "";
  }, []);

  const { id } = caseProps;
  return (
    <div>
      {reduceToPairs(dataGroups).map((dataGroupPair, i) => {
        return (
          <div className="card-deck mt-3" key={i}>
            {dataGroupPair.map((dataGroup, j) => {
              const { name } = dataGroup;
              return (
                <div className="card" key={j}>
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      {name}
                      <a
                        className="btn btn-success"
                        href={`/cases/${id}/edit${
                          dataGroup.primary ? "" : "_second_step"
                        }`}
                      >
                        <i className="mdi mdi-pencil"></i>
                        Modificar
                      </a>
                    </div>
                  </div>
                  <div className="card-body mt-4 mb-5">
                    <div className="table-responsive">
                      <table className="table table-hover table-outline table-vcenter text-nowrap card-table ">
                        <tbody>
                          {dataGroup.data_fields.map(
                            (dataField, k) =>
                              dataField.enabled && (
                                <Fragment key={k}>
                                  {dataField.parent && (
                                    <tr>
                                      <td>
                                        {dataField.parent.human_name} ({dataField.name})
                                      </td>
                                      <td>{parentLabel(dataField)}</td>
                                    </tr>
                                  )}
                                  <tr key={k}>
                                    <td>{dataField.name}</td>
                                    <td>
                                      <DataField
                                        dataGroup={dataGroup}
                                        dataField={dataField}
                                        selectFields={selectFields}
                                        selectMaintainers={selectMaintainers}
                                        caseProps={caseProps}
                                      />
                                    </td>
                                  </tr>
                                </Fragment>
                              )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default CaseShow;
