/* eslint-disable import/extensions */
import axios from "axios";
import { MOVEMENTS_URL } from "./constants";

export const createMovement = (csrf, formData) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;
  const caseId = formData.get("movement[case_id]");
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return axios.post(`/cases/${caseId}/${MOVEMENTS_URL}.json`, formData, config);
};

export const updateMovement = (id, csrf, formData) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;
  const caseId = formData.get("movement[case_id]");
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return axios.put(
    `/cases/${caseId}/${MOVEMENTS_URL}/${id}.json`,
    formData,
    config
  );
};

export const deleteMovement = (caseId, id, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.delete(`/cases/${caseId}/${MOVEMENTS_URL}/${id}.json`);
};
