/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BootstrapPagination from "./BootstrapPagination";
import { sortedArray } from "../../components/Forms/Utils";

const Styles = styled.div`
  padding: 1rem;

  table {
    td {
      a.clickeable {
        color: black;
      }
    }
  }
`;

const Table = (props) => {
  const {
    entityClass,
    columns,
    rows,
    actions,
    pagination,
    sortColumns,
    customActions,
    customColumns,
    customClassName,
    clickeable,
  } = props;

  const setGetParameter = (key, value) => {
    const urlParams = new URLSearchParams(location.search.substr(1));
    urlParams.set(key, value);
    location.search = urlParams.toString();
  };

  const handleOnClickSort = useCallback((key) => {
    return setGetParameter("order", key);
  });

  const sortedRows = customColumns.includes("enabled")
    ? rows.sort((a, b) => (a.enabled > b.enabled ? -1 : 1))
    : rows;

  const deleteUrl = (row) => {
    let url = `/${entityClass}/${row.id}`;
    if (entityClass === "cases") url += `/case_doctors/${row.case_doctor_id}`;
    return url;
  };

  return (
    <Styles className="col-12">
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table
              className={`table table-hover table-outline table-vcenter text-nowrap card-table ${customClassName}`}
            >
              <thead>
                <tr>
                  {columns.map((column, i) => (
                    <th key={i}>
                      {column.label}
                      {sortColumns && (
                        <span>
                          <i
                            className="mdi mdi-chevron-up"
                            onClick={() =>
                              handleOnClickSort(`${column.key} asc`)
                            }
                          ></i>
                          <i
                            className="mdi mdi-chevron-down"
                            onClick={() =>
                              handleOnClickSort(`${column.key} desc`)
                            }
                          ></i>
                        </span>
                      )}
                    </th>
                  ))}
                  {customColumns.includes("enabled") && <th>Estado</th>}
                  {customColumns.includes("actions") && <th>Acciones</th>}
                </tr>
              </thead>
              <tbody>
                {sortedRows.map((d, i) => (
                  <tr key={i}>
                    {columns.map((column, j) => (
                      <td key={`${i}-${j}`}>
                        {clickeable ? (
                          <a
                            className="clickeable"
                            href={`/${entityClass}/${d.id}`}
                          >
                            {column.value(d)}
                          </a>
                        ) : (
                          column.value(d)
                        )}
                      </td>
                    ))}
                    {customColumns.includes("enabled") && (
                      <td>
                        {d.enabled ? (
                          <span className="badge badge-success">Activo</span>
                        ) : (
                          <span className="badge badge-light">Inactivo</span>
                        )}
                      </td>
                    )}
                    {customColumns.includes("actions") && (
                      <td className="nowrap">
                        {customActions && customActions(d)}
                        {actions.includes("show") && (
                          <a
                            className="btn btn-sm btn-primary mr-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            data-title="Ver detalle"
                            href={`/${entityClass}/${d.id}`}
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </a>
                        )}
                        {actions.includes("edit") && (
                          <a
                            className="btn btn-sm btn-success mr-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            data-title="Editar"
                            href={`/${entityClass}/${d.id}/edit`}
                          >
                            <i className="mdi mdi-pencil"></i>
                          </a>
                        )}
                        {actions.includes("delete") &&
                          (d.enabled ? (
                            <a
                              className="btn btn-sm btn-danger"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-title="Desactivar"
                              rel="nofollow"
                              data-method="delete"
                              data-confirm="¿Estás seguro que quieres desactivarlo?"
                              href={deleteUrl(d)}
                            >
                              <i className="mdi mdi-toggle-switch"></i>
                            </a>
                          ) : (
                            <a
                              className="btn btn-sm btn-secondary"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-title="Activar"
                              href={`${deleteUrl(d)}/enable`}
                              data-original-title=""
                              title=""
                            >
                              <i className="mdi mdi-toggle-switch-off"></i>
                            </a>
                          ))}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-12">
            <BootstrapPagination
              setGetParameter={setGetParameter}
              entityClass={entityClass}
              pagination={pagination}
            />
          </div>
        </div>
      </div>
    </Styles>
  );
};

Table.propTypes = {
  entityClass: PropTypes.string,
  columns: PropTypes.array,
  customColumns: PropTypes.array,
  rows: PropTypes.array,
  actions: PropTypes.array,
  pagination: PropTypes.object,
  customActions: PropTypes.func,
  customClassName: PropTypes.string,
  sortColumns: PropTypes.bool,
  clickeable: PropTypes.bool,
};

Table.defaultProps = {
  entityClass: "",
  columns: [],
  customColumns: ["enabled", "actions"],
  rows: [],
  actions: ["edit", "delete"],
  pagination: {},
  customActions: () => {},
  customClassName: "",
  sortColumns: true,
  clickeable: true,
};

export default Table;
