/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import Checkbox from "../../../../components/Forms/Checkbox";

const DoctorCaseFieldsForm = (props) => {
  const { form, setForm } = props;
  const identifiers = [
    { name: "IRJ", field_key: "irj" },
    { name: "Sub-estado de causa", field_key: "sub_cause_status_id" },
    {
      name: "Fecha de primera atención",
      field_key: "first_attention_date",
    },
    { name: "Número de póliza", field_key: "policy_id" },
    { name: "Fecha de póliza", field_key: "policy_date" },
    {
      name: "Especialidad de los hechos",
      field_key: "specialty_of_the_fact_id",
    },
    { name: "Siniestro 1", field_key: "sinister_1" },
    { name: "Siniestro 2", field_key: "sinister_2" },
    { name: "Siniestro 3", field_key: "sinister_3" },
    { name: "Siniestro 4", field_key: "sinister_4" },
    { name: "Acta de directorio", field_key: "board_of_directors" },
    { name: "Asegurabilidad", field_key: "insurable" },
  ];

  const onChangeCheckbox = useCallback((event) => {
    const {
      target: { checked, name },
    } = event;
    form.procedure_case_doctor_field_attributes[name] = checked;
    setForm({ ...form });
  });

  const { procedure_case_doctor_field_attributes } = form;

  return (
    <div>
      {identifiers.map((entry, index) => {
        const { name, field_key } = entry;

        return (
          <div className="col-12" key={index}>
            <Checkbox
              checked={
                procedure_case_doctor_field_attributes[field_key] || false
              }
              label={name}
              onChange={onChangeCheckbox}
              id={`doctor-case-field-${field_key}`}
              name={field_key}
            />
          </div>
        );
      })}
    </div>
  );
};

export default DoctorCaseFieldsForm;
