/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { createCase, updateCase } from "../../../../services/cases";
import Errors from "../../../../components/Layouts/Errors";
import DataFieldForm from "./DataFieldForm";
import CasesForm from "../../requests/Form/CasesForm";

const CaseNextStepForm = ({
  caseProps,
  dataGroups,
  selectMaintainers,
  selectFields,
  csrf,
}) => {
  const [form, setForm] = useState({
    id: caseProps.id,
    data: caseProps.data || {},
    existing_cases_attributes: caseProps.existing_cases || [],
  });
  const [errors, setErrors] = useState(caseProps.errors || []);

  const createOrUpdate = useCallback(() => {
    const { id } = caseProps;
    return id ? updateCase(id, csrf, form) : createCase(csrf, form);
  });

  const submitSuccess = useCallback(() => {
    const { id } = caseProps;
    const message = `Caso ${id ? "actualizada" : "creada"} con éxito`;
    // eslint-disable-next-line no-alert
    alert(message);
    window.location.href = `/cases/${caseProps.id}`;
  });

  const handleAddSelect = useCallback(() => {
    const { id, existing_cases_attributes } = form;
    existing_cases_attributes.push({
      case_id: null,
      enabled: true,
      actual_case_id: id,
    });
    setForm({ ...form, existing_cases_attributes });
  });

  const handleOnRemoveSelect = useCallback((index) => {
    const { existing_cases_attributes } = form;
    if (existing_cases_attributes[index].id) {
      existing_cases_attributes[index].enabled = false;
    } else {
      existing_cases_attributes.splice(index, 1);
    }
    setForm({ ...form, existing_cases_attributes });
  });

  const handleOnChangeSelect = useCallback((event, object, index) => {
    const { existing_cases_attributes } = form;
    const { value } = event;
    const { name } = object;
    existing_cases_attributes[index][name] = value;
    setForm({ ...form, existing_cases_attributes });
  });

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    createOrUpdate()
      .then(() => submitSuccess())
      .catch((error) => {
        setErrors(error.response.data);
      });
  });

  const { procedure_id } = caseProps;
  const { existing_cases_attributes } = form;
  return (
    <form className="row" onSubmit={handleSubmit}>
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className="col-12">
              <h4 className="font-weight-bold mb-3">
                <div className="badge badge-pill badge-info font-16 mr-2">
                  2.1
                </div>
                Datos secundarios
              </h4>
            </div>
          </div>
          <div className="card-body">
            {dataGroups &&
              dataGroups.map((dataGroup, i) => (
                <div className="card" key={i}>
                  <div className="card-header">{dataGroup.name}</div>
                  <div className="card-body mt-4 mb-5" key={i}>
                    {dataGroup.data_fields.map(
                      (dataField, j) =>
                        dataField.enabled && (
                          <div key={j} className="row form-group">
                            <DataFieldForm
                              dataGroup={dataGroup}
                              dataField={dataField}
                              selectFields={selectFields}
                              selectMaintainers={selectMaintainers}
                              form={form}
                              setForm={setForm}
                            />
                          </div>
                        )
                    )}
                  </div>
                </div>
              ))}
          </div>

          <CasesForm
            stepNumber={2.2}
            procedure_id={procedure_id}
            selectedCases={existing_cases_attributes}
            handleAddSelect={handleAddSelect}
            handleOnRemoveSelect={handleOnRemoveSelect}
            handleOnChangeSelect={handleOnChangeSelect}
            handleSubmitAndCreateCase={() => {}}
            csrf={csrf}
          />

          <div className="card-body">
            <div className="actions">
              <button type="submit" className="btn btn-success float-right">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

CaseNextStepForm.propTypes = {
  csrf: PropTypes.string.isRequired,
  caseProps: PropTypes.object.isRequired,
  selectMaintainers: PropTypes.array,
  selectFields: PropTypes.array,
  dataGroup: PropTypes.array,
};

CaseNextStepForm.defaultProps = {
  selectMaintainers: [],
  selectFields: [],
  dataGroup: [],
};

export default CaseNextStepForm;
