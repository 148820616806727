/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from "react";
import Table from "../../../../components/Table";
import Errors from "../../../../components/Layouts/Errors";
import SearchForm from "../../doctors/Index/SearchForm";

const Index = (props) => {
  const {
    params,
    addressComunes,
    courts,
    errors,
    requests,
    pagination,
  } = props;

  const columns = [
    {
      label: "ID",
      key: "identifiers.id",
      value: (d) => d.identifier && d.identifier.id,
    },
    {
      label: "Materia",
      key: "procedures.name",
      value: (d) => d.procedure && d.procedure.name,
    },
    {
      label: "Médico",
      key: "doctors.surname",
      value: (d) =>
        d.doctor && `${d.doctor.surname || ""}, ${d.doctor.name || ""}`,
    },
    { label: "Fecha de Consulta", key: "date", value: (d) => d.date },
    { label: "Fecha de término", key: "date", value: (d) => d.answer_date },
    {
      label: "Abogado Principal",
      key: "name",
      value: (d) => {
        const {
          users: [lawyer],
        } = d;
        return `${lawyer?.first_name || ""} ${lawyer?.last_name || ""}`;
      },
    },
    {
      label: "Estado",
      key: "cause_status.name",
      value: (d) => d.cause_status && d.cause_status.name,
    },
  ];

  return (
    <div className="row">
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <SearchForm
              params={params}
              addressComunes={addressComunes}
              courts={courts}
              withIdentifierAndCourt={false}
            />
          </div>
        </div>
      </div>

      <Table
        columns={columns}
        entityClass={"requests"}
        rows={requests}
        pagination={pagination}
      />
    </div>
  );
};

export default Index;
