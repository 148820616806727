/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import DateSelect from "../../../../components/Forms/DateSelect";
import Select from "../../../../components/Forms/Select";
import RequiredLabel from "../../../../components/Forms/RequiredLabel";
import { momentUtcFormat } from "../../../../components/Dates";

const RequestInfo = ({
  procedures,
  judgmentTypes,
  judgmentSubtypes,
  handleDateChange,
  handleInputChange,
  form,
  setForm,
}) => {
  const handleChangeProcedure = useCallback((event) => {
    const { value } = event;
    setForm({
      ...form,
      procedure_id: value,
      judgment_type_id: null,
      judgment_subtype_id: null,
    });
  });

  const handleChangeJudgmentType = useCallback((event) => {
    const { value } = event;
    setForm({ ...form, judgment_type_id: value, judgment_subtype_id: null });
  });

  const handleChangeJudgmentSubType = useCallback((event) => {
    const { value } = event;
    setForm({ ...form, judgment_subtype_id: value });
  });

  const {
    detail,
    date,
    procedure_id,
    judgment_type_id,
    judgment_subtype_id,
  } = form;

  return (
    <form>
      <div className="col-12">
        <h6 className="font-weight-bold mb-3">
          <div className="badge badge-pill badge-info font-16 mr-2">3</div>
          Consulta
        </h6>
      </div>
      <div className="col-12">
        <div className="form-group">
          <RequiredLabel label={"Fecha consulta"} />
          <DateSelect
            wrapperClassName="input-group"
            className="form-control"
            ariaLabelledBy=""
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            name="date"
            selected={momentUtcFormat(date)}
            onChange={handleDateChange}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="form-group">
          <RequiredLabel label={"Materia"} />
          <Select
            options={procedures}
            value={procedures.find((d) => d.value == procedure_id)}
            onChange={handleChangeProcedure}
            placeholder="Seleccione una materia"
            name="procedure_id"
          />
        </div>
      </div>

      <div className="col-12">
        <div className="form-group">
          <RequiredLabel label={"Tipo de juicio"} />
          <Select
            options={judgmentTypes.filter(
              (d) => d.procedure_id == procedure_id
            )}
            value={judgmentTypes.find((d) => d.value == judgment_type_id)}
            onChange={handleChangeJudgmentType}
            placeholder="Seleccione un tipo de juicio"
            name="judgment_type_id"
          />
        </div>
      </div>

      <div className="col-12">
        <div className="form-group">
          <RequiredLabel label={"Subtipo de juicio"} />
          <Select
            options={judgmentSubtypes.filter(
              (d) => d.judgment_type_id == judgment_type_id
            )}
            value={judgmentSubtypes.find((d) => d.value == judgment_subtype_id)}
            onChange={handleChangeJudgmentSubType}
            placeholder="Seleccione un subtipo de juicio"
            name="judgment_subtype_id"
          />
        </div>
      </div>

      <div className="col-12">
        <div className="form-group">
          <label className="form-label" htmlFor="detail">
            Detalle consulta
          </label>
          <textarea
            className="form-control"
            type="text"
            rows="5"
            value={detail || ""}
            name="detail"
            id="detail"
            onChange={handleInputChange}
          />
        </div>
      </div>
    </form>
  );
};

export default RequestInfo;
