/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useState } from "react";
import Checkbox from "../../../../components/Forms/Checkbox";

const EntryTypeForm = ({ entryTypes, entryType, onChange }) => {
  const [selected, setSelected] = useState(entryType);

  const onChangeCheckbox = useCallback((event) => {
    const {
      target: { value },
    } = event;
    setSelected(value);
    onChange(event);
  });

  return (
    <div className="row">
      <div className="col-12">
        <h6 className="font-weight-bold mb-3">
          <div className="badge badge-pill badge-info font-16 mr-2">1</div>
          Vía de Ingreso
        </h6>
      </div>

      {entryTypes.map((entry) => {
        const { id, name } = entry;

        return (
          <div className="col-12" key={id}>
            <div className="form-group">
              <Checkbox
                checked={selected == id}
                label={name}
                onChange={onChangeCheckbox}
                id={`entry-type-${id}`}
                name={"entry_type_id"}
                value={id}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EntryTypeForm;
