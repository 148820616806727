/* eslint-disable import/prefer-default-export */
export const sortedArray = (arr, field) =>
  arr.sort((a, b) => (a[field] > b[field] ? 1 : -1));

export const numberFormatter = (number) => {
  if (!number) return number;
  const [entire, decimals] = number.toString().split(",");
  const hasDecimals = number.includes(",");

  return `${entire.replace(/\B(?=(\d{3})+\b)/g, ".")}${
    hasDecimals ? `,${decimals}` : ""
  }`;
};
