import createChannel from ".";

let callback;
const setChannel = () =>
  createChannel(
    {
      channel: "NotificationsChannel",
    },
    {
      received(payload) {
        if (callback) {
          callback.call(null, payload);
        }
      },
    }
  );

const setCallback = (fn) => {
  callback = fn;
};

export { setChannel, setCallback };
