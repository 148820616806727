/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from "react";
import Table from "../../../../components/Table";
import Errors from "../../../../components/Layouts/Errors";

const RequestsIndex = (props) => {
  const {
    errors,
    requests,
    pagination
  } = props;

  const columns = [
    {
      label: "ID",
      key: "id",
      value: (d) => d.identifier_id,
    },
    {
      label: "Materia",
      key: "procedure_name",
      value: (d) => d.procedure_name,
    },
    {
      label: "Fecha de Consulta",
      key: "date",
      value: (d) => moment(d.date).format("DD/MM/YYYY"),
    },
    {
      label: "Fecha de Término",
      key: "answer_date",
      value: (d) => (d.answer_date ? moment(d.answer_date).format("DD/MM/YYYY") : ""),
    },
    {
      label: "Abogado Principal",
      key: "lawyer_name",
      value: (d) => d.lawyer_name,
    }
  ];

  return (
    <div className="row">
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <Table
        columns={columns}
        entityClass={"requests"}
        rows={requests} 
        actions={["show"]}
      />
    </div>
  );
};

export default RequestsIndex;
