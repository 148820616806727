/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Select from "../../../../components/Forms/Select";

const SearchForm = (props) => {
  const { params, procedures, judgmentTypes, judgmentSubtypes } = props;
  const [searchParams, setSearchParams] = useState({
    names: params.names,
    nin: params.nin,
    icm: params.icm,
    identifier_id: params.identifier_id,
    identifier_ids: params.identifier_ids,
    procedure_id: params.procedure_id,
    judgment_type_id: params.judgment_type_id,
    judgment_subtype_id: params.judgment_subtype_id,
    stage: params.stage,
    data: params.data,
  });

  const handleChange = useCallback((e) => {
    const {
      target: { value, name },
    } = e;
    setSearchParams({ ...searchParams, [name]: value });
  });

  const handleSelectChange = useCallback((e, t) => {
    const { value } = e;
    const { name } = t;
    setSearchParams({
      ...searchParams,
      [name]: value,
      ...(name === "judgment_type_id" ? { judgment_subtype_id: "" } : {}),
      ...(name === "procedure_id"
        ? { judgment_subtype_id: "", judgment_type_id: "" }
        : {}),
    });
  });

  const handleOnClick = useCallback(() => {
    const urlParams = new URLSearchParams();
    Object.keys(searchParams).forEach((key) => {
      if (searchParams[key]) {
        urlParams.set(key, searchParams[key]);
      }
    });
    urlParams.set("page", 1);
    location.search = urlParams.toString();
  });

  const handleKeyPress = useCallback((e) => {
    const { key } = e;
    if (key === "Enter") handleOnClick();
  });

  const { procedure_id, judgment_type_id, judgment_subtype_id } = searchParams;
  const emptyOption = { value: "", label: "Ninguno" };
  const proceduresOptions = [emptyOption, ...procedures];
  const judgmentTypesOptions = [emptyOption, ...judgmentTypes];
  const judgmentSubtypesOptions = [emptyOption, ...judgmentSubtypes];

  return (
    <div className="container" onKeyPress={handleKeyPress}>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="names">
            Nombres y/o Apellido Médico
          </label>
        </div>
        <div className="form-group col-md-9">
          <input
            type="text"
            name="names"
            id="names"
            defaultValue={searchParams.names}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="nin">
            R.U.N.
          </label>
        </div>
        <div className="form-group col-md-9">
          <input
            type="text"
            name="nin"
            id="nin"
            defaultValue={searchParams.nin}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="icm">
            ICM
          </label>
        </div>
        <div className="form-group col-md-9">
          <input
            type="text"
            name="icm"
            id="icm"
            defaultValue={searchParams.icm}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="case_id">
            Id
          </label>
        </div>
        <div className="form-group col-md-9">
          <input
            type="text"
            name="identifier_id"
            id="identifier_id"
            defaultValue={searchParams.identifier_id}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="identifier_ids">
            RUC, ROL, RIT o Aux
          </label>
        </div>
        <div className="form-group col-md-9">
          <input
            type="text"
            name="identifier_ids"
            id="identifier_ids"
            defaultValue={searchParams.identifier_ids}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="stage">
            Etapa
          </label>
        </div>
        <div className="form-group col-md-9">
          <input
            type="text"
            name="stage"
            id="stage"
            defaultValue={searchParams.stage}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="data">
            Dato
          </label>
        </div>
        <div className="form-group col-md-9">
          <input
            type="text"
            name="data"
            id="data"
            defaultValue={searchParams.data}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="icm">
            Materia
          </label>
        </div>
        <div className="form-group col-md-9">
          <Select
            placeholder={"Seleccione una materia"}
            options={proceduresOptions}
            name="procedure_id"
            value={proceduresOptions.find((d) => procedure_id == d.value)}
            onChange={handleSelectChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="icm">
            Tipo de Juicio
          </label>
        </div>
        <div className="form-group col-md-9">
          <Select
            placeholder={"Seleccione un tipo de juicio"}
            options={
              procedure_id
                ? judgmentTypesOptions.filter(
                    (d) => d.procedure_id == procedure_id
                  )
                : judgmentTypesOptions
            }
            name="judgment_type_id"
            value={judgmentTypesOptions.find(
              (d) => judgment_type_id == d.value
            )}
            onChange={handleSelectChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="icm">
            Subtipo de juicio
          </label>
        </div>
        <div className="form-group col-md-9">
          <Select
            placeholder={"Seleccione un subtipo de juicio"}
            options={
              judgment_type_id
                ? judgmentSubtypesOptions.filter(
                    (d) => d.judgment_type_id == judgment_type_id
                  )
                : judgmentSubtypesOptions
            }
            name="judgment_subtype_id"
            value={judgmentSubtypesOptions.find(
              (d) => judgment_subtype_id == d.value
            )}
            onChange={handleSelectChange}
          />
        </div>
      </div>
      <div className="actions text-center pt-2">
        <div className="btn btn-success" onClick={handleOnClick}>
          Buscar
        </div>
      </div>
    </div>
  );
};

SearchForm.propTypes = {
  params: PropTypes.object.isRequired,
  procedures: PropTypes.array,
  judgmentTypes: PropTypes.array,
  judgmentSubtypes: PropTypes.array,
};

SearchForm.defaultProps = {
  procedures: [],
  judgmentTypes: [],
  judgmentSubtypes: [],
};

export default SearchForm;
