import axios from "axios";

export const getCases = (csrf, params) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;
  return axios.get(`/home/cases_dashboard.json?${params}`);
};

export const getPayments = (csrf, params) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;
  return axios.get(`/home/payments_dashboard.json?${params}`);
};
