/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
import React, { useState, useCallback, Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import Select from "../../../../components/Forms/Select";

const SearchForm = (props) => {
  const { addressComunes, courts, params, withIdentifierAndCourt } = props;
  const [searchParams, setSearchParams] = useState({
    names: params.names,
    nin: params.nin,
    icm: params.icm,
    identifier_id: params.identifier_id,
    identifier_ids: params.identifier_ids,
    pacient_names: params.pacient_names,
    address_commune_id: params.address_commune_id,
    court_id: params.court_id,
  });

  const handleChange = useCallback((e) => {
    const {
      target: { value, name },
    } = e;
    setSearchParams({ ...searchParams, [name]: value });
  });

  const handleSelectChange = useCallback((e, t) => {
    const { value } = e;
    const { name } = t;
    setSearchParams({
      ...searchParams,
      [name]: value,
      ...(name === "address_commune_id" && value === ""
        ? { court_id: "" }
        : {}),
    });
  });

  const handleOnClick = useCallback(() => {
    const urlParams = new URLSearchParams();
    Object.keys(searchParams).forEach((key) => {
      if (searchParams[key]) {
        urlParams.set(key, searchParams[key]);
      }
    });
    urlParams.set("page", 1);
    location.search = urlParams.toString();
  });

  const handleKeyPress = useCallback((e) => {
    const { key } = e;
    if (key === "Enter") handleOnClick();
  });

  const { address_commune_id, court_id } = searchParams;
  const addressCommunesOptions = [
    { value: "", label: "Ninguno" },
    ...addressComunes,
  ];

  return (
    <div className="container" onKeyPress={handleKeyPress}>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="names">
            Nombres y/o Apellido Médico
          </label>
        </div>
        <div className="form-group col-md-9">
          <input
            type="text"
            name="names"
            id="names"
            defaultValue={searchParams.names}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="nin">
            RUT
          </label>
        </div>
        <div className="form-group col-md-9">
          <input
            type="text"
            name="nin"
            id="nin"
            defaultValue={searchParams.nin}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="icm">
            ICM
          </label>
        </div>
        <div className="form-group col-md-9">
          <input
            type="text"
            name="icm"
            id="icm"
            defaultValue={searchParams.icm}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label className="form-label" htmlFor="case_id">
            ID
          </label>
        </div>
        <div className="form-group col-md-9">
          <input
            type="text"
            name="identifier_id"
            id="identifier_id"
            defaultValue={searchParams.identifier_id}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      {withIdentifierAndCourt === true && (
        <Fragment>
          <div className="row">
            <div className="col-md-3">
              <label className="form-label" htmlFor="identifier_ids">
                RUC, ROL o RIT
              </label>
            </div>
            <div className="form-group col-md-9">
              <input
                type="text"
                name="identifier_ids"
                id="identifier_ids"
                defaultValue={searchParams.identifier_ids}
                className="form-control"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label className="form-label" htmlFor="icm">
                Tribunal
              </label>
            </div>
            <div className="row col-md-9 justify-content-center">
              <Select
                className="col-sm-12 col-md-6"
                placeholder={"Seleccione una comuna"}
                options={addressCommunesOptions}
                name="address_commune_id"
                value={addressCommunesOptions.find(
                  (d) => address_commune_id == d.value
                )}
                onChange={handleSelectChange}
              />
              <Select
                className="col-sm-12 col-md-6"
                proplaceholderp={"Seleccione un tribunal"}
                options={courts.filter(
                  (d) => d.address_commune_id == address_commune_id
                )}
                value={courts.find((d) => court_id == d.value)}
                name="court_id"
                onChange={handleSelectChange}
              />
            </div>
          </div>
        </Fragment>
      )}
      <div className="actions text-center pt-2">
        <div className="btn btn-success" onClick={handleOnClick}>
          Buscar
        </div>
      </div>
    </div>
  );
};

SearchForm.defaultProps = {
  withIdentifierAndCourt: true,
};

SearchForm.propTypes = {
  withIdentifierAndCourt: PropTypes.bool,
};

export default SearchForm;
