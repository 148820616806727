/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from "react";
import SearchForm from "./SearchForm";
import Table from "../../../../components/Table";
import Errors from "../../../../components/Layouts/Errors";

const Index = (props) => {
  const { params, currentUser, addressComunes, courts, errors, doctors, pagination } = props;
  const columns = [
    {
      label: "ICM",
      key: "doctor_colmeds.icm",
      value: (d) => d.doctor_colmed && d.doctor_colmed.icm,
    },
    { label: "RUT", key: "nin", value: (d) => d.nin },
    { label: "Primer Apellido", key: "surname", value: (d) => d.surname },
    {
      label: "Segundo Apellido",
      key: "second_surname",
      value: (d) => d.second_surname,
    },
    { label: "Nombres", key: "name", value: (d) => d.name },
    {
      label: "Falmed Condición Socio",
      key: "doctor_falmeds.membership_status",
      value: (d) => d.membership_status,
    },
    {
      label: "Falmed Estado de pago",
      key: "doctor_falmeds.payment_status",
      value: (d) => d.doctor_falmed && d.doctor_falmed.payment_status,
    },
    {
      label: "Colmed Condición Socio",
      key: "doctor_colmeds.membership_status",
      value: (d) => d.doctor_falmed && d.doctor_colmed.membership_status,
    },
    {
      label: "Colmed Estado de pago",
      key: "doctor_colmeds.payment_status",
      value: (d) => d.doctor_falmed && d.doctor_colmed.payment_status,
    },
  ];

  const actions = currentUser.role_id != 1 ? ['show'] : ['show', 'edit', 'delete']; 

  return (
    <div className="row">
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <SearchForm
              params={params}
              addressComunes={addressComunes}
              courts={courts}
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        entityClass={"doctors"}
        rows={doctors}
        pagination={pagination}
        actions={actions}
      />
    </div>
  );
};

export default Index;
