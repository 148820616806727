/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import updateCaseDoctor from "../../../../services/case_doctors";
import Errors from "../../../../components/Layouts/Errors";
import Select from "../../../../components/Forms/Select";
import DateSelect from "../../../../components/Forms/DateSelect";
import Checkbox from "../../../../components/Forms/Checkbox";
import { momentUtcFormat } from "../../../../components/Dates";

const CaseDoctorsForm = (props) => {
  const {
    procedureDoctorCaseFieldProp,
    currentUser,
    caseDoctorProps,
    causeStatuses,
    subCauseStatuses,
    policies,
    specialtyOfTheFacts,
    csrf,
  } = props;
  const [form, setForm] = useState({
    irj: caseDoctorProps.irj,
    policy_id: caseDoctorProps.policy_id,
    policy_date: caseDoctorProps.policy_date,
    first_attention_date: caseDoctorProps.first_attention_date,
    cause_status_id: caseDoctorProps.cause_status_id,
    sub_cause_status_id: caseDoctorProps.sub_cause_status_id,
    end_date: caseDoctorProps.end_date,
    specialty_of_the_fact_id: caseDoctorProps.specialty_of_the_fact_id,
    sinister_1: caseDoctorProps.sinister_1,
    sinister_2: caseDoctorProps.sinister_2,
    sinister_3: caseDoctorProps.sinister_3,
    sinister_4: caseDoctorProps.sinister_4,
    board_of_directors: caseDoctorProps.board_of_directors,
    insurable: caseDoctorProps.insurable,
  });
  const [errors, setErrors] = useState(caseDoctorProps.errors || []);

  const showInsurableCheckbox = currentUser.role_id === 1 ? true : false; 

  const irjs = Array.from(Array(26).keys()).map((d) => ({
    label: d,
    value: d,
  }));

  const handleDateChange = useCallback((date, name) => {
    setForm({ ...form, [name]: date });
  });

  const handleInputChange = useCallback((event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value });
  });

  const handleSelectChange = useCallback((event, obj) => {
    const { name } = obj;
    const { value } = event;
    setForm({ ...form, [name]: value });
  });

  const handleCheckboxChange = useCallback((event) => {
    const {
      target: { checked, name },
    } = event;
    setForm({ ...form, [name]: checked });
  });

  const handleChangeStatusCause = useCallback((event) => {
    const { value } = event;
    setForm({
      ...form,
      cause_status_id: value,
      sub_cause_status_id: null,
    });
  });

  const submitSuccess = useCallback((response) => {
    const { case_id, id } = caseDoctorProps;
    const message = `Detalle médico actualizado con éxito`;
    // eslint-disable-next-line no-alert
    alert(message);
    window.location.href = `/cases/${case_id}/case_doctors/${id}`;
  });

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const { case_id, id } = caseDoctorProps;
    updateCaseDoctor(case_id, id, csrf, form)
      .then((response) => submitSuccess(response))
      .catch((error) => {
        setErrors(error.response.data);
      });
  });

  const {
    irj,
    sinister_1,
    sinister_2,
    sinister_3,
    sinister_4,
    board_of_directors,
    policy_id,
    policy_date,
    first_attention_date,
    cause_status_id,
    sub_cause_status_id,
    end_date,
    specialty_of_the_fact_id,
    insurable,
  } = form;

  const selectedCauseStatus =
    cause_status_id && causeStatuses.find((d) => d.value == cause_status_id);

  return (
    <form className="row" onSubmit={handleSubmit}>
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className="col-12">
              <h4 className="font-weight-bold mb-3">Detalle médico</h4>
            </div>
          </div>
          <div className="card-body">
            {procedureDoctorCaseFieldProp.irj && (
              <div className="form-group">
                <div className="col-12">
                  <label>IRJ</label>
                  <Select
                    options={irjs}
                    value={irj && irjs.find((e) => e.value == irj)}
                    placeholder="IRJ"
                    name="irj"
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
            )}

            {procedureDoctorCaseFieldProp.sub_cause_status_id && (
              <div>
                <div className="form-group">
                  <div className="col-12">
                    <label>Estado de causa</label>
                    <Select
                      options={causeStatuses}
                      value={selectedCauseStatus}
                      onChange={handleChangeStatusCause}
                      placeholder="Seleccione una estado de causa"
                      name="cause_status_id"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-12">
                    <label>Sub-estado de causa</label>
                    <Select
                      disabled={!cause_status_id}
                      options={subCauseStatuses.filter(
                        (d) => d.cause_status_id == cause_status_id
                      )}
                      value={subCauseStatuses.find(
                        (d) => d.value == sub_cause_status_id
                      )}
                      onChange={handleSelectChange}
                      placeholder="Seleccione un sub-estado de causa"
                      name="sub_cause_status_id"
                      required={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-12">
                    <label className="form-label">Fecha de Término</label>
                    <DateSelect
                      ariaLabelledBy=""
                      dateFormat="dd/MM/yyyy"
                      selected={
                        selectedCauseStatus?.label == "Terminado" &&
                        end_date &&
                        momentUtcFormat(end_date)
                      }
                      onChange={(e) => handleDateChange(e, "end_date")}
                      disabled={
                        !selectedCauseStatus ||
                        selectedCauseStatus.label != "Terminado"
                      }
                      required={true}
                    />
                  </div>
                </div>
              </div>
            )}

            {procedureDoctorCaseFieldProp.first_attention_date && (
              <div className="form-group">
                <div className="col-12">
                  <label>Fecha de primera atención</label>
                  <div className="form-group">
                    <DateSelect
                      wrapperClassName="input-group"
                      className="form-control"
                      ariaLabelledBy=""
                      dateFormat="dd/MM/yyyy"
                      name="first_attention_date"
                      required
                      selected={
                        first_attention_date &&
                        momentUtcFormat(first_attention_date)
                      }
                      onChange={(e) =>
                        handleDateChange(e, "first_attention_date")
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            {procedureDoctorCaseFieldProp.policy_id && (
              <div className="form-group">
                <div className="col-12">
                  <label>Número de póliza</label>
                  <Select
                    options={policies}
                    value={
                      policy_id && policies.find((d) => d.value == policy_id)
                    }
                    onChange={handleSelectChange}
                    placeholder="Seleccione una póliza"
                    name="policy_id"
                  />
                </div>
              </div>
            )}

            {procedureDoctorCaseFieldProp.policy_date && (
              <div className="form-group">
                <div className="col-12">
                  <label>Fecha de póliza</label>
                  <div className="form-group">
                    <DateSelect
                      wrapperClassName="input-group"
                      className="form-control"
                      ariaLabelledBy=""
                      dateFormat="dd/MM/yyyy"
                      name="policy_date"
                      selected={policy_date && momentUtcFormat(policy_date)}
                      onChange={(e) => handleDateChange(e, "policy_date")}
                    />
                  </div>
                </div>
              </div>
            )}

            {procedureDoctorCaseFieldProp.specialty_of_the_fact_id && (
              <div className="form-group">
                <div className="col-12">
                  <label>Especialidad de los hechos</label>
                  <Select
                    options={specialtyOfTheFacts}
                    value={
                      specialty_of_the_fact_id &&
                      specialtyOfTheFacts.find(
                        (d) => d.value == specialty_of_the_fact_id
                      )
                    }
                    onChange={handleSelectChange}
                    placeholder="Especialidad de los hechos"
                    name="specialty_of_the_fact_id"
                  />
                </div>
              </div>
            )}

            {procedureDoctorCaseFieldProp.sinister_1 && (
              <div className="form-group">
                <div className="col-12">
                  <label>Siniestro 1</label>
                  <input
                    type="string"
                    className="form-control"
                    onChange={handleInputChange}
                    name="sinister_1"
                    defaultValue={sinister_1}
                  />
                </div>
              </div>
            )}

            {procedureDoctorCaseFieldProp.sinister_2 && (
              <div className="form-group">
                <div className="col-12">
                  <label>Siniestro 2</label>
                  <input
                    type="string"
                    className="form-control"
                    onChange={handleInputChange}
                    name="sinister_2"
                    defaultValue={sinister_2}
                  />
                </div>
              </div>
            )}

            {procedureDoctorCaseFieldProp.sinister_3 && (
              <div className="form-group">
                <div className="col-12">
                  <label>Siniestro 3</label>
                  <input
                    type="string"
                    className="form-control"
                    onChange={handleInputChange}
                    name="sinister_3"
                    defaultValue={sinister_3}
                  />
                </div>
              </div>
            )}

            {procedureDoctorCaseFieldProp.sinister_4 && (
              <div className="form-group">
                <div className="col-12">
                  <label>Siniestro 4</label>
                  <input
                    type="string"
                    className="form-control"
                    onChange={handleInputChange}
                    name="sinister_4"
                    defaultValue={sinister_4}
                  />
                </div>
              </div>
            )}

            {procedureDoctorCaseFieldProp.board_of_directors && (
              <div className="form-group">
                <div className="col-12">
                  <label>Acta de directorio</label>
                  <input
                    type="string"
                    className="form-control"
                    onChange={handleInputChange}
                    name="board_of_directors"
                    defaultValue={board_of_directors}
                  />
                </div>
              </div>
            )}

            {showInsurableCheckbox && procedureDoctorCaseFieldProp.insurable && (
              <div className="form-group">
                <Checkbox
                  id="insurable"
                  checked={insurable}
                  label="Asegurabilidad"
                  onChange={handleCheckboxChange}
                  name="insurable"
                />
              </div>
            )}

          </div>

          <div className="card-body">
            <div className="actions">
              <button type="submit" className="btn btn-success float-right">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CaseDoctorsForm;
