/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";

const CommentsShow = (props) => {
  const {
    comment: {
      created_at,
      content,
      user: { first_name, last_name },
    },
  } = props;
  return (
    <div className="card-body">
      <div className="d-flex">
        <i className="mdi mdi-account-circle md-36 mr-4 text-secondary"></i>
        <div className="d-flex flex-column justify-content-center">
          <div className="row">
            <b className="mr-2">{moment(created_at).format("DD/MM/YYYY")} - </b>
            <b className="mr-2">
              *{first_name} {last_name}*
            </b>
            <b>{content}</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsShow;
