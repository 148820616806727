/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { createEntity, updateEntiy } from "../../../../services/services";
import Select from "../../../../components/Forms/Select";
import Errors from "../../../../components/Layouts/Errors";

const Form = (props) => {
  const {
    csrf,
    entityClass,
    entityProps,
    entityReferences: entityReferencesProp,
  } = props;
  const [entityReferences, setEntityReferences] = useState(
    entityReferencesProp
  );
  const [entity, setEntity] = useState(entityProps);
  const [errors, setErrors] = useState([]);

  const handleOnChangeSelector = useCallback((entityReference, index) => {
    const modifiedEntity = entityReferences.splice(index, 1);
    modifiedEntity[0].entityReference = entityReference;
    setEntityReferences([modifiedEntity[0], ...entityReferences]);
    setEntity({
      ...entity,
      [`${modifiedEntity[0].attribute_name}_id`]: entityReference.value,
    });
  });

  const handleChange = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setEntity({ ...entity, name: value });
  });

  const handleSubmit = useCallback((e) => {
    if (entity.id) {
      updateEntiy(csrf, entity, entityClass, entity.id).then(
        (response) => {
          window.location = response.data.url;
        },
        (error) => {
          setErrors(error.response && error.response.data);
        }
      );
    } else {
      createEntity(csrf, entity, entityClass).then(
        (response) => {
          window.location = response.data.url;
        },
        (error) => {
          setErrors(error.response && error.response.data);
        }
      );
    }
  });

  return (
    <div>
      <Errors errors={errors} />
      <input hidden="hidden" name="entity_class" defaultValue={entityClass} />
      <div className="row">
        <div className="form-group col-6">
          <label className="form-label">Nombre</label>
          <input
            className="form-control"
            type="text"
            defaultValue={entity.name}
            onInput={handleChange}
          />
        </div>
      </div>
      {entityReferences
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .map((d, i) => (
          <div className="row" key={i}>
            <div className="form-group col-6">
              <Select
                label={d.label}
                options={d.options}
                value={d.entityReference}
                onChange={(e) => handleOnChangeSelector(e, i)}
              />
            </div>
          </div>
        ))}
      <div className="actions">
        <input
          type="submit"
          className="btn btn-success float-right"
          name="commit"
          value="Guardar"
          data-disable-with="Guardar"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default Form;
