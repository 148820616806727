/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { getCases } from "../../services/dashboard";
import { DATE_LAST_10_YEARS, DATE_LAST_12_MONTHS } from "./constants";
import GrahType from "./GraphType";

const GraphWrapper = ({ csrf, filters, loadingCases, setLoadingCases }) => {
  const [newCases, setNewCases] = useState([]);
  const [activeCases, setActiveCases] = useState([]);
  const [finishedCases, setFinishedCases] = useState([]);
  const [reopenedCases, setReopenedCases] = useState([]);
  const [casesByRegion, setCasesByRegion] = useState([]);
  const [payments, setPayments] = useState([]);
  const [sinisters, setSinisters] = useState([]);
  const [timeOut, setTimeOut] = useState(0);
  const initialRender = useRef(true);

  const paramFormat = (value) => {
    if (Array.isArray(filters[value])) {
      return filters[value].length
        ? `${value}=${filters[value].map((v) => v.value)}`
        : "";
    }
    return `${value}=${filters[value]}`;
  }

  const setParams = () => {
    return Object.keys(filters)
      .filter((value) => filters[value])
      .map((value) => paramFormat(value))
      .join("&");
  };

  const datesCompared = useCallback(() => {
    const { date_type } = filters;
    const today = new Date();
    const previous = today.setYear(today.getFullYear() - 1);
    const config = { year: "numeric" };
    if (date_type === DATE_LAST_10_YEARS) return ["Total"];
    return [
      new Date().toLocaleDateString("es-ES", config),
      new Date(previous).toLocaleDateString("es-ES", config),
    ];
  });

  const dateType = () => {
    const { date_type } = filters;
    return date_type === DATE_LAST_12_MONTHS
      ? "Últimos 12 meses"
      : "Últimos 10 años";
  };

  const getCasesSuccess = (response) => {
    const {
      news,
      active,
      finished,
      reopened,
      region,
      payments: newPayments,
      sinisters: newSinisters,
    } = response;
    setNewCases(news);
    setActiveCases(active);
    setFinishedCases(finished);
    setReopenedCases(reopened);
    setCasesByRegion(region);
    setPayments(newPayments);
    setSinisters(newSinisters);
  };

  const getInformation = useCallback(() => {
    setLoadingCases(true);
    const params = setParams();
    getCases(csrf, params)
      .then(
        (response) => {
          getCasesSuccess(response.data);
        },
        (error) => {
          console.log(error.data);
        }
      )
      .finally(() => setLoadingCases(false));
  }, [setLoadingCases, filters]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      getInformation();
    }
  }, [
    filters.lawyer_id,
    filters.doctor_id,
    filters.entry_date_begin,
    filters.entry_date_end,
    filters.fact_date_begin,
    filters.fact_date_end,
    filters.date_type,
    filters.cause_status_name,
  ]);

  useEffect(() => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(() => {
        getInformation();
      }, 800)
    );
  }, [
    filters.ruc,
    filters.rut,
    filters.icm,
    filters.requests_id,
    filters.procedure_id,
    filters.judgment_type_id,
    filters.judgment_subtype_id,
    filters.address_state_id,
    filters.address_commune_id,
    filters.speciality_id,
  ]);

  const { date_type, graphType } = filters;
  const datesTitle = datesCompared();
  return (
    <Fragment>
      <div className="col-12 mt-3 p-3 card">
        <h4 className="text-capitalize" style={{ color: "black" }}>
          {dateType()}
        </h4>
        <GrahType
          data={newCases}
          modelTitle="Casos"
          graphTitle="Casos Nuevos"
          loadingCases={loadingCases}
          dateType={date_type}
          datesTitle={datesTitle}
          graphType={graphType}
          modelName="cases"
        />
        <GrahType
          data={activeCases}
          modelTitle="Casos"
          graphTitle="Casos Vigentes"
          loadingCases={loadingCases}
          dateType={date_type}
          datesTitle={["Total"]}
          graphType={graphType}
          modelName="cases"
          compare={false}
        />
        <GrahType
          data={finishedCases}
          modelTitle="Casos"
          graphTitle="Casos Terminados"
          loadingCases={loadingCases}
          dateType={date_type}
          datesTitle={datesTitle}
          graphType={graphType}
          modelName="cases"
        />
        {/* <GrahType
          data={reopenedCases}
          modelTitle="Casos"
          graphTitle="Casos Reabiertos"
          loadingCases={loadingCases}
          dateType={date_type}
          datesTitle={datesTitle}
          graphType={graphType}
          modelName="cases"
        /> */}
        <GrahType
          data={casesByRegion}
          modelTitle="Casos"
          graphTitle="Casos por Región"
          loadingCases={loadingCases}
          dateType={date_type}
          datesTitle={datesTitle}
          graphType={graphType}
          modelName="cases"
          labelHeight={200}
          height={450}
        />
        <GrahType
          data={sinisters}
          modelTitle="Casos"
          graphTitle="Siniestros vigentes"
          loadingCases={loadingCases}
          dateType={date_type}
          datesTitle={["Total"]}
          graphType={graphType}
          modelName="cases"
          compare={false}
        />
        <GrahType
          data={payments}
          modelTitle="Pagos"
          graphTitle="Pagos MM$"
          loadingCases={loadingCases}
          dateType={date_type}
          datesTitle={datesTitle}
          graphType={graphType}
          modelName="payments"
        />
      </div>
    </Fragment>
  );
};

GraphWrapper.propTypes = {
  filters: PropTypes.object.isRequired,
  csrf: PropTypes.string.isRequired,
  loadingCases: PropTypes.bool,
  loadingPayments: PropTypes.bool,
  setLoadingCases: PropTypes.func,
  setLoadingPayments: PropTypes.func,
  addressStates: PropTypes.array,
};

GraphWrapper.defaultProps = {
  loadingCases: false,
  loadingPayments: false,
  setLoadingCases: () => {},
  setLoadingPayments: () => {},
  addressStates: [],
};

export default GraphWrapper;
