/* eslint-disable import/extensions */
import axios from "axios";
import { PAYMENTS_URL } from "./constants";

export const createPayment = (caseId, csrf, formData) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return axios.post(`/cases/${caseId}${PAYMENTS_URL}.json`, formData, config);
};

export const updatePayment = (caseId, id, csrf, formData) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return axios.put(`/cases/${caseId}${PAYMENTS_URL}/${id}.json`,
    formData,
    config
  );
};

export const deletePayment = (caseId, id, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.delete(`/cases/${caseId}/${PAYMENTS_URL}/${id}.json`);
};
