import toastr from "toastr";
import { setChannel, setCallback } from "channels/notification_channel";

const setExtendedTimeout = (title) => {
  if (title === "Atención extraordinaria") return "5000";
  return "1000";
};

const renderNotification = (text, description, link) => {
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: true,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "5000",
    extendedTimeOut: setExtendedTimeout(text),
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    ...(link
      ? {
          onclick: () => {
            window.location.href = link;
          },
        }
      : {}),
  };
  toastr.info(text, description, { iconClass: "toast-custom" });
};

let channel;
document.addEventListener("turbolinks:load", () => {
  if (!channel) {
    channel = setChannel();
    setCallback(({ title, description, link }) => {
      renderNotification(title, description, link);
    });
  }
});
