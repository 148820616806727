/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useCallback } from "react";
import Select from "../../../../components/Forms/Select";
import Checkbox from "../../../../components/Forms/Checkbox";
import RequiredLabel from "../../../../components/Forms/RequiredLabel";

const LawyerForm = (props) => {
  const { procedures, form, setForm } = props;

  const lawyeTypes = [
    { label: "Abogado", value: "lawyer" },
    { label: "Abogado Jefe", value: "chief" },
    { label: "Procurador", value: "procurator" },
  ];

  const onChangeCheckbox = useCallback((event) => {
    const {
      target: { checked, value },
    } = event;
    if (checked) {
      form.lawyer_attributes.lawyer_procedures_attributes.push({
        procedure_id: value,
      });
    } else {
      const selectedLayerProcedureIndex = form.lawyer_attributes.lawyer_procedures_attributes.findIndex(
        (d) => d.procedure_id == value
      );
      const selectedLawyerProcedure =
        form.lawyer_attributes.lawyer_procedures_attributes[
          selectedLayerProcedureIndex
        ];
      if (selectedLawyerProcedure.id) {
        selectedLawyerProcedure._destroy = true;
      } else {
        form.lawyer_attributes.lawyer_procedures_attributes.splice(
          selectedLayerProcedureIndex,
          1
        );
      }
    }
    setForm({ ...form });
  });

  const handleSelectChange = useCallback((e, t) => {
    const { value } = e;
    form.lawyer_attributes.lawyer_type = value;
    setForm({ ...form });
  });

  const {
    lawyer_attributes: { lawyer_type, lawyer_procedures_attributes },
  } = form;

  return (
    <div className="row">
      <div className="col-md-6 col-xs-12">
        <div className="form-group">
          <label className="">Materias</label>
          {procedures.map((procedure, index) => {
            const { label, value } = procedure;
            return (
              <div className="col-12" key={index}>
                <div className="form-group">
                  <Checkbox
                    checked={lawyer_procedures_attributes
                      .filter((d) => !d._destroy)
                      .map((d) => parseInt(d.procedure_id, 10))
                      .includes(value)}
                    label={label}
                    onChange={onChangeCheckbox}
                    id={`lawyer-procedure-${value}`}
                    name="lawyer_procedures_attributes[procedure_id]"
                    value={value}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-md-4 col-xs-12">
        <div className="card-body">
          <div className="form-group">
            <div className="col-12">
              <div className="form-group">
                <RequiredLabel
                  label={"Tipo de abogado"}
                  htmlFor="lawyer_type"
                />
                <Select
                  id="lawyer_type"
                  options={lawyeTypes}
                  value={lawyeTypes.find((d) => d.value == lawyer_type)}
                  onChange={handleSelectChange}
                  placeholder="Seleccione un estado"
                  name="lawyer_attributes[lawyer_type]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LawyerForm;
