/* eslint-disable camelcase */
import axios from "axios";
import { TEMPLATES_URL } from "./constants";

export const createTemplatesManagement = (csrf, formData) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return axios.post(`${TEMPLATES_URL}.json`, formData, config);
};

export const updateTemplatesManagement = (id, csrf, formData) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return axios.put(`${TEMPLATES_URL}/${id}.json`, formData, config);
};
