import React, { useCallback, useState } from "react";
import { Fragment } from "react";
import Checkbox from "../../components/Forms/Checkbox";

const Report = ({ titles, url }) => {
  const [checks, setChecks] = useState(
    titles.map((title) => ({ title, show: true }))
  );

  const handleCheckboxChange = (event, index) => {
    const {
      target: { checked, name },
    } = event;
    const newChecks = checks.map((c) => ({ ...c }));
    newChecks[index][name] = checked;
    setChecks(newChecks);
  };

  const setReportParams = useCallback(() => {
    let param = "?titles=";
    checks.forEach((check) => {
      const { title, show } = check;
      if (show) {
        param += `${title},`;
      }
    });

    return param;
  });

  const params = setReportParams();

  return (
    <Fragment>
      <div className="row p-3">
        <div className="actions col-12">
          <a
            href={`${url}${params}`}
            target="_blank"
            type="button"
            className="btn btn-success float-right"
          >
            Descargar reporte
          </a>
        </div>
        <h3 className="col-12 mb-2">Columnas a mostrar</h3>
        {checks.map((check, index) => {
          const { title, show } = check;

          return (
            <div className="col-md-4 col-xs-12" key={index}>
              <div className="form-group">
                <Checkbox
                  name="show"
                  onChange={(e) => handleCheckboxChange(e, index)}
                  checked={show}
                  label={title}
                  id={title}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default Report;
