/* eslint-disable camelcase */
/* eslint-disable import/extensions */
import axios from "axios";
const config = {
  headers: {
    "content-type": "multipart/form-data",
  },
  withCredentials: true,
};

export const createCaseStage = (case_id, case_doctor_id, data, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post(
    `/cases/${case_id}/case_doctors/${case_doctor_id}/case_stages.json`,
    data,
    config
  );
};

export const updateCaseStage = (case_id, case_doctor_id, id, data, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(
    `/cases/${case_id}/case_doctors/${case_doctor_id}/case_stages/${id}.json`,
    data,
    config
  );
};

export const deleteCaseStage = (case_id, case_doctor_id, id, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.delete(
    `/cases/${case_id}/case_doctors/${case_doctor_id}/case_stages/${id}.json`
  );
};
