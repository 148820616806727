/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useCallback } from "react";
import Select from "../../../../components/Forms/Select";

const LawyersForm = (props) => {
  const {
    lawyers,
    currentLawyer,
    selectedLawyers,
    stepNumber,
    handleAddSelect,
    handleOnRemoveSelect,
    handleOnChangeSelect,
    formName,
  } = props;

  const setFormName = useCallback(() => {
    if (formName === "request") return "de la consulta";
    return "del caso";
  }, [formName]);

  const setTitle = useCallback(() => {
    if (formName === "request") return "Agregar abogados";
    return "Seleccione Abogado";
  }, [formName]);

  useEffect(() => {
    console.log('abogados selected', selectedLawyers.length );
    if (selectedLawyers.length === 0) 
      handleAddSelect("lawyers");
  }, [])

  let index = 0;
  return (
    <div>
      <div className="card-header">
        <div className="col-12">
          <h4 className="font-weight-bold mb-3">
            <div className="badge badge-pill badge-info font-16 mr-2">
              {stepNumber}
            </div>
            {setTitle()}
          </h4>
          (Si usted es abogado del caso, debe incluirse)
        </div>
      </div>
      <div className="card-body">
        {selectedLawyers.map((d, i) => {
          if (!d.enabled) {
            return "";
          }
          index += 1;
          const { lawyer_id } = d;
          /*let lawyer_id = d.lawyer_id;
          if (index == 1) {
            lawyer_id = currentLawyer.id;
            const lawyer = lawyers.find((e) => e.value == currentLawyer.id)
            handleOnChangeSelect(lawyer, { action:'select-option', option: undefined, name: 'lawyer_id' }, index, 'lawyers');
          }*/

          return (
            <div className="row border border-light rounded p-3 mb-4" key={i}>
              <div className="col-md-1 col-6 first-order mb-1">{index}</div>
              <div className="col-md-10 col-12 third-order">
                <Select
                  options={lawyers}
                  value={lawyers.find((e) => e.value == lawyer_id)}
                  placeholder="Seleccione un abogado"
                  name="lawyer_id"
                  onChange={(e, t) => handleOnChangeSelect(e, t, i, "lawyers")}
                />
              </div>
              <div className="col-md-1 col-6 second-order mb-1">
                <div
                  className="btn btn-sm btn-danger"
                  onClick={() => handleOnRemoveSelect(i, "lawyers")}
                >
                  <i className="mdi mdi-close"></i>
                  <span>Eliminar</span>
                </div>
              </div>
            </div>
          );
        })}
        <div className="col-12 mt-3">
          <div
            className="btn btn-sm btn-success"
            onClick={() => handleAddSelect("lawyers")}
          >
            <i className="mdi mdi-plus"></i>
            Agregar Abogado
          </div>
        </div>
      </div>
    </div>
  );
};

LawyersForm.propTypes = {
  formName: PropTypes.string,
};

LawyersForm.defaultProps = {
  formName: "case",
};

export default LawyersForm;
