import "bootstrap";
import "application/application";
import $ from "jquery";
import moment from "moment";
import "../components";
import "../containers";
import "services/notifications";

window.$ = $;
window.jQuery = $;
window.moment = moment;

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

const ReactRailsUJS = require("react_ujs");

const componentRequireContext = require.context("containers", true);
ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.detectEvents();

$(document).ready(() => {
  $('[data-toggle="tooltip"]').tooltip();
});

window.formatAmount = (amount) => {
  return `$ ${Math.round(amount).toLocaleString("es-CL")}`;
};
