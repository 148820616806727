/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "../../../../components/Forms/Select";
import Checkbox from "../../../../components/Forms/Checkbox";
import RequiredLabel from "../../../../components/Forms/RequiredLabel";

const RequiredLabelStyled = styled.div`
  font-size: 0.75rem;
`;
const DataFieldForm = (props) => {
  const {
    dataGroupIndex,
    dataFields,
    selectFieldTypes,
    maintainers,
    form,
    setForm,
    newDataField,
  } = props;

  const fieldTypes = [
    { label: "Entrada", value: "input" },
    { label: "Selector", value: "select" },
    { label: "Mantenedor", value: "maintainer" },
  ];

  const inputFieldTyes = [
    { label: "Verdadero/Falso", value: "bool" },
    { label: "Fecha", value: "date" },
    { label: "Texto", value: "string" },
    { label: "Entero", value: "integer" },
    { label: "Decimal", value: "decimal" },
  ];

  const dataFieldOptions = (dataField) => {
    if (dataField) {
      if (dataField.field_type == "input") {
        return inputFieldTyes;
      }
      if (dataField.field_type == "select") {
        return selectFieldTypes;
      }
      if (dataField.field_type == "maintainer") {
        return maintainers;
      }
    }
    return [];
  };

  const handleDataFieldInputChange = useCallback((event, index, indexField) => {
    const {
      target: { value, name },
    } = event;
    const { data_groups_attributes } = form;
    data_groups_attributes[index].data_fields_attributes[indexField][
      name
    ] = value;
    setForm({ ...form, data_groups_attributes });
  });

  const handleDataFieldSelectChange = (event, index, indexField, name) => {
    const { value } = event;
    const { data_groups_attributes } = form;
    data_groups_attributes[index].data_fields_attributes[indexField][
      name
    ] = value;
    setForm({ ...form, data_groups_attributes });
  };

  const handleDataFieldCheckboxChange = (event, index, indexField) => {
    const {
      target: { name, checked },
    } = event;
    const { data_groups_attributes } = form;
    data_groups_attributes[index].data_fields_attributes[indexField][
      name
    ] = checked;
    setForm({ ...form, data_groups_attributes });
  };

  const handleOnClickRemoveField = useCallback((index, indexField) => {
    const { data_groups_attributes } = form;
    data_groups_attributes[index].data_fields_attributes[
      indexField
    ].enabled = false;
    setForm({ ...form, data_groups_attributes });
  });

  const handleOnClickAddField = useCallback((index) => {
    const { data_groups_attributes } = form;
    data_groups_attributes[index].data_fields_attributes.push(
      newDataField(data_groups_attributes[index].data_fields_attributes.length)
    );
    setForm({ ...form, data_groups_attributes });
  });

  return (
    <div className="card-body">
      {dataFields &&
        dataFields.map((dataField, index) => {
          if (!dataField.enabled) {
            return "";
          }
          const { name, field_type, data_type, required } = dataField;
          return (
            <div className="row" key={`${dataGroupIndex}-${index}`}>
              <div className="row col-11">
                <div className="row col-11">
                  <div className="col-4 pr-1">
                    <input
                      className="form-control"
                      type="text"
                      value={name}
                      name="name"
                      required={true}
                      id={`field_name_${index}`}
                      placeholder={"Nombre campo"}
                      pattern="[A-zÁ-ú0-9\s]*"
                      onChange={(e) =>
                        handleDataFieldInputChange(e, dataGroupIndex, index)
                      }
                    />
                  </div>
                  <div className="col-4 px-1">
                    <Select
                      value={
                        field_type &&
                        fieldTypes.find((d) => d.value == field_type)
                      }
                      options={fieldTypes}
                      placeholder={"Tipo campo"}
                      name="field_type"
                      required={true}
                      onChange={(e) =>
                        handleDataFieldSelectChange(
                          e,
                          dataGroupIndex,
                          index,
                          "field_type"
                        )
                      }
                    />
                  </div>
                  <div className="col-4 pl-1">
                    <Select
                      value={
                        data_type &&
                        dataFieldOptions(dataField).find(
                          (d) => d.value == data_type
                        )
                      }
                      options={dataFieldOptions(dataField)}
                      placeholder={"Tipo de dato"}
                      name="data_type"
                      required={true}
                      onChange={(e) =>
                        handleDataFieldSelectChange(
                          e,
                          dataGroupIndex,
                          index,
                          "data_type"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-1">
                  <RequiredLabelStyled>
                    <RequiredLabel />
                  </RequiredLabelStyled>
                  <Checkbox
                    checked={required}
                    name="required"
                    id={`field_required_${dataGroupIndex}_${index}`}
                    onChange={(e) => {
                      handleDataFieldCheckboxChange(e, dataGroupIndex, index);
                    }}
                  />
                </div>
              </div>
              <div className="col-1">
                <div
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    handleOnClickRemoveField(dataGroupIndex, index)
                  }
                >
                  <i className="mdi mdi-close"></i>
                </div>
              </div>
            </div>
          );
        })}
      <div className="col-12 mt-3">
        <div
          className="btn btn-sm btn-success"
          onClick={() => handleOnClickAddField(dataGroupIndex)}
        >
          <i className="mdi mdi-plus"></i>
          Agregar campo
        </div>
      </div>
    </div>
  );
};

export default DataFieldForm;
