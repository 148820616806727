/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CommentsIndex from "../../comments/Index";

const CaseStageEntry = ({
  editCaseStage,
  deleteCaseStage,
  caseStage,
  csrf,
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <div>
          <div className="d-flex flex-row justify-content-between">
            {caseStage.procedure_stage?.name}
            <div className="dropdown">
              <i
                data-toggle="dropdown"
                className="mdi mdi-dots-vertical float-right"
                style={{ fontSize: "20px" }}
              />
              <div className="dropdown-menu dropdown-menu-right">
                <button
                  className="dropdown-item"
                  onClick={() => editCaseStage(caseStage)}
                >
                  Editar
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => deleteCaseStage(caseStage)}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="d-flex">
          <i className="mdi mdi-account-circle md-48 mr-3 text-secondary"></i>
          <div className="d-flex flex-column justify-content-center">
            <br />
            Documentos:
            {caseStage.documents_with_url.map((document) => (
              <a href={document.url} target="_blank" key={document.id}>
                {document.name}
              </a>
            ))}
            <div>
              <i className="mdi mdi-calendar md-18 mr-2 text-success"></i>
              <b className="mr-2">
                Fecha del cambio de etapa *
                {moment(caseStage.date).format("DD/MM/YYYY")}*
              </b>
              <b className="mr-2">
                (Creado el {moment(caseStage.created_at).format("DD/MM/YYYY")})
              </b>
            </div>
          </div>
        </div>
        <CommentsIndex owner={caseStage} ownerType="CaseStage" csrf={csrf} />
      </div>
    </div>
  );
};

CaseStageEntry.propTypes = {
  editCaseStage: PropTypes.func.isRequired,
  deleteCaseStage: PropTypes.func.isRequired,
  caseStage: PropTypes.object.isRequired,
  csrf: PropTypes.string.isRequired,
};

CaseStageEntry.defaultProps = {};

export default CaseStageEntry;
