import axios from "axios";
import { REQUESTS_URL } from "./constants";

export const createRequest = (csrf, request) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post(`${REQUESTS_URL}.json`, {
    request,
    withCredentials: true,
  });
};

export const updateRequest = (id, csrf, request) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`${REQUESTS_URL}/${id}.json`, {
    request,
    withCredentials: true,
  });
};
