export const IDENTIFIERS = {
  rol_id: "Rol",
  ruc_id: "Ruc",
  ric_id: "Ric",
  aux_id: "Auxiliar",
};

export const CASE_DATA = [
  "{{CASO.materia}}",
  "{{CASO.tipo de juicio}}",
  "{{CASO.subtipo de juicio}}",
  "{{CASO.fecha de los hechos}}",
  "{{CASO.fecha de término}}",
];

export const DOCTOR_DATA = [
  "{{DOCTOR.id}}",
  "{{DOCTOR.nombre}}",
  "{{DOCTOR.IRJ}}",
  "{{DOCTOR.ICM}}",
  "{{DOCTOR.RUT}}",
];
