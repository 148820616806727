/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const RequiredLabelStyled = styled.label`
  white-space: "nowrap";
`;

const RequiredTextStyled = styled.span`
  color: red;
`;

const RequiredLabel = (props) => (
  <RequiredLabelStyled className="form-label" htmlFor="props.htmlFor">
    {props.label}
    <RequiredTextStyled>(*)</RequiredTextStyled>
  </RequiredLabelStyled>
);

RequiredLabel.propTypes = {
  label: PropTypes.string,
};

export default RequiredLabel;
