/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useCallback, useState } from "react";
import Table from "../../../../components/Table";
import { downloadTemplateManagement } from "../../../../services/cases";
import Loader from "../../../../components/Loader";

const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const { templates_managements, case_id, csrf, case_doctors } = props;

  const columns = [
    { label: "Nombre", key: "name", value: (d) => d.name },
    { label: "Id", key: "identifier_id", value: (d) => d.identifier_id },
    { label: "Médico", key: "doctor", value: (d) => d.doctor_name },
  ];

  const handleDownload = useCallback((d) => {
    const { id, case_doctor_id, identifier_id, icm, name } = d;
    setLoading(true);
    downloadTemplateManagement(case_id, case_doctor_id, id, csrf)
      .then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${identifier_id} - ${icm} - ${name}.docx`
          );
          document.body.appendChild(link);
          link.click();
        },
        (error) => alert("No estás autorizado para descargar el documento")
      )
      .finally(() => setLoading(false));
  });

  const customActions = (d) =>
    d.enabled && (
      <a
        className="btn btn-sm btn-primary mr-2"
        onClick={() => handleDownload(d)}
        data-toggle="tooltip"
        data-placement="top"
        data-title="Descargar documento"
      >
        <i className="mdi mdi-file"></i>
      </a>
    );
  const rows = case_doctors.reduce(
    (arr, case_doctor) => [
      ...arr,
      ...templates_managements.map((template) => ({
        ...template,
        case_doctor_id: case_doctor.id,
        identifier_id: case_doctor.identifier_id,
        doctor_name: case_doctor.doctor?.preview_name,
        icm: case_doctor.doctor?.icm,
      })),
    ],
    []
  );

  return (
    <div className="row" style={{ ...(loading ? { opacity: 0.2 } : {}) }}>
      {loading && (
        <Loader
          style={{
            position: "absolute",
            top: "65%",
            left: "50%",
            zIndex: 1000,
          }}
        />
      )}
      <Table
        sortColumns={false}
        columns={columns}
        entityClass={"templates_managements"}
        rows={rows}
        pagination={{
          pages: 1,
          page: 1,
        }}
        actions={[]}
        customActions={customActions}
      />
    </div>
  );
};

export default Index;
