/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const FormSelect = ({
  onChangeNone,
  value,
  error,
  label,
  placeholder,
  required,
  disabled,
  ref,
  onInputChange,
  ...rest
}) => (
  <Fragment>
    {label ? <label className="form-label">{label}</label> : ""}
    <Select
      ref={ref}
      value={value}
      isDisabled={disabled}
      {...rest}
      placeholder={placeholder}
      onInputChange={onInputChange}
    />
    {required && (
      <input        
        tabIndex={-1}
        autoComplete="off"
        style={{ opacity: 0, height: 0, width: "100%", position: "absolute" }}
        value={value || ""}
        required={required}
        onChange={onChangeNone}
      />
    )}
  </Fragment>
);

FormSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChangeNone: PropTypes.func,
  onInputChange: PropTypes.func,
};

FormSelect.defaultProps = {
  label: "",
  value: "",
  error: "",
  type: "text",
  disabled: false,
  required: false,
  ref: null,
  placeholder: "Seleccione",
  onChange: () => {},
  onChangeNone: () => {},
  onInputChange: () => {},
};

export default FormSelect;
