/* eslint-disable import/prefer-default-export */
export const setDocumentIcon = (name) => {
  const extension = name.split(".").pop();
  switch (extension) {
    case "pdf":
      return "mdi-file-pdf";
    case "ppt":
    case "pptx":
      return "mdi-file-powerpoint";
    case "xlsx":
    case "xlsm":
    case "xls":
    case "xlsb":
    case "csv":
      return "mdi-file-excel";
    case "docx":
    case "docm":
      return "mdi-file-word";
    default:
      return "mdi-file";
  }
};
