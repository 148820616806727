/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";
import Uppy from "@uppy/core";
import { DragDrop } from "@uppy/react";
import {
  createCaseStage,
  updateCaseStage,
} from "../../../../services/case_stage";
import Loader from "../../../../components/Loader";
import DateSelect from "../../../../components/Forms/DateSelect";
import Select from "../../../../components/Forms/Select";
import { momentUtcFormat } from "../../../../components/Dates";

const CaseStagesForm = (props) => {
  const {
    caseDoctorProps,
    caseStageProps,
    handleSaveCaseStage,
    stagesOptions,
    previousStage,
    csrf,
    index,
  } = props;
  const [form, setForm] = useState({
    case_id: caseDoctorProps.case_id,
    case_doctor_id: caseDoctorProps.id,
    id: caseStageProps.id || "",
    title: caseStageProps.title || "",
    date: caseStageProps.date || new Date(),
    documents: caseStageProps.documents_with_url || [],
    procedure_stage_id: caseStageProps.procedure_stage_id || "",
  });
  const [loading, setLoading] = useState(false);

  const handleDateChange = useCallback((date) => {
    setForm({ ...form, date });
  });

  const uppy = new Uppy({
    meta: { type: "document" },
    restrictions: { maxNumberOfFiles: 1 },
    autoProceed: false,
  });

  uppy.on("file-added", (file) => {
    setForm({ ...form, documents: [...form.documents, file.data] });
  });

  const handleSelectChange = useCallback((event, obj) => {
    const { name } = obj;
    const { value } = event;
    setForm({ ...form, [name]: value });
  });

  const handleDeleteDocument = useCallback((documentName) => {
    const { documents } = form;
    const documentIndex = documents.findIndex(
      (document) => document.name === documentName
    );
    documents.splice(documentIndex, 1);
    setForm({ ...form, documents });
  });

  const createOrUpdate = useCallback(() => {
    const { case_id, case_doctor_id, id } = form;
    const formData = new FormData();
    formData.append("case_stage[date]", form.date);
    formData.append("case_stage[case_doctor_id]", form.case_doctor_id);
    formData.append("case_stage[procedure_stage_id]", form.procedure_stage_id);
    form.documents.forEach((document) => {
      formData.append(
        "case_stage[documents][]",
        document.signed_id ? document.signed_id : document
      );
    });
    return id
      ? updateCaseStage(case_id, case_doctor_id, id, formData, csrf)
      : createCaseStage(case_id, case_doctor_id, formData, csrf);
  }, [form]);

  const submitSuccess = useCallback((response) => {
    const { id } = form;
    const message = `Cambio de estado ${
      id ? "actualizado" : "creado"
    } con éxito`;
    // eslint-disable-next-line no-alert
    alert(message);
    handleSaveCaseStage(response.data, index);
    setLoading(false);
  });

  const handleSubmit = useCallback((event) => {
    setLoading(true);
    event.preventDefault();
    createOrUpdate().then(
      (response) => submitSuccess(response),
      // eslint-disable-next-line no-console
      (err) => {
        console.log(err);
        setLoading(false);
        alert("No estás autorizado para modificar la etapa");
      }
    );
  });

  const stageCardTitle = useCallback(() => {
    const { procedure_stage_id } = caseStageProps;
    const { id } = form;
    const stageName =
      stagesOptions.find((o) => o.value === procedure_stage_id)?.label || "";
    return `${id ? "Modificando" : "Cambiando a etapa"} ${stageName}`;
  }, [caseStageProps, form, stagesOptions]);

  const { date, procedure_stage_id } = form;

  return (
    <div className="card">
      <div className="card-header">{stageCardTitle()}</div>
      <form
        className="card-body"
        onSubmit={handleSubmit}
        style={{ ...(loading ? { opacity: 0.5 } : {}) }}
      >
        {loading && (
          <Loader
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 1000,
            }}
          />
        )}
        <div className="d-flex">
          <i className="mdi mdi-account-circle md-48 mr-4 text-secondary"></i>
          <div className="row">
            <div className="col-sm-12 col-md-6 form-group">
              <DateSelect
                wrapperClassName="input-group"
                className="form-control"
                ariaLabelledBy=""
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                name="date"
                selected={date ? momentUtcFormat(date) : null}
                onChange={handleDateChange}
              />
            </div>
            <div className="col-12 form-group">
              <DragDrop
                uppy={uppy}
                value={form.documents}
                locale={{
                  strings: {
                    dropHereOr: "Arrastrar para adjuntar o %{browse}",
                    browse: "navegar sistema",
                  },
                }}
              />
            </div>
            {form.documents.length > 0 && (
              <div className="col-12">
                Archivos por subir:
                <div>
                  {form.documents.map((document, i) => (
                    <span key={i}>
                      <i
                        className="mdi mdi-delete text-danger p-5"
                        onClick={() => handleDeleteDocument(document.name)}
                      />
                      {i + 1}-. {document.name}
                      <br />
                    </span>
                  ))}
                </div>
              </div>
            )}
            <div className="col-12 form-group">
              <Select
                value={stagesOptions.find(
                  (s) => s.value === procedure_stage_id
                )}
                options={stagesOptions}
                name="procedure_stage_id"
                onChange={handleSelectChange}
                required
                isOptionDisabled={(option) =>
                  option.value === previousStage?.procedure_stage_id
                }
              />
            </div>
            <div className="col-12">
              <div className="action float-right">
                <button className="btn btn-sm btn-success" type="submit">
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CaseStagesForm;
