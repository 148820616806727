/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from "react";
import Table from "../../../../components/Table";
import Errors from "../../../../components/Layouts/Errors";

const Index = (props) => {
  const { errors, procedures, pagination } = props;

  const columns = [
    { label: "Nombre", key: "name", value: (d) => d.name },
    { label: "Descripción", key: "description", value: (d) => d.description },
  ];

  return (
    <div className="row">
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <Table
        columns={columns}
        entityClass={"procedures"}
        rows={procedures}
        pagination={pagination}
      />
    </div>
  );
};

export default Index;
