/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from "react";
import CasesForm from "./CasesForm";
import Select from "../../../../components/Forms/Select";
import Checkbox from "../../../../components/Forms/Checkbox";
import DateSelect from "../../../../components/Forms/DateSelect";
import { momentUtcFormat } from "../../../../components/Dates";

const AnswerForm = (props) => {
  const {
    causeStatuses,
    form,
    setForm,
    handleSubmitAndCreateCase,
    csrf,
  } = props;

  const handleDateChange = useCallback((answer_date) => {
    setForm({ ...form, answer_date });
  });

  const handleSelectChange = useCallback((e, t) => {
    const { value } = e;
    const { name } = t;
    form[name] = value;
    setForm({ ...form });
  });

  const handleOnChangeSelect = useCallback((event, target, index) => {
    const { value } = event;
    const { name } = target;
    const { request_cases_attributes } = form;
    request_cases_attributes[index][name] = value;
    setForm({ ...form, request_cases_attributes });
  });

  const handleOnRemoveSelect = useCallback((index) => {
    const { request_cases_attributes } = form;
    const modifiedEntity = request_cases_attributes.splice(index, 1);
    if (modifiedEntity[0].id) {
      modifiedEntity[0].enabled = false;
      request_cases_attributes.splice(index, 0, modifiedEntity[0]);
    }
    setForm({ ...form, request_cases_attributes });
  });

  const handleAddSelect = useCallback(() => {
    const { request_cases_attributes } = form;
    request_cases_attributes.push({ enabled: true });
    setForm({ ...form, request_cases_attributes });
  });

  const {
    cause_status_id,
    procedure_id,
    request_cases_attributes,
    answer_date,
  } = form;

  const selectedCauseStatus =
    cause_status_id && causeStatuses.find((d) => d.value == cause_status_id);

  return (
    <div>
      <div className="row">
        <div className="col-4 col-xs-12">
          <div className="card-body">
            <h6 className="font-weight-bold mb-3">Estado</h6>
            <div className="form-group">
              <Select
                options={causeStatuses}
                value={selectedCauseStatus}
                onChange={handleSelectChange}
                placeholder="Seleccione un estado"
                name="cause_status_id"
              />
            </div>
          </div>
        </div>

        <div className="col-4 col-xs-12">
          <div className="card-body">
            <div className="form-group">
              <h6 className="font-weight-bold mb-3">Fecha de término</h6>
              <div className="form-group">
                <DateSelect
                  wrapperClassName="input-group"
                  className="form-control"
                  ariaLabelledBy=""
                  dateFormat="dd/MM/yyyy"
                  name="answer_date"
                  selected={
                    selectedCauseStatus &&
                    selectedCauseStatus.name == "Terminado" &&
                    momentUtcFormat(answer_date)
                  }
                  onChange={handleDateChange}
                  disabled={
                    !selectedCauseStatus ||
                    selectedCauseStatus.name != "Terminado"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CasesForm
        stepNumber={""}
        procedure_id={procedure_id}
        selectedCases={request_cases_attributes}
        handleAddSelect={handleAddSelect}
        handleOnRemoveSelect={handleOnRemoveSelect}
        handleOnChangeSelect={handleOnChangeSelect}
        handleSubmitAndCreateCase={handleSubmitAndCreateCase}
        csrf={csrf}
      />
    </div>
  );
};

export default AnswerForm;
