/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import { RUC_VERIFIER_REGEX } from "./constants";

const IdentifiersForm = (props) => {
  const { procedureIdentifier, form, setForm } = props;
  const identifiers = [
    { name: "ROL", field_key: "rol_id" },
    { name: "RUC", field_key: "ruc_id" },
    { name: "RIT", field_key: "rit_id" },
    { name: "Auxiliar", field_key: "aux_id" },
  ];

  const onChangeInputs = useCallback((event, position) => {
    const { target } = event;
    const { name } = target;
    let { value } = target;
    if (name === "ruc_id" && position == 1) {
      value = value.replace(RUC_VERIFIER_REGEX, "");
    }
    const currentValue = form.data[name] || "-";
    if (position == 0) {
      form.data[name] = `${value}-${currentValue.split("-")[1]}`;
    } else if (position == 1) {
      form.data[name] = `${currentValue.split("-")[0]}-${value}`;
    }
    setForm({ ...form });
  });

  const onChangeInput = useCallback((event) => {
    const {
      target: { value, name },
    } = event;
    form.data[name] = value;
    setForm({ ...form });
  });

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const renderRol = (field_key) => {
    const currentValue = form.data[field_key] || "-";
    return (
      <div className="row col-sm-12 col-md-8">
        <input
          type="number"
          min="0"
          className="col-6 form-control"
          onKeyDown={blockInvalidChar}
          onChange={(e) => onChangeInputs(e, 0)}
          id={`identifier-${field_key}`}
          name={field_key}
          defaultValue={currentValue.split("-")[0]}
        />
        <div className="col-1 text-center">-</div>
        <input
          type="number"
          min="0"
          className="col-5 form-control"
          onKeyDown={blockInvalidChar}
          onChange={(e) => onChangeInputs(e, 1)}
          id={`identifier-${field_key}`}
          name={field_key}
          defaultValue={currentValue.split("-")[1]}
        />
      </div>
    );
  };

  const renderRuc = (field_key) => {
    const currentValue = form.data[field_key] || "-";
    return (
      <div className="row col-sm-12 col-md-8">
        <input
          type="number"
          min="0"
          className="col-6 form-control"
          onKeyDown={blockInvalidChar}
          onChange={(e) => onChangeInputs(e, 0)}
          id={`identifier-${field_key}`}
          name={field_key}
          defaultValue={currentValue.split("-")[0]}
        />
        <div className="col-1 text-center">-</div>
        <input
          type="text"
          min="0"
          className="col-5 form-control"
          onKeyDown={blockInvalidChar}
          onChange={(e) => onChangeInputs(e, 1)}
          id={`identifier-${field_key}`}
          name={field_key}
          value={currentValue.split("-")[1]}
        />
      </div>
    );
  };

  const renderRit = (field_key) => {
    const currentValue = form.data[field_key] || "-";
    return (
      <div className="row col-sm-12 col-md-8">
        <input
          type="number"
          min="0"
          className="col-6 form-control"
          onKeyDown={blockInvalidChar}
          onChange={(e) => onChangeInputs(e, 0)}
          id={`identifier-${field_key}`}
          name={field_key}
          defaultValue={currentValue.split("-")[0]}
        />
        <div className="col-1 text-center">-</div>
        <input
          type="number"
          min="0"
          className="col-5 form-control"
          onKeyDown={blockInvalidChar}
          onChange={(e) => onChangeInputs(e, 1)}
          id={`identifier-${field_key}`}
          name={field_key}
          label={"Año"}
          defaultValue={currentValue.split("-")[1]}
        />
      </div>
    );
  };

  const renderAux = (field_key) => (
    <div className="row col-sm-12 col-md-8">
      <input
        className="col-12 form-control"
        onChange={onChangeInput}
        id={`identifier-${field_key}`}
        name={field_key}
        defaultValue={form.data[field_key]}
      />
    </div>
  );

  return (
    <div className="card">
      <div className="card-header">Identificadores</div>
      <div className="card-body mt-4 mb-5">
        {identifiers.map((identifier, index) => {
          const { name, field_key } = identifier;

          return (
            procedureIdentifier &&
            procedureIdentifier[field_key] && (
              <div className="row form-group" key={index}>
                <div className="col-sm-12 col-md-4 form-label">{name}</div>
                {field_key == "rol_id" && renderRol(field_key)}
                {field_key == "ruc_id" && renderRuc(field_key)}
                {field_key == "rit_id" && renderRit(field_key)}
                {field_key == "aux_id" && renderAux(field_key)}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default IdentifiersForm;
