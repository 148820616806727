/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState, useCallback } from "react";
import DoctorNew from "./DoctorNew";
import DoctorInformation from "../DoctorInformation";
import Checkbox from "../../../../components/Forms/Checkbox";
import RequiredLabel from "../../../../components/Forms/RequiredLabel";
import DoctorsSelect from "../../doctors/Select";

const DoctorSelector = ({ form, setForm, csrf, doctorProps }) => {
  const { doctor_id } = form;
  const [doctor, setDoctor] = useState(doctorProps);
  const [withoutICM, setWithoutICM] = useState(false);

  const handleSelectOnChange = useCallback((event, obj) => {
    const { value, doctor: doctorInformation } = event;
    const { name } = obj;
    setForm({ ...form, [name]: value });
    if (value) {
      setWithoutICM(false);
      setDoctor(doctorInformation);
    }
  });

  const onChangeCheckbox = useCallback((event) => {
    const {
      target: { checked },
    } = event;
    setWithoutICM(checked);
    if (checked) {
      setForm({ ...form, doctor_id: null, doctor_attributes: {} });
      setDoctor(null);
    } else {
      setForm({ ...form, doctor_attributes: null });
    }
  });

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <h6 className="font-weight-bold mb-3">
            <div className="badge badge-pill badge-info font-16 mr-2">2</div>
            Seleccione Médico
            <RequiredLabel />
          </h6>
          <DoctorsSelect
            doctor_id={doctor_id}
            handleOnChangeSelect={handleSelectOnChange}
            csrf={csrf}
            name="doctor_id"
          />
          {doctor && !withoutICM && (
            <div>
              <p className="text-center font-weight-light font-italic">
                Resultados de búsqueda
              </p>
              <DoctorInformation doctor={doctor} />
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="row mt-3">
        <div className="col-12">
          <div className="form-group">
            <Checkbox
              id={"withoutICM"}
              checked={withoutICM}
              label="Sin ICM"
              onChange={onChangeCheckbox}
            />
          </div>
          {withoutICM && (
            <div className="form-group">
              <DoctorNew form={form} setForm={setForm} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DoctorSelector;
