/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from "react";
import Table from "../../../../components/Table";
import Errors from "../../../../components/Layouts/Errors";

const CasesIndex = (props) => {
  const {
    errors,
    cases,
    pagination,
    caseDoctorStatuses,
    caseDoctorAccepted,
  } = props;

  const columns = [
    {
      label: "ID",
      key: "identifier_id",
      value: (d) => d.identifier_id,
    },
    {
      label: "Materia",
      key: "procedures.name",
      value: (d) => d.procedure?.name,
    },
    {
      label: "Tipo de juicio",
      key: "judgment_types.name",
      value: (d) => d.judgment_type?.name,
    },
    {
      label: "Subtipo de juicio",
      key: "judgment_subtypes.name",
      value: (d) => d.judgment_subtype?.name,
    },
    {
      label: "Nombre Paciente",
      key: "data",
      value: (d) => {        
        if (d.data === null) return "";

        if ('20' in d.data) {
          const name = d.data[20][106] != undefined ? d.data[20][106] : '';
          const apaterno = d.data[20][107] != undefined ? d.data[20][107] : '';
          const amaterno = d.data[20][108] != undefined ? d.data[20][108] : '';
          return `${name} ${apaterno} ${amaterno}`;
        }
        if ('21' in d.data) {
          const name = d.data[21][115] != undefined ? d.data[21][115] : '';
          const apaterno = d.data[21][116] != undefined ? d.data[21][116] : '';
          const amaterno = d.data[21][117] != undefined ? d.data[21][117] : '';
          return `${name} ${apaterno} ${amaterno}`;
        }
        if ('23' in d.data) {
          const name = d.data[23][139] != undefined ? d.data[23][139] : '';
          const apaterno = d.data[23][140] != undefined ? d.data[23][140] : '';
          const amaterno = d.data[23][141] != undefined ? d.data[23][141] : '';
          return `${name} ${apaterno} ${amaterno}`;
        }
        if ('26' in d.data) {
          const name = d.data[26][160] != undefined ? d.data[26][160] : '';
          const apaterno = d.data[26][161] != undefined ? d.data[26][161] : '';
          const amaterno = d.data[26][162] != undefined ? d.data[26][162] : '';
          return `${name} ${apaterno} ${amaterno}`;
        }
        if ('29' in d.data) {
          const name = d.data[29][179] != undefined ? d.data[29][179] : '';
          const apaterno = d.data[29][180] != undefined ? d.data[29][180] : '';
          const amaterno = d.data[29][181] != undefined ? d.data[29][181] : '';
          return `${name} ${apaterno} ${amaterno}`;
        }
        if ('31' in d.data) {
          const name = d.data[31][198] != undefined ? d.data[31][198] : '';
          const apaterno = d.data[31][199] != undefined ? d.data[31][199] : '';
          const amaterno = d.data[31][200] != undefined ? d.data[31][200] : '';
          return `${name} ${apaterno} ${amaterno}`;
        }
        if ('35' in d.data) {
          const name = d.data[35][219] != undefined ? d.data[35][219] : '';
          const apaterno = d.data[35][220] != undefined ? d.data[35][220] : '';
          const amaterno = d.data[35][221] != undefined ? d.data[35][221] : '';
          return `${name} ${apaterno} ${amaterno}`;
        }
        if ('44' in d.data) {
          const name = d.data[44][248] != undefined ? d.data[44][248] : '';
          const apaterno = d.data[44][249] != undefined ? d.data[44][249] : '';
          const amaterno = d.data[44][250] != undefined ? d.data[44][250] : '';
          return `${name} ${apaterno} ${amaterno}`;
        }
      }
    },
    {
      label: "Estado",
      key: "extraordinary_state",
      value: (d) => d.cause_status
        /*caseDoctorStatuses.find(
          (s) => s.value == (d.extraordinary_state || caseDoctorAccepted)
        ).label,*/
    },
    {
      label: "Fecha de Ingreso",
      key: "created_at",
      value: (d) => moment(d.created_at).format("DD/MM/YYYY"),
    },
    {
      label: "Fecha de Término",
      key: "end_date",
      value: (d) => (d.end_date ? moment(d.end_date).format("DD/MM/YYYY") : ""),
    },
    {
      label: "Asegurabilidad",
      key: "insurable",
      value: (d) => {
        if (d.insurable === null) return "No Siniestro";
        return d.insurable ? "Siniestro" : "No Siniestro";
      },
    },
    {
      label: "Etapa",
      key: "current_stage",
      value: (d) => d?.case_stage?.procedure_stage?.name,
    },
    {
      label: "RUC",
      key: "ruc_id",
      value: (d) => d.data.ruc_id,
    },
    {
      label: "ROL",
      key: "rol_id",
      value: (d) => d.data.rol_id,
    },
    {
      label: "RIT",
      key: "rit_id",
      value: (d) => d.data.rit_id,
    },
    {
      label: "Auxiliar",
      key: "aux_id",
      value: (d) => d.data.aux_id,
    },
    {
      label: "Nombre abogado",
      key: "lawyer_name",
      value: (d) => (
        <div style={{ display: "grid" }}>
          {d.users?.map((u, i) => (
            <span key={i}>
              {u.last_name}, {u.first_name}.
            </span>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <Table
        columns={columns}
        entityClass={"cases"}
        rows={cases}
        pagination={pagination}
        actions={["show", "edit", "delete"]}
      />
    </div>
  );
};

export default CasesIndex;
