/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#204772" : "grey",
    backgroundColor: "white",
    fontWeight: state.isSelected ? "bold" : "normal",
    "&:active": {
      backgroundColor: "grey",
    },
  })
};

const MultiValue = (props) => {
  const {
    selectProps: { title, value, options },
  } = props;
  const isAllSelected = value.length > 0 && value[0] && value[0].value === "*"; 
  const label = `${title} (${
    isAllSelected ? options.length - 1 : value.length
  })`;

  return (
    <components.SingleValue {...props}>
      <span>{label}</span>
    </components.SingleValue>
  )
};

const MultiSelect = ({
  options,
  value,
  title,
  onChange,
  allowSelectAll,
  disabled,
  required,
  ...rest
}) => {
  const allOption = {
    value: "*",
    label: "Seleccionar todo",
  };

  const handleChange = (event, fields) => {
    if (allowSelectAll) {
      if (value?.length === options?.length) {
        onChange(event.slice(1), fields);

        return;
      }
      if (event.length && event[event.length - 1].value === allOption.value) {
        onChange(options, fields);

        return;
      }
    }
    onChange(event, fields);
  };
  return (
    <Fragment>
      <Select
        components={{ MultiValue }}
        value={
          allowSelectAll && value?.length === options?.length
            ? [allOption]
            : value
        }
        options={allowSelectAll ? [allOption, ...options] : options}
        styles={{ ...customStyles }}
        isSearchable
        isClearable
        isMulti
        title={title}
        hideSelectedOptions={false}
        onChange={handleChange}
        isDisabled={disabled}
        {...rest}
      />
      {required && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0, width: "100%", position: "absolute" }}
          value={value || ""}
          required={required}
          onChange={() => {}}
        />
      )}
    </Fragment>
  );
};

MultiValue.propTypes = {
  selectProps: PropTypes.instanceOf(Object),
};

MultiValue.defaultProps = {
  selectProps: {},
};

MultiSelect.defaultProps = {
  options: [],
  onChange: () => {},
  value: [],
  title: "",
  allowSelectAll: true,
  disabled: false,
  required: false,
};

MultiSelect.propTypes = {
  options: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Array),
  title: PropTypes.string,
  allowSelectAll: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default MultiSelect;
