/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createRequest, updateRequest } from "../../../../services/requests";
import DoctorForm from "./DoctorForm";
import AnswerForm from "./AnswerForm";
import EntryTypeForm from "./EntryTypeForm";
import LawyersForm from "../../lawyers/Form/index";
import RequestInfo from "./RequestInfo";
import Errors from "../../../../components/Layouts/Errors";

const RequestForm = ({
  request,
  entryTypes,
  doctorProps,
  procedures,
  judgmentTypes,
  judgmentSubtypes,
  lawyers,
  selectedLawyers,
  causeStatuses,
  selectedCases,
  csrf,
}) => {
  const [form, setForm] = useState({
    id: request.id,
    doctor_id: request.doctor_id,
    procedure_id: request.procedure_id,
    judgment_type_id: request.judgment_type_id,
    judgment_subtype_id: request.judgment_subtype_id,
    entry_type_id: request.entry_type_id,
    title: request.title,
    detail: request.detail,
    date: request.date || new Date(),
    cause_status_id: causeStatuses.find(
      (cs) => cs.label == request.cause_status?.name
    )?.value,
    answer_date: request.answer_date,
    request_lawyers_attributes: selectedLawyers,
    request_cases_attributes: selectedCases,
  });
  const [errors, setErrors] = useState(request.errors || []);

  const handleDateChange = useCallback((date) => {
    setForm({ ...form, date });
  });

  const handleInputChange = useCallback((event) => {
    const {
      target: { value, name },
    } = event;
    setForm({ ...form, [name]: value });
  });

  const createOrUpdate = useCallback(() => {
    const { id } = request;
    return id ? updateRequest(id, csrf, form) : createRequest(csrf, form);
  });

  const handleOnChangeSelect = useCallback((event, target, index) => {
    const { value } = event;
    const { name } = target;
    const { request_lawyers_attributes } = form;
    request_lawyers_attributes[index][name] = value;
    setForm({ ...form, request_lawyers_attributes });
  });

  const handleOnRemoveSelect = useCallback((index) => {
    const { request_lawyers_attributes } = form;
    if (request_lawyers_attributes[index].id) {
      request_lawyers_attributes[index].enabled = false;
    } else {
      request_lawyers_attributes.splice(index, 1);
    }
    setForm({ ...form, request_lawyers_attributes });
  });

  const handleAddSelect = useCallback(() => {
    const { request_lawyers_attributes } = form;
    request_lawyers_attributes.push({ enabled: true });
    setForm({ ...form, request_lawyers_attributes });
  });

  const submitSuccess = useCallback(() => {
    const { id } = request;
    const message = `Consulta ${id ? "actualizada" : "creada"} con éxito`;
    // eslint-disable-next-line no-alert
    alert(message);
    window.location.href = "/requests";
  });

  const handleSubmit = useCallback(() => {
    createOrUpdate()
      .then(() => submitSuccess())
      .catch((error) => {
        setErrors(error.response.data);
      });
  });

  const handleGoBack = () => {
    window.location.href = "/requests";
  };

  const submitAndCreateCaseSuccess = useCallback((response) => {
    const {
      data: { id },
    } = response;
    const message = `Consulta ${
      id ? "actualizada" : "creada"
    } con éxito. Acepte para crear el caso asociado`;
    // eslint-disable-next-line no-alert
    alert(message);
    window.location.href = `/cases/new?request_id=${id}`;
  });

  const handleSubmitAndCreateCase = useCallback(() => {
    createOrUpdate()
      .then((response) => submitAndCreateCaseSuccess(response))
      .catch((error) => {
        setErrors(error.response.data);
      });
  });

  const { entry_type_id, procedure_id, request_lawyers_attributes } = form;

  return (
    <div className="row">
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <span className="h4 font-weight-bold">Ingreso de consulta</span>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-3 col-xs-12">
                <div className="card-body">
                  <EntryTypeForm
                    entryTypes={entryTypes}
                    entryType={entry_type_id}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-5 col-xs-12">
                <div className="card-body">
                  <DoctorForm
                    doctorProps={doctorProps}
                    form={form}
                    setForm={setForm}
                    csrf={csrf}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="card-body">
                  <RequestInfo
                    procedures={procedures}
                    judgmentTypes={judgmentTypes}
                    judgmentSubtypes={judgmentSubtypes}
                    handleDateChange={handleDateChange}
                    handleInputChange={handleInputChange}
                    form={form}
                    setForm={setForm}
                  />
                </div>
              </div>
            </div>

            <hr />

            <AnswerForm
              form={form}
              setForm={setForm}
              causeStatuses={causeStatuses}
              csrf={csrf}
            />

            <LawyersForm
              stepNumber={""}
              lawyers={lawyers.filter((d) =>
                d.procedures.map((e) => e.id).includes(procedure_id)
              )}
              selectedLawyers={request_lawyers_attributes}
              handleAddSelect={handleAddSelect}
              handleOnRemoveSelect={handleOnRemoveSelect}
              handleOnChangeSelect={handleOnChangeSelect}
              formName="request"
            />
          </div>
        </div>

        <div className="actions">
          <button
            type="submit"
            className="btn btn-success float-right"
            onClick={handleSubmit}
          >
            Guardar
          </button>
          <button
            type="button"
            className="btn btn-danger float-right mr-2"
            onClick={handleGoBack}
          >
            No guardar y volver
          </button>
          {request.id && (
            <button
              type="submit"
              className="btn btn-primary float-left"
              onClick={handleSubmitAndCreateCase}
            >
              Guardar y crear nuevo caso
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestForm;
