/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import Select from "../../../../components/Forms/Select";
import { getCases } from "../../../../services/cases";

const CasesForm = (props) => {
  const {
    procedure_id,
    selectedCases,
    handleAddSelect,
    handleOnRemoveSelect,
    handleOnChangeSelect,
    stepNumber,
    csrf,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [cases, setCases] = useState([]);

  const getCasesSuccess = useCallback((response) => {
    setCases(
      response.data.cases.map((d) => {
        return { label: `Consulta ${d.id}`, value: d.id };
      })
    );
    setLoaded(true);
  });

  useEffect(() => {
    if (procedure_id) {
      setLoaded(false);
      getCases(csrf, procedure_id).then(
        (response) => getCasesSuccess(response),
        // eslint-disable-next-line no-console
        (err) => console.log(err)
      );
    }
  }, [procedure_id]);

  let index = 0;
  return (
    <div>
      <div className="card-header">
        <div className="col-12">
          <h4 className="font-weight-bold mb-3">
            <div className="badge badge-pill badge-info font-16 mr-2">
              {stepNumber}
            </div>
            Seleccione casos
          </h4>
        </div>
      </div>
      {!procedure_id && (
        <div className="card-body">
          <div className="d-flex justify-content-center">
            Seleccione una materia
          </div>
        </div>
      )}
      {procedure_id && !loaded && (
        <div className="card-body">
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-secondary">
              <span className="sr-only"></span>
            </div>
          </div>
        </div>
      )}
      {procedure_id && loaded && (
        <div className="card-body">
          {selectedCases.map((d, i) => {
            if (!d.enabled) {
              return "";
            }
            index += 1;

            const { case_id } = d;
            return (
              <div className="row border border-light rounded p-3 mb-4" key={i}>
                <div className="col-md-1 col-6 first-order mb-1">{index}</div>
                <div className="col-md-10 col-12 third-order">
                  <Select
                    options={cases}
                    value={cases.find((e) => e.value == case_id)}
                    placeholder="Seleccione un caso"
                    name="case_id"
                    onChange={(e, t) => handleOnChangeSelect(e, t, i)}
                  />
                </div>
                <div className="col-md-1 col-6 second-order mb-1">
                  <div
                    className="btn btn-sm btn-danger"
                    onClick={() => handleOnRemoveSelect(i)}
                  >
                    <i className="mdi mdi-close"></i>
                    <span>Eliminar</span>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="col-12 mt-3">
            <div
              className="btn btn-sm btn-success"
              onClick={() => handleAddSelect()}
            >
              <i className="mdi mdi-plus"></i>
              Agregar caso preexistente
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CasesForm;
