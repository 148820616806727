/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

const AttributeRow = ({ attribute, value }) => (
  <Fragment>
    <div className="row">
      <div className="col-6">{attribute}</div>
      <div className="col-6">{value}</div>
    </div>
    <hr className="mt-2 mb-3" />
  </Fragment>
);

export default AttributeRow;
