/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";
import CommentsShow from "../Show/index";
import CommentsForm from "../Form/index";

const CommentsIndex = (props) => {
  const { owner, ownerType, csrf } = props;
  const [comments, setComments] = useState(owner.comments);

  const handleSaveComment = useCallback((comment) => {
    comments.push(comment);
    setComments([...comments]);
  });

  return (
    <div className="card-body">
      {comments
        .filter((d) => d.enabled)
        .sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
        .map((comment, key) => {
          return <CommentsShow key={key} commentProps={comment} csrf={csrf} />;
        })}

      <CommentsForm
        ownerId={owner.id}
        ownerType={ownerType}
        handleSaveComment={handleSaveComment}
        csrf={csrf}
      />
    </div>
  );
};

export default CommentsIndex;
