/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

const ProcedureStepForm = (props) => {
  const { form, setForm } = props;

  const handleProcedureStepInputChange = useCallback((event, index) => {
    const {
      target: { value, name },
    } = event;
    const { procedure_stages_attributes } = form;
    procedure_stages_attributes[index][name] = value;
    setForm({ ...form, procedure_stages_attributes });
  });

  const handleOnClickRemoveProcedureStep = useCallback((index) => {
    const { procedure_stages_attributes } = form;
    if (procedure_stages_attributes[index].id) {
      procedure_stages_attributes[index].enabled = false;
    } else {
      procedure_stages_attributes.splice(index, 1);
    }
    setForm({ ...form, procedure_stages_attributes });
  });

  const handleOnClickAddProcedureStep = useCallback(() => {
    const { procedure_stages_attributes } = form;
    procedure_stages_attributes.push({
      name: "",
      number: procedure_stages_attributes.filter((d) => d.enabled).length,
      enabled: true,
    });
    setForm({ ...form, procedure_stages_attributes });
  });

  const { procedure_stages_attributes } = form;

  return (
    <div className="row draggable-cards" id="draggable-area">
      {procedure_stages_attributes &&
        procedure_stages_attributes.map((procedureStep, index) => {
          if (!procedureStep.enabled) {
            return "";
          }
          const { name } = procedureStep;
          return (
            <div className="col-md-6 col-sm-12" key={index}>
              <div className="card card-hover">
                <div className="card-header">
                  <div className="col-12 d-flex no-block align-items-center">
                    <label
                      className="mr-2"
                      htmlFor={`procedure_stage_name_${index}`}
                    >
                      Nombre:
                    </label>
                    <input
                      className="form-control mr-2"
                      type="text"
                      value={name}
                      id={`procedure_stage_name_${index}`}
                      name="name"
                      pattern="[A-zÁ-ú0-9\s]*"
                      onChange={(e) => handleProcedureStepInputChange(e, index)}
                    />
                    <div className="ml-auto">
                      <div
                        className="btn btn-sm btn-danger"
                        onClick={() => handleOnClickRemoveProcedureStep(index)}
                      >
                        <i className="mdi mdi-close"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <div className="col-12 mt-3">
        <div
          className="btn btn-sm btn-success"
          onClick={handleOnClickAddProcedureStep}
        >
          <i className="mdi mdi-plus"></i>
          Agregar etapa
        </div>
      </div>
    </div>
  );
};

export default ProcedureStepForm;
