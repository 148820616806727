/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from "react";
import Table from "../../../../components/Table";
import Errors from "../../../../components/Layouts/Errors";

const Index = (props) => {
  const { errors, users, pagination } = props;

  const columns = [
    { label: "Email", key: "email", value: (d) => d.email },
    { label: "Nombre", key: "first_name", value: (d) => d.first_name },
    { label: "Apellido", key: "last_name", value: (d) => d.last_name },
    { label: "Rol", key: "roles.name", value: (d) => d.role && d.role.name },
  ];

  const customActions = (d) =>
    d.enabled && (
      <a
        className="btn btn-sm btn-warning mr-2"
        href={`/users/${d.id}/reset_password`}
        data-confirm="¿Estás seguro que desea recuperar la contraseña del usuario?"
        data-toggle="tooltip"
        data-placement="top"
        data-title="Recuperar contraseña"
      >
        <i className="mdi mdi-security"></i>
      </a>
    );

  return (
    <div className="row">
      <div className="col-12">
        <Errors errors={errors} />
      </div>

      <Table
        columns={columns}
        entityClass={"users"}
        rows={users}
        pagination={pagination}
        customActions={customActions}
      />
    </div>
  );
};

export default Index;
